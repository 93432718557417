import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import {
  faUserEdit,
  faPlus,
  faTrash,
  faChevronLeft,
  faChevronRight,
  faEnvelope,
  faKey,
  faEye,
  faEyeSlash,
  faChartLine,
  faUtensils,
  faUsers,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faUserEdit,
  faPlus,
  faTrash,
  faChevronLeft,
  faChevronRight,
  faEnvelope,
  faKey,
  faEye,
  faEyeSlash,
  faChartLine,
  faUtensils,
  faUsers,
  faCopy
);

export default FontAwesomeIcon;
