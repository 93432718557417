<template>
  <!-- Container -->
  <div class="w-11/12 mx-auto">
    <h2 class="big-title mb-14">
      Restaurant
    </h2>
    <!-- Form container -->
    <div class="form-container 2xl:w-9/12">
      <!-- Gradient Background -->
      <div class="gradient-background"></div>

      <h3 class="form-title relative z-10">
        Edit Restaurant
      </h3>

      <!-- Form -->
      <form
        @submit.prevent="onSubmit"
        class="relative z-10 text-tableSubColor font-bold"
      >
        <div class="md:flex">
          <!-- Select Restaurant Owner -->
          <div class="w-full mb-4 md:mr-8">
            <label
              >Select Restaurant Owner
              <select
                name="owner_id"
                v-model="model.owner_id"
                class="input"
                :class="{
                  'is-invalid': v$.model.owner_id.$error,
                }"
              >
                <option
                  v-for="owner in getRestaurantOwner"
                  :key="owner.id"
                  :value="owner.id"
                  >{{ owner.first_name }} {{ owner.last_name }}</option
                >
              </select>
            </label>
            <span
              v-if="v$.model.owner_id.$error"
              class="text-sm font-normal text-invalidColor"
              >{{ v$.model.owner_id.$errors[0].$message }}</span
            >
          </div>
          <!-- Name -->
          <div class="w-full mb-4">
            <label
              >Name
              <input
                type="text"
                name="name"
                autocomplete="off"
                v-model="model.name"
                class="input"
                :class="{
                  'is-invalid': v$.model.name.$error,
                }"
              />
            </label>
            <span
              v-if="v$.model.name.$error"
              class="text-sm font-normal text-invalidColor"
              >{{ v$.model.name.$errors[0].$message }}</span
            >
          </div>
        </div>

        <div class="md:flex">
          <!-- Business Name -->
          <div class="w-full mb-4 md:mr-8">
            <label
              >Business Name
              <input
                type="text"
                name="business_name"
                autocomplete="off"
                v-model="model.business_name"
                class="input"
                :class="{
                  'is-invalid': v$.model.business_name.$error,
                }"
              />
            </label>
            <span
              v-if="v$.model.business_name.$error"
              class="text-sm font-normal text-invalidColor"
              >{{ v$.model.business_name.$errors[0].$message }}</span
            >
          </div>

          <!-- Business Number -->
          <div class="w-full mb-4">
            <label
              >Business Number
              <input
                type="text"
                name="business_number"
                autocomplete="off"
                v-model="model.business_number"
                class="input"
                :class="{
                  'is-invalid': v$.model.business_number.$error,
                }"
              />
            </label>
            <span
              v-if="v$.model.business_number.$error"
              class="text-sm font-normal text-invalidColor"
              >{{ v$.model.business_number.$errors[0].$message }}</span
            >
          </div>
        </div>

        <div class="md:flex">
          <!-- Phone -->
          <div class="w-full mb-4 md:mr-8">
            <label
              >Phone
              <input
                type="tel"
                name="phone"
                autocomplete="off"
                v-model="model.phone"
                class="input"
                :class="{
                  'is-invalid': v$.model.phone.$error,
                }"
              />
            </label>
            <span
              v-if="v$.model.phone.$error"
              class="text-sm font-normal text-invalidColor"
              >{{ v$.model.phone.$errors[0].$message }}</span
            >
          </div>
          <!-- Street -->
          <div class="w-full mb-4">
            <label
              >Street
              <input
                type="text"
                name="street"
                autocomplete="off"
                v-model="model.street"
                class="input"
                :class="{
                  'is-invalid': v$.model.street.$error,
                }"
              />
            </label>
            <span
              v-if="v$.model.street.$error"
              class="text-sm font-normal text-invalidColor"
              >{{ v$.model.street.$errors[0].$message }}</span
            >
          </div>
        </div>

        <div class="md:flex">
          <!-- City -->
          <div class="w-full mb-4 md:mr-8">
            <label
              >City
              <input
                type="text"
                name="city"
                autocomplete="off"
                v-model="model.city"
                class="input"
                :class="{
                  'is-invalid': v$.model.city.$error,
                }"
              />
            </label>
            <span
              v-if="v$.model.city.$error"
              class="text-sm font-normal text-invalidColor"
              >{{ v$.model.city.$errors[0].$message }}</span
            >
          </div>
          <!-- Postcode -->
          <div class="w-full mb-4">
            <label
              >Postcode
              <input
                type="text"
                name="postcode"
                autocomplete="off"
                v-model="model.postcode"
                class="input"
                :class="{
                  'is-invalid': v$.model.postcode.$error,
                }"
              />
            </label>
            <span
              v-if="v$.model.postcode.$error"
              class="text-sm font-normal text-invalidColor"
              >{{ v$.model.postcode.$errors[0].$message }}</span
            >
          </div>
        </div>

        <div class="md:flex">
          <!-- Time Block -->
          <div class="w-full mb-4 md:mr-8">
            <label
              >Time Block
              <input
                type="number"
                name="time_block"
                autocomplete="off"
                placeholder="Enter number of minutes..."
                v-model="model.time_block"
                class="input"
                :class="{
                  'is-invalid': v$.model.time_block.$error,
                }"
              />
            </label>
            <span
              v-if="v$.model.time_block.$error"
              class="text-sm font-normal text-invalidColor"
              >{{ v$.model.time_block.$errors[0].$message }}</span
            >
          </div>
          <!-- Max Orders -->
          <div class="w-full mb-4">
            <label
              >Max Orders
              <input
                type="number"
                name="max_orders"
                autocomplete="off"
                placeholder="Enter number of orders..."
                v-model="model.max_orders"
                class="input"
                :class="{
                  'is-invalid': v$.model.max_orders.$error,
                }"
              />
            </label>
            <span
              v-if="v$.model.max_orders.$error"
              class="text-sm font-normal text-invalidColor"
              >{{ v$.model.max_orders.$errors[0].$message }}</span
            >
          </div>
        </div>

        <div class="md:flex">
          <!-- Starting Pickup Time -->
          <div class="w-full mb-4 md:mr-8">
            <label>
              Starting Pickup Time
              <input
                type="time"
                name="starting_pickup_time"
                autocomplete="off"
                v-model="model.starting_pickup_time"
                class="input"
                :class="{
                  'is-invalid': v$.model.starting_pickup_time.$error,
                }"
              />
            </label>
            <span
              v-if="v$.model.starting_pickup_time.$error"
              class="text-sm font-normal text-invalidColor"
              >{{ v$.model.starting_pickup_time.$errors[0].$message }}</span
            >
          </div>
          <!-- Ending Pickup Time -->
          <div class="w-full mb-4">
            <label
              >Ending Pickup Time
              <input
                type="time"
                name="ending_pickup_time"
                autocomplete="off"
                v-model="model.ending_pickup_time"
                class="input"
                :class="{
                  'is-invalid': v$.model.ending_pickup_time.$error,
                }"
              />
            </label>
            <span
              v-if="v$.model.ending_pickup_time.$error"
              class="text-sm font-normal text-invalidColor"
              >{{ v$.model.ending_pickup_time.$errors[0].$message }}</span
            >
          </div>
        </div>

        <div class="md:flex">
          <!-- Latitude -->
          <div class="w-full mb-4 md:mr-8">
            <label
              >Latitude
              <input
                type="text"
                name="latitude"
                autocomplete="off"
                v-model="model.latitude"
                class="input"
              />
            </label>
            <span
              v-if="v$.model.latitude.$error"
              class="text-sm font-normal text-invalidColor"
              >{{ v$.model.latitude.$errors[0].$message }}</span
            >
          </div>
          <!-- Longitude -->
          <div class="w-full mb-4">
            <label
              >Longitude
              <input
                type="text"
                name="longitude"
                autocomplete="off"
                v-model="model.longitude"
                class="input"
              />
            </label>
            <span
              v-if="v$.model.longitude.$error"
              class="text-sm font-normal text-invalidColor"
              >{{ v$.model.longitude.$errors[0].$message }}</span
            >
          </div>
        </div>

        <div class="md:flex">
          <!-- Select Business Hours -->
          <div class="w-full mb-4 md:mr-8">
            <label
              >Select Business Hours
              <Multiselect
                :classes="{
                  container: 'multiselectContainer',
                  tag: 'multiselectTag bg-black text-white',
                  tagsSearch: 'multiselectTagsSearch',
                }"
                v-model="this.selectedBusinessHours"
                mode="tags"
                :closeOnSelect="false"
                :searchable="true"
                :options="allBusinessHours"
              />
            </label>
          </div>
          <!-- Select Delivery Areas -->
          <div class="w-full mb-4">
            <label
              >Select Delivery Areas
              <Multiselect
                :classes="{
                  container: 'multiselectContainer',
                  tag: 'multiselectTag bg-black text-white',
                  tagsSearch: 'multiselectTagsSearch',
                }"
                v-model="this.selectedDeliveryAreas"
                mode="tags"
                :closeOnSelect="false"
                :searchable="true"
                :options="allDeliveryAreas"
              />
            </label>
          </div>
        </div>

        <div class="md:flex">
          <!-- Published -->
          <div class="md:w-full md:flex">
            <div class="flex flex-col md:ml-1 md:mr-8">
              <label>Published</label>
              <label class="switch mb-4 md:mb-0 md:mt-2">
                <input
                  type="checkbox"
                  v-model="model.is_active"
                  :false-value="parseInt(0)"
                  :true-value="parseInt(1)"
                />
                <span class="slider round"></span>
              </label>
            </div>
            <!-- Is Delivery Available? -->
            <div class="flex flex-col">
              <label>Is Delivery Available?</label>
              <label class="switch mb-4 md:mb-0 md:mt-2">
                <input
                  type="checkbox"
                  v-model="model.is_delivery_available"
                  :false-value="parseInt(0)"
                  :true-value="parseInt(1)"
                />
                <span class="slider round"></span>
              </label>
            </div>
          </div>
        </div>

        <!-- Buttons -->
        <div class="w-full flex justify-end pt-4">
          <Button
            type="button"
            @click.prevent="back"
            btnTitle="Cancel"
            class="mr-4 bg-goldBeige text-black"
          />
          <Button type="submit" btnTitle="Save" class="bg-greenBtn" />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Button from "../../../components/Button.vue";
import { mapActions, mapGetters } from "vuex";
import Multiselect from "@vueform/multiselect";
import useVuelidate from "@vuelidate/core";
import { required, minLength, maxLength, numeric } from "@vuelidate/validators";

export default {
  name: "RestaurantsEdit",
  components: {
    Button,
    Multiselect,
  },
  data() {
    return {
      v$: useVuelidate(),
      model: {
        owner_id: "",
        name: "",
        business_name: "",
        business_number: "",
        phone: "",
        street: "",
        city: "",
        postcode: "",
        time_block: "",
        max_orders: "",
        starting_pickup_time: "",
        ending_pickup_time: "",
        latitude: "",
        longitude: "",
        is_active: 0,
        is_delivery_available: 0,
        hours: [],
        areas: [],
      },
      selectedBusinessHours: [],
      selectedDeliveryAreas: [],
    };
  },
  validations() {
    return {
      model: {
        owner_id: { required },
        name: { required },
        business_name: {required},
        business_number: {required},
        phone: {
          required,
          minLengthValue: minLength(10),
          maxLengthValue: maxLength(10),
        },
        street: { required },
        city: { required },
        postcode: { required, numeric },
        time_block: { required },
        max_orders: { required },
        starting_pickup_time: { required },
        ending_pickup_time: { required },
        latitude: { required },
        longitude:{ required }
      },
    };
  },

  async beforeMount() {
    if (this.getUsers.length == 0) {
      await this.fetchUsers;
    }
    if (this.getBusinessHours.length == 0) {
      await this.fetchBusinessHours;
    }
    if (this.getDeliveryAreas.length == 0) {
      await this.fetchDeliveryAreas;
    }
    // if (this.getRestaurants.length == 0) {

    // }
    await this.fetchRestaurants;
    this.getCurrentRestaurant();
  },

  computed: {
    ...mapActions([
      "fetchUsers",
      "fetchBusinessHours",
      "fetchDeliveryAreas",
      "fetchRestaurants",
    ]),
    ...mapGetters([
      "getUsers",
      "getBusinessHours",
      "getDeliveryAreas",
      "getRestaurantById",
      "getRestaurants",
    ]),

    allBusinessHours() {
      let arry = [];
      this.getBusinessHours.forEach((element) => {
        arry.push({
          value: element.id,
          label: `${
            element.day == 0
              ? "Söndag"
              : element.day == 1
              ? "Måndags"
              : element.day == 2
              ? "Tisdag"
              : element.day == 3
              ? "Onsdag"
              : element.day == 4
              ? "Torsdag"
              : element.day == 5
              ? "Fredag"
              : element.day == 6
              ? "Lördag"
              : null
          }  ${element.open_time} - ${element.close_time} `,
        });
      });
      return arry;
    },

    allDeliveryAreas() {
      let arry = [];
      this.getDeliveryAreas.forEach((element) => {
        arry.push({
          value: element.id,
          label: element.name,
        });
      });
      return arry;
    },

    getRestaurantOwner() {
      return this.getUsers.filter((user) => {
        if (user.role.toLowerCase() === "restaurant_owner") {
          return user;
        }
      });
    },
  },

  methods: {
    getCurrentRestaurant() {
      let id = parseInt(this.$route.params.id);
      let currentObject = this.getRestaurantById(id)[0];
      console.log(currentObject);

      this.model = currentObject;
      this.selectedBusinessHours = currentObject.hours;
      this.selectedDeliveryAreas = currentObject.areas;
      // this.model.hours = null;
      this.model.areas = null;
    },

    async onSubmit() {
      this.v$.$validate();
      if (!this.v$.$error) {
        const convertToStringHours = this.selectedBusinessHours.join();
        const convertToStringAreas = this.selectedDeliveryAreas.join();
        this.model.hours = convertToStringHours;
        this.model.areas = convertToStringAreas;

        if (this.model.hours.length == "") {
          this.model.hours = "";
          this.model.business_hours = [];
          this.selectedBusinessHours.length = 0;
        }

        try {
          await this.$store.dispatch("patchRestaurant", this.model);
          await this.$store.dispatch("fetchRestaurants", this.model);
          //redirect
          this.$router.push("/restaurants");
        } catch (e) {
          alert(Object.values(e.response.data.messages).join(" "));
        }
      }
    },

    back() {
      this.$router.go(-1);
    },
  },
};
</script>
<style>
.is-invalid {
  border-color: rgb(253, 165, 49);
}
</style>
