import axios from "axios";

const state = {
  dashboardData: null,
};
const getters = {
  getDashboardData: (state) => state.dashboardData,
};
const actions = {
  async fetchDashboardData({ commit }) {
    await axios
      .get(`/admin/dashboard`)
      .then((res) => {
        // console.log("RESPONSE REVEIVED: ", res);
        commit("setDashboardData", res.data.data);
      })
      .catch((err) => console.log(err));
  },
};
const mutations = {
  setDashboardData: (state, payload) => {
    state.dashboardData = payload;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
