<template>
  <!-- Container -->
  <div class="w-11/12 mx-auto">
    <h2 class="big-title md:mb-8 2xl:mb-14">
      User
    </h2>
    <!-- Form container -->
    <div class="form-wrapper 2xl:w-10/12 mx-auto">
      <h3 class="form-title">
        Create user
      </h3>

      <!-- Form -->
      <form
        @submit.prevent="onSubmit"
        class="text-tableSubColor font-bold md:flex"
      >
        <!-- Profil image -->
        <div class="w-full md:w-4/12 mr-4">
          <div
            class="w-48 h-48 lg:w-60 lg:h-60 mx-auto bg-tableBorderColor rounded-full relative"
          >
            <img v-if="img" :src="img" alt="Avatar" class="w-full h-full" />
            <div class="file-input mt-4">
              <input
                type="file"
                id="file"
                class="file"
                name="avatar"
                @change="filesChange"
              />
              <label
                for="file"
                class="bg-white text-subTitle border border-tableBorderColor px-3 py-2 rounded-full shadow-md text-sm font-bold cursor-pointer absolute bottom-2 right-4 lg:right-8 "
                ><font-awesome-icon icon="plus"
              /></label>
            </div>
          </div>
        </div>

        <div class="w-full md:w-8/12">
          <div class="md:flex">
            <!-- First name -->
            <div class="w-full mb-4 md:mr-8">
              <label
                >First name
                <input
                  type="text"
                  name="first_name"
                  autocomplete="off"
                  v-model="model.first_name"
                  class="input"
                  :class="{
                    'is-invalid': v$.model.first_name.$error,
                  }"
                />
              </label>
              <span
                v-if="v$.model.first_name.$error"
                class="text-sm font-normal text-invalidColor"
                >{{ v$.model.first_name.$errors[0].$message }}</span
              >
            </div>
            <!-- Last name -->
            <div class="w-full mb-4">
              <label
                >Last name
                <input
                  type="text"
                  name="last_name"
                  autocomplete="off"
                  v-model="model.last_name"
                  class="input"
                  :class="{
                    'is-invalid': v$.model.last_name.$error,
                  }"
                />
              </label>
              <span
                v-if="v$.model.last_name.$error"
                class="text-sm font-normal text-invalidColor"
                >{{ v$.model.last_name.$errors[0].$message }}</span
              >
            </div>
          </div>

          <div class="md:flex">
            <!-- Email -->
            <div class="w-full mb-4 md:mr-8">
              <label
                >Email
                <input
                  type="text"
                  name="email"
                  placeholder="name@exemple.com"
                  autocomplete="off"
                  v-model="model.email"
                  class="input"
                  :class="{
                    'is-invalid': v$.model.email.$error,
                  }"
                />
              </label>
              <span
                v-if="v$.model.email.$error"
                class="text-sm font-normal text-invalidColor"
                >{{ v$.model.email.$errors[0].$message }}</span
              >
            </div>
            <!-- Username -->
            <div class="w-full mb-4">
              <label
                >Username
                <input
                  type="text"
                  name="username"
                  autocomplete="off"
                  v-model="model.username"
                  class="input"
                  :class="{
                    'is-invalid': v$.model.username.$error,
                  }"
                />
              </label>
              <span
                v-if="v$.model.username.$error"
                class="text-sm font-normal text-invalidColor"
                >{{ v$.model.username.$errors[0].$message }}</span
              >
            </div>
          </div>

          <div class="md:flex">
            <!-- Mobile number -->
            <div class="w-full mb-4 md:mr-8">
              <label
                >Mobile number
                <input
                  type="text"
                  name="mobile_number"
                  autocomplete="off"
                  v-model="model.mobile_number"
                  class="input"
                  :class="{
                    'is-invalid': v$.model.mobile_number.$error,
                  }"
                />
              </label>
              <span
                v-if="v$.model.mobile_number.$error"
                class="text-sm font-normal text-invalidColor"
                >{{ v$.model.mobile_number.$errors[0].$message }}</span
              >
            </div>
            <!-- Select Role -->
            <div class="w-full mb-4">
              <label
                >Role
                <select
                  name="role"
                  v-model="model.role"
                  class="input"
                  :class="{
                    'is-invalid': v$.model.role.$error,
                  }"
                >
                  <option value="administrator">Administrator</option>
                  <option value="restaurant_owner">Restaurant owner</option>
                  <option value="kitchen_staff">Kitchen staff</option>
                  <option value="driver">Driver</option>
                  <option value="client">Client</option>
                </select>
              </label>
              <span
                v-if="v$.model.role.$error"
                class="text-sm font-normal text-invalidColor"
                >{{ v$.model.role.$errors[0].$message }}</span
              >
            </div>
          </div>

          <!-- Select Restaurant -->
          <div class="w-full mb-4">
            <label
              >Select Restaurant
              <select
                name="restaurant_id"
                v-model="model.restaurant_id"
                class="input"
                :class="{
                  'is-invalid': v$.model.restaurant_id.$error,
                }"
              >
                <option
                  v-for="restaurant in getRestaurants"
                  :key="restaurant.id"
                  :value="restaurant.id"
                  >{{ restaurant.name }}</option
                >
              </select>
            </label>
            <span
              v-if="v$.model.restaurant_id.$error"
              class="text-sm font-normal text-invalidColor"
              >{{ v$.model.restaurant_id.$errors[0].$message }}</span
            >
          </div>

          <!-- Buttons -->
          <div class="w-full flex justify-end pt-4">
            <Button
              type="button"
              @click.prevent="back"
              btnTitle="Cancel"
              class="mr-4 bg-goldBeige text-black"
            />
            <Button type="submit" btnTitle="Save" class="bg-greenBtn" />
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Button from "../../components/Button.vue";
import { mapGetters, mapActions } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";

export default {
  name: "UserCreate",
  components: {
    Button,
  },
  data() {
    return {
      v$: useVuelidate(),
      inputs: {
        file: null,
      },
      img: "",
      model: {
        first_name: "",
        last_name: "",
        email: "",
        username: "",
        mobile_number: "",
        role: "",
        restaurant_id: "",
        avatar: null,
      },
    };
  },

  /* --- Validation --- */
  validations() {
    return {
      model: {
        first_name: { required },
        last_name: { required },
        email: { required, email },
        username: { required },
        mobile_number: { required },
        role: { required },
        restaurant_id: { required },
      },
    };
  },

  async beforeMount() {
    if (this.getRestaurants.length == 0) {
      this.fetchRestaurants;
    }
  },

  computed: {
    ...mapGetters(["getRestaurants"]),
    ...mapActions(["fetchRestaurants"]),
  },

  methods: {
    filesChange(e) {
      const file = e.target.files[0];

      // console.log(e.target.files);
      // console.log(this.model);
      this.img = URL.createObjectURL(file);
      this.inputs.file = e;
      if (e.target.files.length == 1) {
        this.model.avatar = file;
      } else {
        delete this.model.avatar;
      }
    },

    async onSubmit() {
      this.v$.$validate();
      if (!this.v$.$error) {
        try {
          let formData = new FormData();
          for (var key in this.model) {
            formData.append(key, this.model[key]);
          }

          await this.$store.dispatch("saveUser", formData);
          await this.$store.dispatch("fetchUsers", formData);
          //redirect
          this.$router.push("/users");
        } catch (e) {
          alert(Object.values(e.response.data.messages).join(" "));
        }
      }
    },

    back() {
      this.$router.go(-1);
    },
  },
};
</script>
