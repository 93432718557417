export const lineChartWeek = {
  type: "line",
  data: {
    labels: [
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
      21,
      22,
      23,
      24,
      25,
      26,
    ],
    datasets: [
      {
        label: false,
        data: [
          100,
          50,
          300,
          2000,
          1000,
          5000,
          90,
          3000,
          2000,
          400,
          100,
          50,
          300,
          2000,
          1000,
          5000,
          90,
          3000,
          2000,
          400,
          100,
          50,
          300,
          2000,
          1000,
          5000,
        ],
        backgroundColor: "rgba(18, 152, 144, 0.2)",
        borderColor: "rgba(18, 152, 144, 0.5)",
        borderWidth: 3,
      },
    ],
  },
  options: {
    maintainAspectRatio: false,
    responsive: true,
    lineTension: 1,
    tooltips: {
      displayColors: false,
      backgroundColor: "#773090",
      titleFontColor: "#EADBEF",
      titleAlign: "center",
      bodyFontSize: 14,
      caretPadding: 10,
      padding: 10,

      callbacks: {
        label: function(item) {
          let value = item.yLabel;
          value = value.toLocaleString();
          let label = value + " Sek";
          return label;
        },
      },
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            beginAtZero: true,
            padding: 15,
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            padding: 25,
          },
        },
      ],
    },
    legend: {
      display: false,
    },
  },
};

export default lineChartWeek;
