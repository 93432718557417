import axios from "axios";

const state = {
  businessHours: [],
  days: ["Söndag", "Måndag", "Tisdag", "Onsdag", "Torsdag", "Fredag", "Lördag"],
};

const getters = {
  getBusinessHours: (state) => state.businessHours,
  getDays: (state) => state.days,
  getBusinessHourById: (state) => (id) => {
    return state.businessHours.filter((item) => item.id === id);
  },
};

const mutations = {
  SET_BUSINESS_HOURS(state, payload) {
    state.businessHours = payload;
  },

  ADD_BUSINESS_HOUR(state, payload) {
    state.businessHours.push(payload);
  },

  REMOVE_BUSINESS_HOUR(state, payload) {
    state.businessHours = state.businessHours.filter(
      (hour) => hour.id !== payload
    );
  },

  UPDATE_BUSINESS_HOUR(state, payload) {
    state.businessHours.forEach((hour, i) => {
      if (hour.id == payload.id) {
        state.businessHours[i] = payload;
      }
    });
  },
};

const actions = {
  async fetchBusinessHours({ commit }) {
    const { data } = await axios.get("/business-hours");
    commit("SET_BUSINESS_HOURS", data.data);
  },

  async saveBusinessHour({ commit }, payload) {
    const { data } = await axios.post("/business-hours", payload);
    commit("ADD_BUSINESS_HOUR", data.data);
  },

  async deleteBusinessHour({ commit }, id) {
    await axios.delete(`business-hours/${id}`);
    commit("REMOVE_BUSINESS_HOUR", id);
  },

  async patchBusinessHour({ commit }, payload) {
    const { data } = await axios.patch(
      `/business-hours/${payload.id}`,
      payload
    );
    commit("UPDATE_BUSINESS_HOUR", data.data);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
