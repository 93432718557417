import axios from "axios";

const state = {
  variations: [],
};

const getters = {
  getVariations: (state) => state.variations,
  getVariationById: (state) => (id) => {
    return state.variations.filter((item) => item.id === id);
  },
};

const mutations = {
  SET_VARIATIONS(state, payload) {
    state.variations = payload;
  },

  ADD_VARIATION(state, payload) {
    state.variations.push(payload);
  },
  REMOVE_VARIATION(state, payload) {
    state.variations = state.variations.filter(
      (variation) => variation.id !== payload
    );
  },
  UPDATE_VARIATION(state, payload) {
    state.variations.forEach((variation, i) => {
      if (variation.id == payload.id) {
        state.variations[i] = payload;
      }
    });
  },
};

const actions = {
  async fetchVariations({ commit }) {
    const { data } = await axios.get("/variations");
    commit("SET_VARIATIONS", data.data);
  },

  async saveVariation({ commit }, payload) {
    const { data } = await axios.post("/variations", payload);
    commit("ADD_VARIATION", data.data);
  },

  async deleteVariation({ commit }, id) {
    await axios.delete(`variations/${id}`);
    commit("REMOVE_VARIATION", id);
  },

  async patchVariation({ commit }, payload) {
    const { data } = await axios.patch(`/variations/${payload.id}`, payload);
    commit("UPDATE_VARIATION", data.data);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
