<template>
  <!-- Container -->
  <div class="w-11/12 mx-auto">
    <h2 class="big-title mb-14">
      Menu
    </h2>
    <!-- Form container -->
    <div class="form-wrapper 2xl:w-9/12 mx-auto">
      <h3 class="form-title ">
        Edit category
      </h3>

      <!-- Form -->
      <form @submit.prevent="onSubmit" class="text-tableSubColor font-bold">
        <div class="md:flex">
          <!-- Select menu -->
          <div class="w-full mb-4 md:mr-8">
            <label
              >Select menu
              <select
                name="menu_id"
                v-model="model.menu_id"
                class="input"
                :class="{
                  'is-invalid': v$.model.menu_id.$error,
                }"
              >
                <option
                  v-for="menu in getMenus"
                  :key="menu.id"
                  :value="menu.id"
                  >{{ menu.name }}</option
                >
              </select>
            </label>
            <span
              v-if="v$.model.menu_id.$error"
              class="text-sm font-normal text-invalidColor"
              >{{ v$.model.menu_id.$errors[0].$message }}</span
            >
          </div>
          <!-- Name -->
          <div class="w-full mb-4">
            <label
              >Name
              <input
                type="text"
                name="name"
                autocomplete="off"
                v-model="model.name"
                class="input"
                :class="{
                  'is-invalid': v$.model.name.$error,
                }"
              />
            </label>
            <span
              v-if="v$.model.name.$error"
              class="text-sm font-normal text-invalidColor"
              >{{ v$.model.name.$errors[0].$message }}</span
            >
          </div>
        </div>

        <div class="md:flex">
          <!-- Description -->
          <div class="w-full mb-4 md:mr-8">
            <label
              >Description
              <textarea
                rows="4"
                cols="30"
                name="description"
                v-model="model.description"
                class="input"
                :class="{
                  'is-invalid': v$.model.description.$error,
                }"
              ></textarea>
            </label>
            <span
              v-if="v$.model.description.$error"
              class="text-sm font-normal text-invalidColor"
              >{{ v$.model.description.$errors[0].$message }}</span
            >
          </div>

          <div class="md:w-full">
            <!-- Order -->
            <div class="w-full mb-4 md:mr-8">
              <label
                >Order
                <input
                  type="number"
                  name="order"
                  v-model="model.order"
                  class="input"
                  :class="{
                    'is-invalid': v$.model.order.$error,
                  }"
                />
              </label>
              <span
                v-if="v$.model.order.$error"
                class="text-sm font-normal text-invalidColor"
                >{{ v$.model.order.$errors[0].$message }}</span
              >
            </div>
            <div class="md:flex">
              <!-- Toggle Switch -->
              <div class="flex flex-col md:ml-1 md:mr-8">
                <label>Published</label>
                <label class="switch mb-4">
                  <input
                    type="checkbox"
                    v-model="model.is_active"
                    :false-value="parseInt(0)"
                    :true-value="parseInt(1)"
                  />
                  <span class="slider round"></span>
                </label>
              </div>
              <!-- Toggle Switch -->
              <div class="flex flex-col">
                <label title="Is Counted in Time Blocks?">Time Blocks?</label>
                <label class="switch mb-4">
                  <input
                    type="checkbox"
                    v-model="model.is_counted_in_timeblocks"
                    :false-value="parseInt(0)"
                    :true-value="parseInt(1)"
                  />
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <!-- Buttons -->
        <div class="w-full flex justify-end pt-4">
          <Button
            type="button"
            @click.prevent="back"
            btnTitle="Cancel"
            class="mr-4 bg-goldBeige text-black"
          />
          <Button type="submit" btnTitle="Save" class="bg-greenBtn" />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Button from "../../../components/Button.vue";
import { mapGetters, mapActions } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  name: "CategoriesEdit",
  components: {
    Button,
  },
  data() {
    return {
      v$: useVuelidate(),
      model: {
        id: "",
        name: "",
        order: "",
        menu_id: "",
        description: "",
        is_active: 0,
        is_counted_in_timeblocks: 0,
      },
    };
  },

  /* --- Validation --- */
  validations() {
    return {
      model: {
        name: { required },
        order: { required },
        menu_id: { required },
        description: { required },
      },
    };
  },

  async beforeMount() {
    if (this.getMenus.length == 0) {
      await this.fetchMenus;
    }
    if (this.getCategories.length == 0) {
      await this.fetchCategories;
    }
    this.getCurrentCategory();
  },

  computed: {
    ...mapActions(["fetchMenus", "fetchCategories"]),
    ...mapGetters(["getCategoryById", "getMenus", "getCategories"]),
  },

  methods: {
    getCurrentCategory() {
      let id = parseInt(this.$route.params.id);
      this.model = this.getCategoryById(id)[0];
    },

    async onSubmit() {
      this.v$.$validate();
      if (!this.v$.$error) {
        try {
          await this.$store.dispatch("patchCategory", this.model);
          //redirect
          this.$router.push("/categories");
        } catch (e) {
          alert(Object.values(e.response.data.messages).join(" "));
        }
      }
    },

    back() {
      this.$router.go(-1);
    },
  },
};
</script>
