<template>
  <div
    class="bg-darkTrans fixed top-0 left-0 bottom-0 right-0 flex justify-center items-center z-50"
    @click="cancel"
  >
    <div class="w-80 md:w-96 bg-white py-4 rounded-lg text-center shadow-lg ">
      <h3 class="text-2xl font-bold">Are you sure?</h3>
      <p class="py-4">You won't be able to revert this!</p>
      <Button
        btnTitle="Yes, delete it!"
        class="bg-exportBtn w-32 m-2"
        @click="remove"
      />
      <Button
        btnTitle="Cancel"
        class="bg-goldBeige text-black m-2"
        @click="cancel"
      />
    </div>
  </div>
</template>

<script>
import Button from "../components/Button.vue";
export default {
  name: "PopUp",
  components: {
    Button,
  },
  methods: {
    remove() {
      this.$emit("remove");
    },
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>
