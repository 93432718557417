<template>
  <div class="xl:hidden relative z-30">
    <div
      class="flex justify-between items-center p-4 background border-b border-darkTrans"
    >
      <!-- logo -->
      <router-link to="/"
        ><img
          class="w-12 "
          src="../assets/images/chingaling-logo.png"
          alt="Pizza och co logo"
      /></router-link>

      <!-- mobile menu button -->
      <button @click="toggleNav">
        <svg
          class="h-7 w-7"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="white"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
      </button>
    </div>
  </div>

  <!-- Sidebar -->
  <nav
    class="h-full text-tableTitleColor bg-bodyBg rounded-tr-3xl shadow-lg w-52 px-2 overflow-y-auto flex flex-col justify-between fixed z-30 inset-y-0 left-0 transform xl:translate-x-0 transition duration-200 ease-in-out"
    :class="[open ? '-translate-x-full' : '-translate-x-0']"
  >
    <div>
      <!-- Nav Logo -->
      <div class="w-full flex justify-center">
        <router-link to="/"
          ><img
            class="w-24 pt-8 pb-16"
            src="../assets/images/chingaling-logo.png"
            alt="Pizza och co logo"
        /></router-link>
      </div>

      <!-- NAVIGATION -->
      <div class="flex flex-col ">
        <!---------- Dashboard ---------->

        <router-link
          class="p-1 mb-2 flex items-center hover:text-menuText border-r-4 border-whiteTrans focus:border-tableTitleColor focus:text-menuText transition duration-400 ease-in-out"
          to="/"
          v-if="this.getUser.role == 'administrator' || 'kitchen_staff'"
          ><img
            class="mx-2"
            src="../assets/images/dashboard.svg"
            alt="Dashboard icon"
          />
          Dashboard</router-link
        >

        <!---------- Daily wok ---------->

        <router-link
          class="p-1 mb-2 flex items-center hover:text-menuText border-r-4 border-whiteTrans focus:border-tableTitleColor focus:text-menuText transition duration-400 ease-in-out"
          :to="{ name: 'MenuItemsEdit', params: { id: 106 } }"
          v-if="this.getUser.role == 'administrator' || 'kitchen_staff'"
          ><img
            class="mx-2"
            src="../assets/images/restaurant.svg"
            alt="Menu icon"
          />
          Dailywok</router-link
        >

        <!---------- Users ---------->
        <router-link
          class="p-1 mb-2 flex items-center hover:text-menuText border-r-4 border-whiteTrans focus:border-tableTitleColor focus:text-menuText transition duration-400 ease-in-out"
          :to="{ name: 'Users' }"
          v-if="this.getUser.role == 'administrator'"
          ><div class="flex">
            <img class="mx-2" src="../assets/images/user.svg" alt="User icon" />
            Users
          </div>
        </router-link>

        <!---------- Restaurants ---------->
        <div
          @click="toggleRestaurantsDropdown"
          :class="[showRestaurants ? 'bg-sideDashboard' : '']"
          class="p-2 flex items-center justify-between rounded  transition 300ms hover:text-menuText cursor-pointer"
          v-if="this.getUser.role == 'administrator'"
        >
          <div class="flex">
            <img
              class="mx-2"
              src="../assets/images/restaurant.svg"
              alt="Menu icon"
            />
            Restaurant
          </div>
          <img
            class="w-4"
            :class="[showRestaurants ? 'rotate' : '']"
            src="../assets/images/arrow.svg"
            alt="Arrow icon"
          />
        </div>
        <!-- SUB Restaurants Dropdown  -->
        <div
          class="text-sm font-normal pl-11 block"
          :class="[showRestaurants ? 'block' : 'hidden']"
        >
          <router-link
            class="my-3 block hover:text-menuText border-r-2 border-whiteTrans focus:border-tableTitleColor focus:text-menuText transition duration-400 ease-in-out"
            :to="{ name: 'Restaurants' }"
          >
            Restaurants</router-link
          >
          <router-link
            class="my-3 block hover:text-menuText border-r-2 border-whiteTrans focus:border-tableTitleColor focus:text-menuText transition duration-400 ease-in-out"
            :to="{ name: 'BusinessHours' }"
          >
            Business Hours</router-link
          >
          <router-link
            class="my-3 block hover:text-menuText border-r-2 border-whiteTrans focus:border-tableTitleColor focus:text-menuText transition duration-400 ease-in-out"
            :to="{ name: 'DeliveryAreas' }"
          >
            Delivery Areas</router-link
          >
          <router-link
            class="my-3 block hover:text-menuText border-r-2 border-whiteTrans focus:border-tableTitleColor focus:text-menuText transition duration-400 ease-in-out"
            :to="{ name: 'TablePlacement' }"
          >
            Table Placement</router-link
          >
        </div>

        <!---------- Menu ---------->
        <div
          @click="toggleMenuDropdown"
          :class="[showMenu ? 'bg-sideDashboard' : '']"
          class="p-2 flex items-center justify-between rounded  transition 300ms hover:text-menuText cursor-pointer"
          v-if="this.getUser.role == 'administrator'"
        >
          <div class="flex">
            <img
              class="mx-2"
              src="../assets/images/menu-alt.svg"
              alt="Menu icon"
            />
            Menu
          </div>
          <img
            class="w-4"
            :class="[showMenu ? 'rotate' : '']"
            src="../assets/images/arrow.svg"
            alt="Arrow icon"
          />
        </div>
        <!-- SUB MENU Dropdown  -->
        <div
          class="text-sm font-normal pl-11 block"
          :class="[showMenu ? 'block' : 'hidden']"
        >
          <router-link
            class="my-3 block hover:text-menuText border-r-2 border-whiteTrans focus:border-tableTitleColor focus:text-menuText transition duration-400 ease-in-out"
            :to="{ name: 'Menus' }"
          >
            Menus</router-link
          >
          <router-link
            class="my-3 block hover:text-menuText border-r-2 border-whiteTrans focus:border-tableTitleColor focus:text-menuText transition duration-400 ease-in-out"
            :to="{ name: 'Categories' }"
          >
            Categories</router-link
          >
          <router-link
            class="my-3 block hover:text-menuText border-r-2 border-whiteTrans focus:border-tableTitleColor focus:text-menuText transition duration-400 ease-in-out"
            :to="{ name: 'Ingredients' }"
          >
            Ingredients</router-link
          >
          <router-link
            class="my-3 block hover:text-menuText border-r-2 border-whiteTrans focus:border-tableTitleColor focus:text-menuText transition duration-400 ease-in-out"
            :to="{ name: 'Variations' }"
          >
            Variations</router-link
          >
          <router-link
            class="my-3 block hover:text-menuText border-r-2 border-whiteTrans focus:border-tableTitleColor focus:text-menuText transition duration-400 ease-in-out"
            :to="{ name: 'MenuItems' }"
          >
            Menu Items</router-link
          >
        </div>
        <!---------- Sales ---------->
        <div
          @click="toggleSalesDropdown"
          :class="[showSales ? 'bg-sideDashboard' : '']"
          class="p-2 flex items-center justify-between rounded  transition 300ms hover:text-menuText cursor-pointer"
        >
          <div class="flex">
            <img
              class="mx-2"
              src="../assets/images/coin-piles.svg"
              alt="Menu icon"
            />
            Sales
          </div>
          <img
            class="w-4"
            :class="[showSales ? 'rotate' : '']"
            src="../assets/images/arrow.svg"
            alt="Arrow icon"
          />
        </div>
        <!-- SUB SALES Dropdown  -->
        <div
          class="text-sm font-normal pl-11 block"
          :class="[showSales ? 'block' : 'hidden']"
        >
          <router-link
            class="my-3 block hover:text-menuText border-r-2 border-whiteTrans focus:border-tableTitleColor focus:text-menuText transition duration-400 ease-in-out"
            :to="{ name: 'CurrentOrders' }"
          >
            Current Orders</router-link
          >

          <router-link
            v-if="
              this.getUser.role == 'administrator' ||
                this.getUser.role == 'kitchen_staff'
            "
            class="my-3 block hover:text-menuText border-r-2 border-whiteTrans focus:border-tableTitleColor focus:text-menuText transition duration-400 ease-in-out"
            :to="{ name: 'OrdersAdmin' }"
          >
            All Orders</router-link
          >
        </div>
        <!---------- Promotions ---------->
        <div
          @click="togglePromotionsDropdown"
          :class="[showPromotions ? 'bg-sideDashboard' : '']"
          class="p-2 flex items-center justify-between rounded  transition 300ms hover:text-menuText cursor-pointer"
          v-if="this.getUser.role == 'administrator'"
        >
          <div class="flex">
            <img class="mx-2" src="../assets/images/bell.svg" alt="Menu icon" />
            Promotions
          </div>
          <img
            class="w-4"
            :class="[showPromotions ? 'rotate' : '']"
            src="../assets/images/arrow.svg"
            alt="Arrow icon"
          />
        </div>
        <!-- SUB PROMOTIONS Dropdown  -->
        <div
          class="text-sm font-normal pl-11 block"
          :class="[showPromotions ? 'block' : 'hidden']"
        >
          <router-link
            class="my-3 block hover:text-menuText border-r-2 border-whiteTrans focus:border-tableTitleColor focus:text-menuText transition duration-400 ease-in-out"
            :to="{ name: 'Coupons' }"
          >
            Coupons</router-link
          >
          <router-link
            class="my-3 block hover:text-menuText border-r-2 border-whiteTrans focus:border-tableTitleColor focus:text-menuText transition duration-400 ease-in-out"
            :to="{ name: 'PushNotifications' }"
          >
            Push Notifications</router-link
          >
        </div>

        <!---------- Settings ---------->
        <div
          @click="toggleSettingsDropdown"
          :class="[showSettings ? 'bg-sideDashboard' : '']"
          class="p-2 flex items-center justify-between rounded  transition 300ms hover:text-menuText cursor-pointer"
          v-if="this.getUser.role == 'administrator'"
        >
          <div class="flex">
            <img
              class="mx-2"
              src="../assets/images/settings.svg"
              alt="Menu icon"
            />
            Settings
          </div>
          <img
            class="w-4"
            :class="[showSettings ? 'rotate' : '']"
            src="../assets/images/arrow.svg"
            alt="Arrow icon"
          />
        </div>
        <!-- SUB SETTINGS Dropdown  -->
        <div
          class="text-sm font-normal pl-11 block"
          :class="[showSettings ? 'block' : 'hidden']"
        >
          <router-link
            class="my-3 block hover:text-menuText border-r-2 border-whiteTrans focus:border-tableTitleColor focus:text-menuText transition duration-400 ease-in-out"
            :to="{ name: 'Points' }"
          >
            Points</router-link
          >
          <router-link
            class="my-3 block hover:text-menuText border-r-2 border-whiteTrans focus:border-tableTitleColor focus:text-menuText transition duration-400 ease-in-out"
            :to="{ name: 'GeneralSettings' }"
          >
            General Settings</router-link
          >
        </div>
      </div>
    </div>
    <!-- Profil -->
    <div class="mt-12">
      <router-link :to="{ name: 'Profile' }">
        <img
          class="w-20 h-20 rounded-full mx-auto mb-2"
          :src="avatar"
          alt="Avatar"
        />
        <p class="text-center">{{ this.full_name }}</p>
      </router-link>
      <div
        class="p-2 flex items-center my-8 cursor-pointer"
        @click.prevent="signOut"
      >
        <img class="mx-2" src="../assets/images/logout.svg" alt="Logout icon" />

        Logout
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Navigation",
  data() {
    return {
      full_name: "",
      avatar: null,
      open: false,
      showMenu: false,
      showPromotions: false,
      showSettings: false,
      showRestaurants: false,
      showSales: false,
    };
  },

  beforeMount() {
    this.$store.dispatch("getMe");

    this.full_name = this.getUser.full_name;

    // console.log(this.getUser.avatar);

    this.img = this.getUser.avatar;
    this.avatar = this.getUser.avatar;
  },

  computed: {
    ...mapGetters(["getUser"]),
  },

  methods: {
    toggleNav() {
      this.open = !this.open;
    },
    toggleMenuDropdown() {
      this.showMenu = !this.showMenu;
    },
    togglePromotionsDropdown() {
      this.showPromotions = !this.showPromotions;
    },
    toggleSettingsDropdown() {
      this.showSettings = !this.showSettings;
    },
    toggleRestaurantsDropdown() {
      this.showRestaurants = !this.showRestaurants;
    },
    toggleSalesDropdown() {
      this.showSales = !this.showSales;
    },

    async signOut() {
      await this.$store.dispatch("LogOut");
      this.$router.push("/login");
      /* await this.LogOut.then(() => {
        this.$router.push("/login");
      }); */
    },
  },
};
</script>

<style>
.rotate {
  transform: rotate(90deg);
}
.drop {
  transition: 1s;
}
</style>
