<template>
  <!-- Container -->
  <div class="w-11/12 mx-auto">
    <h2 class="big-title mb-14">
      Restaurant
    </h2>
    <!-- Form container -->
    <div class="form-container 2xl:w-9/12">
      <!-- Gradient Background -->
      <div class="gradient-background"></div>

      <h3 class="form-title relative z-10">
        Edit delivery area
      </h3>

      <!-- Form -->
      <form
        @submit.prevent="onSubmit"
        class="relative z-10 text-tableSubColor font-bold"
      >
        <div class="md:flex">
          <!-- Name -->
          <div class="w-full mb-4 md:mr-8">
            <label
              >Name
              <input
                type="text"
                name="name"
                autocomplete="off"
                v-model="model.name"
                class="input"
                :class="{
                  'is-invalid': v$.model.name.$error,
                }"
              />
            </label>
            <span
              v-if="v$.model.name.$error"
              class="text-sm font-normal text-invalidColor"
              >{{ v$.model.name.$errors[0].$message }}</span
            >
          </div>
          <!-- Time block -->
          <div class="w-full mb-4">
            <label
              >Time block
              <input
                type="number"
                name="time_block"
                autocomplete="off"
                v-model="model.time_block"
                class="input"
                :class="{
                  'is-invalid': v$.model.time_block.$error,
                }"
              />
            </label>
            <span
              v-if="v$.model.time_block.$error"
              class="text-sm font-normal text-invalidColor"
              >{{ v$.model.time_block.$errors[0].$message }}</span
            >
          </div>
        </div>
        <div class="md:flex">
          <!-- Max orders -->
          <div class="w-full mb-4 md:mr-8">
            <label
              >Max orders
              <input
                type="number"
                name="max_orders"
                autocomplete="off"
                v-model="model.max_orders"
                class="input"
                :class="{
                  'is-invalid': v$.model.max_orders.$error,
                }"
              />
            </label>
            <span
              v-if="v$.model.max_orders.$error"
              class="text-sm font-normal text-invalidColor"
              >{{ v$.model.max_orders.$errors[0].$message }}</span
            >
          </div>
          <!-- Published -->
          <div class="w-full flex flex-col pl-2">
            <label>Published</label>
            <label class="switch mb-4 md:mb-0 md:mt-2">
              <input
                type="checkbox"
                v-model="model.is_active"
                :false-value="parseInt(0)"
                :true-value="parseInt(1)"
              />
              <span class="slider round"></span>
            </label>
          </div>
        </div>

        <div class="md:flex">
          <!-- Starting Delivery Time -->
          <div class="w-full mb-4 md:mr-8">
            <label>
              Starting Delivery Time
              <input
                type="time"
                name="starting_delivery_time"
                autocomplete="off"
                v-model="model.starting_delivery_time"
                class="input"
                :class="{
                  'is-invalid': v$.model.starting_delivery_time.$error,
                }"
              />
            </label>
            <span
              v-if="v$.model.starting_delivery_time.$error"
              class="text-sm font-normal text-invalidColor"
              >{{ v$.model.starting_delivery_time.$errors[0].$message }}</span
            >
          </div>
          <!-- Ending Delivery Time -->
          <div class="w-full mb-4">
            <label
              >Ending Delivery Time
              <input
                type="time"
                name="ending_delivery_time"
                autocomplete="off"
                v-model="model.ending_delivery_time"
                class="input"
                :class="{
                  'is-invalid': v$.model.ending_delivery_time.$error,
                }"
              />
            </label>
            <span
              v-if="v$.model.ending_delivery_time.$error"
              class="text-sm font-normal text-invalidColor"
              >{{ v$.model.ending_delivery_time.$errors[0].$message }}</span
            >
          </div>
        </div>

        <p class="bg-goldBeige text-darkBlue p-3 mt-8 mb-4 rounded">
          You need minimum three coordinates to define delivery area shape!
        </p>
        <div v-if="this.modelCoordinates.length != 0">
          <!-- Coordinate list -->
          <div
            class="w-full md:w-6/12 mb-4"
            v-for="(n, i) in showMore"
            :key="i"
          >
            <label
              >Coordinate{{ n }}
              <input
                type="text"
                class="input"
                v-model="this.modelCoordinates.coordinates[i]"
              />
            </label>
          </div>

          <!-- Show more Button -->
          <Button
            v-if="showMore == 3"
            type="button"
            @click="showMore = 25"
            btnTitle="Show More"
            class="mr-4 w-24 bg-tableTitleColor text-white"
          />
        </div>

        <!-- Buttons -->
        <div class="w-full flex justify-end pt-4">
          <Button
            type="button"
            @click.prevent="back"
            btnTitle="Cancel"
            class="mr-4 bg-goldBeige text-black"
          />
          <Button type="submit" btnTitle="Save" class="bg-greenBtn" />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Button from "../../../components/Button.vue";
import { mapGetters, mapActions } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  name: "DeliveryAreasEdit",
  components: {
    Button,
  },
  data() {
    return {
      v$: useVuelidate(),
      model: {
        name: "",
        time_block: "",
        max_orders: "",
        starting_delivery_time: "",
        ending_delivery_time: "",
        is_active: 0,
      },

      modelCoordinates: [],
      showMore: 3,
    };
  },

  /* --- Validation --- */
  validations() {
    return {
      model: {
        name: { required },
        time_block: { required },
        max_orders: { required },
        starting_delivery_time: { required },
        ending_delivery_time: { required },
      },
    };
  },

  async beforeMount() {
    await this.fetchDeliveryAreaCoordinates;

    if (this.getDeliveryAreas.length == 0) {
      await this.fetchDeliveryAreas;
    }

    this.getCurrentDeliveryAreaById();
    this.modelCoordinates = this.deliveryAreaCoordinates;
  },

  computed: {
    ...mapGetters([
      "getDeliveryAreaById",
      "getDeliveryAreaCoordinates",
      "getDeliveryAreas",
    ]),
    ...mapActions(["fetchDeliveryAreaCoordinates", "fetchDeliveryAreas"]),

    deliveryAreaCoordinates() {
      let deliveryAreaCoordinate = {
        coordinates: [],
        id: [],
      };
      this.getDeliveryAreaCoordinates.forEach((item) => {
        if (item.delivery_area_id == this.$route.params.id) {
          deliveryAreaCoordinate.coordinates.push(item.coordinate);
          deliveryAreaCoordinate.id.push(item.id);
        }
      });
      return deliveryAreaCoordinate;
    },
  },

  methods: {
    getCurrentDeliveryAreaById() {
      let id = parseInt(this.$route.params.id);
      this.model = this.getDeliveryAreaById(id)[0];
    },

    async onSubmit() {
      this.v$.$validate();
      if (!this.v$.$error) {
        try {
          await this.$store.dispatch("patchDeliveryArea", this.model);

          for (let i = 0; i < this.modelCoordinates.coordinates.length; i++) {
            const element = this.modelCoordinates.coordinates[i];

            let id = parseInt(this.$route.params.id);

            if (this.modelCoordinates.id[i] != undefined) {
              let data = {
                id: this.modelCoordinates.id[i],
                delivery_area_id: id,
                coordinate: element,
              };
              await this.$store.dispatch("patchDeliveryAreaCoordinate", data);
              // console.log("not empty", data);
            } else {
              let data = {
                delivery_area_id: id,
                coordinate: element,
              };
              // console.log("empty", data);
              await this.$store.dispatch("saveDeliveryAreaCoordinate", data);
            }
          }

          //redirect
          this.$router.push("/deliveryAreas");
        } catch (e) {
          alert(Object.values(e.response.data.messages).join(" "));
        }
      }
    },

    back() {
      this.$router.go(-1);
    },
  },
};
</script>
<style>
.is-invalid {
  border-color: rgb(253, 165, 49);
}
</style>
