<template>
  <!-- Container -->
  <div class="w-11/12 mx-auto" v-if="this.getOrders.length > 0">
    <h2 class="big-title text-white mb-14">
      Sales
    </h2>

    <!-- Container table -->
    <div class="form-container">
      <!-- Gradient Background -->
      <div class="gradient-background"></div>

      <!-- Title and search box -->
      <div
        class="sm:flex sm:justify-between sm:items-center mb-4 sm:mr-8 relative z-10"
      >
        <h3 class="form-title ">Orders</h3>

        <form class="flex">
          <img
            src="../../assets/images/search.svg"
            alt=""
            class="w-4 mb-1 transform translate-x-4"
          />
          <input
            class="search"
            type="search"
            name="search"
            placeholder="Search"
            autocomplete="off"
            v-model="search"
          />
        </form>
      </div>
      <!-- Table -->
      <div
        class=" overflow-x-auto md:overflow-hidden text-sm xl:text-base relative z-10"
      >
        <table id="my-table" class="w-full">
          <tr class="text-left text-tableTitleColor">
            <th @click="sort('id')" class="pb-4 cursor-pointer">Id</th>
            <th @click="sort('status')" class="pb-4 cursor-pointer">Status</th>
            <th @click="sort('total')" class="pb-4 cursor-pointer">Total</th>
            <th @click="sort('created_at')" class="pb-4 cursor-pointer">
              Created at
            </th>
          </tr>
          <tr
            v-for="(order, i) in filteredList"
            :key="order.id"
            v-show="setPaginate(i)"
            class="border-b border-tableBorderColor text-tableSubColor"
          >
            <td class="py-4">{{ order.id }}</td>
            <td>{{ order.status }}</td>
            <td>{{ order.total }}</td>
            <td>{{ order.created_at }}</td>
            <td>
              <router-link
                :to="{
                  name: 'OrdersId',
                  params: { id: order.id },
                }"
              >
                <font-awesome-icon
                  icon="eye"
                  class="text-sm xl:text-base mr-1 xl:mr-0 cursor-pointer"
              /></router-link>
            </td>
          </tr>
        </table>
      </div>
      <!-- Pagination -->
      <div class="flex items-center justify-between pt-8 text-tableSubColor">
        <div>
          <p class="hidden md:block text-sm xl:text-base">
            Showing
            {{ current * paginate }} items of
            {{ filteredList.length }}
          </p>
        </div>
        <nav class="flex">
          <button class="pr-4 " @click="prev">
            <font-awesome-icon
              icon="chevron-left"
              class="cursor-pointer text-xs xl:text-sm"
            />
          </button>
          <div v-for="pageIndex in totalPage" :key="pageIndex">
            <button
              class="text-sm xl:text-base w-6 h-6 xl:w-8 xl:h-8 m-1 text-center rounded-full hover:bg-sideDashboard"
              v-if="
                Math.abs(pageIndex - this.current) < 3 ||
                  pageIndex == this.totalPage ||
                  pageIndex == 1
              "
              @click="updateCurrent(pageIndex)"
              :class="{
                'bg-goldBeige shadow-xl text-white border-goldBeige hover:bg-goldBeige':
                  pageIndex == current,
                last:
                  pageIndex == totalPage &&
                  Math.abs(pageIndex - this.current) > 3,
                first: pageIndex == 1 && Math.abs(pageIndex - this.current) > 3,
              }"
            >
              {{ pageIndex }}
            </button>
          </div>

          <button class="pl-4" @click="next">
            <font-awesome-icon
              icon="chevron-right"
              class="cursor-pointer text-xs md:text-sm"
            />
          </button>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "OrdersAdmin",

  data() {
    return {
      search: "",
      current: 1,
      paginate: 10,
      showPopUp: false,
      selectedElement: null,
      currentSort: "name",
      currentSortDir: "asc",
    };
  },

  async beforeMount() {
    await this.fetchOrders;
  },

  computed: {
    ...mapActions(["fetchOrders"]),
    ...mapGetters(["getOrders"]),

    filteredList() {
      const orders = this.getOrders.slice().reverse();
      return orders
        .filter((order) => {
          return order.status.toLowerCase().includes(this.search.toLowerCase());
        })
        .sort((a, b) => {
          let modifier = 1;
          if (this.currentSortDir === "desc") modifier = -1;
          if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
          if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
          return 0;
        });
    },

    totalPage() {
      return Math.ceil(this.filteredList.length / this.paginate);
    },
  },

  methods: {
    sort(columnTitle) {
      //if columnTitle == current sort, reverse
      if (columnTitle === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = columnTitle;
    },

    /* ------- Pagination ------- */
    setPaginate(i) {
      if (this.current == 1) {
        return i < this.paginate;
      } else {
        return (
          i >= this.paginate * (this.current - 1) &&
          i < this.current * this.paginate
        );
      }
    },

    updateCurrent(i) {
      this.current = i;
    },

    next() {
      if (this.current !== this.totalPage) {
        this.current++;
      }
    },
    prev() {
      if (this.current !== 1) {
        this.current--;
      }
    },
  },
};
</script>
<style>
.first::after {
  content: "...";
}

.last::before {
  content: "...";
}
</style>
