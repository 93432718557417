<template>
  <button class="w-20 py-2 rounded shadow-md text-sm font-bold text-white">
    <font-awesome-icon v-if="icon" :icon="icon" class="text-xs xl:text-sm" />
    {{ btnTitle }}
  </button>
</template>
<script>
export default {
  name: "Button",
  props: {
    btnTitle: String,
    icon: {
      type: String,
      required: false,
    },
  },
};
</script>
