<template>
  <div class="w-11/12 mx-auto" v-if="this.getUsers.length > 0">
    <h2 class="big-title">
      User
    </h2>
    <div class="w-full flex justify-end">
      <Button
        @click="generatePdf"
        btnTitle="Export"
        icon="user-edit"
        class="bg-exportBtn mr-4"
      />

      <router-link :to="{ name: 'UserCreate' }">
        <Button btnTitle="New" icon="plus" class="bg-greenBtn"
      /></router-link>
    </div>
    <!-- Container table -->
    <div class="form-container">
      <!-- Gradient Background -->
      <div class="gradient-background"></div>
      <!-- Title and search box -->
      <div
        class="sm:flex sm:justify-between sm:items-center mb-8 sm:mr-8 relative z-10"
      >
        <div class="mb-4">
          <select
            name="role"
            v-model="selectedOption"
            class="text-2xl font-semibold text-subTitle bg-whiteTrans focus:outline-none"
          >
            <option value="">All users</option>
            <option value="Administrator">Administrator</option>
            <option value="Restaurant">Restaurant owner</option>
            <option value="Kitchen">Kitchen staff</option>
            <option value="Driver">Driver</option>
            <option value="Client">Client</option>
          </select>
        </div>
        <form class="flex">
          <img
            src="../../assets/images/search.svg"
            alt=""
            class="w-4 mb-1 transform translate-x-4"
          />
          <input
            class="w-60 pl-6 pb-1 border-b  text-tableSubColor border-tableSubColor bg-whiteTrans focus:outline-none text-sm"
            type="search"
            name="search"
            placeholder="Search"
            v-model="search"
          />
        </form>
      </div>
      <!-- Table -->
      <div
        class=" overflow-x-auto md:overflow-hidden text-sm xl:text-base relative z-10"
      >
        <table id="my-table" class="w-full">
          <tr class="text-left text-tableTitleColor">
            <th @click="sort('email')" class="pb-4 cursor-pointer">Email</th>
            <th @click="sort('username')" class="pb-4 cursor-pointer">
              Username
            </th>
            <th @click="sort('first_name')" class="pb-4 cursor-pointer">
              Name
            </th>
            <th @click="sort('role')" class="pb-4 cursor-pointer">Role</th>
            <th @click="sort('co_points')" class="pb-4 cursor-pointer">
              Points
            </th>
            <th @click="sort('created_at')" class="pb-4 cursor-pointer">
              Created at
            </th>
            <th @click="sort('last_activity_date')" class="pb-4 cursor-pointer">
              Last activity
            </th>
          </tr>
          <tr
            v-for="(user, i) in filteredList"
            :key="user.id"
            v-show="setPaginate(i)"
            class="border-b border-tableBorderColor text-tableSubColor"
          >
            <td class="py-4">{{ user.email }}</td>
            <td>{{ user.username }}</td>
            <td>{{ user.first_name }} {{ user.last_name }}</td>
            <td>{{ user.role }}</td>
            <td>{{ user.co_points }}</td>
            <td>{{ user.created_at }}</td>
            <td>{{ user.last_activity_date }}</td>
            <td>
              <router-link :to="{ name: 'UserEdit', params: { id: user.id } }">
                <font-awesome-icon
                  icon="user-edit"
                  title="Edit"
                  class="text-xs lg:text-sm xl:text-base mr-1 xl:mr-0 cursor-pointer"
              /></router-link>
            </td>
            <td>
              <font-awesome-icon
                icon="trash"
                title="Delete"
                @click="togglePopUp(user.id)"
                class="text-xs lg:text-sm xl:text-base cursor-pointer"
              />
            </td>
          </tr>
        </table>
      </div>
      <!-- Pagination -->
      <div class="flex items-center justify-between pt-8 text-tableSubColor">
        <div>
          <p class="hidden md:block text-sm xl:text-base">
            Showing
            {{ current * paginate }} items of
            {{ filteredList.length }}
          </p>
        </div>
        <nav class="flex">
          <button class="pr-4 " @click="prev">
            <font-awesome-icon
              icon="chevron-left"
              class="cursor-pointer text-xs xl:text-sm"
            />
          </button>
          <div v-for="pageIndex in totalPage" :key="pageIndex">
            <button
              class="text-sm xl:text-base w-6 h-6 xl:w-8 xl:h-8 m-1 text-center rounded-full hover:bg-sideDashboard"
              v-if="
                Math.abs(pageIndex - this.current) < 3 ||
                  pageIndex == this.totalPage ||
                  pageIndex == 1
              "
              @click="updateCurrent(pageIndex)"
              :class="{
                'bg-goldBeige shadow-xl text-white border-goldBeige hover:bg-goldBeige':
                  pageIndex == current,
                last:
                  pageIndex == totalPage &&
                  Math.abs(pageIndex - this.current) > 3,
                first: pageIndex == 1 && Math.abs(pageIndex - this.current) > 3,
              }"
            >
              {{ pageIndex }}
            </button>
          </div>
          <button class="pl-4" @click="next">
            <font-awesome-icon
              icon="chevron-right"
              class="cursor-pointer text-xs xl:text-sm"
            />
          </button>
        </nav>
      </div>
    </div>
    <!-- Pop-up  -->
    <transition name="slide-fade">
      <PopUp v-show="showPopUp" @remove="remove" @cancel="cancel" />
    </transition>
  </div>
</template>

<script>
import Button from "../../components/Button.vue";
import PopUp from "../../components/PopUp.vue";
import { mapActions, mapGetters } from "vuex";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

export default {
  name: "User",
  components: {
    Button,
    PopUp,
  },
  data() {
    return {
      search: "",
      selectedOption: "",
      current: 1,
      paginate: 8,
      showPopUp: false,
      selectedElement: null,
      currentSort: "name",
      currentSortDir: "asc",
    };
  },

  async beforeMount() {
    if (this.getUsers.length == 0) {
      await this.fetchUsers;
    }
  },

  computed: {
    ...mapActions(["fetchUsers"]),
    ...mapGetters(["getUsers"]),

    filteredList() {
      const users = this.getUsers.slice().reverse();
      return users
        .filter((user) => {
          return user.role
            .toLowerCase()
            .includes(this.selectedOption.toLowerCase());
        })
        .filter((user) => {
          return (
            user.first_name.toLowerCase().includes(this.search.toLowerCase()) ||
            user.email.toLowerCase().includes(this.search.toLowerCase()) ||
            user.role.toLowerCase().includes(this.search.toLowerCase())
          );
        })
        .sort((a, b) => {
          let modifier = 1;
          if (this.currentSortDir === "desc") modifier = -1;
          if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
          if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
          return 0;
        });
    },

    totalPage() {
      return Math.ceil(this.filteredList.length / this.paginate);
    },
  },

  methods: {
    sort(columnTitle) {
      //if columnTitle == current sort, reverse
      if (columnTitle === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = columnTitle;
    },

    /* ------- Export ------- */
    generatePdf() {
      const doc = new jsPDF();

      doc.autoTable({ html: "#my-table" });
      doc.save("a4.pdf");
    },

    /* ------- Pop-up Remove User ------- */
    togglePopUp(id) {
      this.selectedElement = id;
      this.showPopUp = true;
    },

    remove() {
      this.showPopUp = false;
      this.$store.dispatch("deleteUser", this.selectedElement);
    },

    cancel() {
      this.showPopUp = false;
    },

    /* ------- Pagination ------- */
    setPaginate(i) {
      if (this.current == 1) {
        return i < this.paginate;
      } else {
        return (
          i >= this.paginate * (this.current - 1) &&
          i < this.current * this.paginate
        );
      }
    },

    updateCurrent(i) {
      this.current = i;
    },

    next() {
      if (this.current !== this.totalPage) {
        this.current++;
      }
    },
    prev() {
      if (this.current !== 1) {
        this.current--;
      }
    },
  },
};
</script>
