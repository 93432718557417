import axios from "axios";
const state = {
  points: [],
};

const getters = {
  getPoints: (state) => state.points,
  getPointById: (state) => (id) => {
    return state.points.filter((item) => item.id === id);
  },
};

const mutations = {
  SET_POINTS(state, payload) {
    state.points = payload;
  },

  ADD_POINT(state, payload) {
    state.points.push(payload);
  },

  REMOVE_POINT(state, payload) {
    state.points = state.points.filter((point) => point.id !== payload);
  },

  UPDATE_POINT(state, payload) {
    state.points.forEach((point, i) => {
      if (point.id == payload.id) {
        state.points[i] = payload;
      }
    });
  },
};

const actions = {
  async fetchPoints({ commit }) {
    const { data } = await axios.get("/points");
    commit("SET_POINTS", data.data);
  },

  async savePoint({ commit }, payload) {
    const { data } = await axios.post("/points", payload);
    commit("ADD_POINT", data.data);
  },

  async deletePoint({ commit }, id) {
    await axios.delete(`points/${id}`);
    commit("REMOVE_POINT", id);
  },

  async patchPoint({ commit }, payload) {
    const { data } = await axios.patch(`/points/${payload.id}`, payload);
    commit("UPDATE_POINT", data.data);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
