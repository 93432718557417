import axios from "axios";

const state = {
  user: null,
  access_token: null,
};

const getters = {
  isAuthenticated: (state) => !!state.user,
  getUser: (state) => state.user,
  getToken: (state) => state.access_token,
};

const mutations = {
  SET_USER(state, data) {
    state.user = data;
  },

  SET_TOKEN(state, token) {
    state.access_token = token;
  },
};

const actions = {
  async LogIn({ dispatch }, payload) {
    const { data } = await axios.post("auth/login", payload);
    return dispatch("attempt", data.data.access_token);
  },

  async attempt({ commit }, token) {
    commit("SET_TOKEN", token);

    try {
      const { data } = await axios.get("me");
      commit("SET_USER", {
        full_name: data.data.full_name,
        avatar: data.data.avatar,
        role: data.data.role,
      });
    } catch (e) {
      commit("SET_TOKEN", null);
      commit("SET_USER", null);
    }
  },

  async getMe({ commit }) {
    try {
      const { data } = await axios.get("me");
      commit("SET_USER", {
        full_name: data.data.full_name,
        avatar: data.data.avatar,
        role: data.data.role,
      });
    } catch (e) {
      commit("SET_USER", null);
    }
  },

  async reset(payload) {
    const { data } = await axios.post("auth/password/forgot", payload);
    console.log(data);
  },

  async update(payload) {
    const { data } = await axios.post(
      `auth/password/recover/${payload.token}`,
      payload
    );
    console.log(data);
  },

  async LogOut({ commit }) {
    return axios.post("/auth/logout").then(() => {
      commit("SET_TOKEN", null);
      commit("SET_USER", null);
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
