<template>
  <!-- Container -->
  <div class="w-11/12 mx-auto">
    <h2 class="big-title mb-14">
      Menu
    </h2>
    <!-- Form container -->
    <div class="form-wrapper 2xl:w-9/12 mx-auto">
      <h3 class="form-title ">
        Edit menu
      </h3>

      <!-- Form -->
      <form @submit.prevent="onSubmit" class="text-tableSubColor font-bold">
        <div class="md:flex">
          <!-- Select Restaurant -->
          <div class="w-full mb-4 md:mr-8">
            <label>Select Restaurant
              <select name="restaurant" v-model="model.restaurant_id" class="input" :class="{
        'is-invalid': v$.model.restaurant_id.$error,
      }">
                <option v-for="restaurant in getRestaurants" :key="restaurant.id" :value="restaurant.id">{{
        restaurant.name }}</option>
              </select>
              <span v-if="v$.model.restaurant_id.$error" class="text-sm font-normal text-invalidColor">{{
        v$.model.restaurant_id.$errors[0].$message }}</span>
            </label>
          </div>
          <!-- Name -->
          <div class="w-full mb-4">
            <label>Name
              <input type="text" name="name" autocomplete="off" v-model="model.name" class="input" :class="{
        'is-invalid': v$.model.name.$error,
      }" />
              <span v-if="v$.model.name.$error" class="text-sm font-normal text-invalidColor">{{
        v$.model.name.$errors[0].$message }}</span>
            </label>
          </div>
        </div>

        <!-- Days and times-->
        <div class="md:flex">
          <week-from-to-selector :times="model.times" @time-updated="updateTimes" />
        </div>
        <span v-if="v$.model.times.$error" class="text-sm font-normal text-invalidColor">The fields for days and times
          are required</span>

        <!-- Buttons -->
        <div class="w-full flex justify-end pt-4">
          <Button type="button" @click.prevent="back" btnTitle="Cancel" class="mr-4 bg-goldBeige text-black" />
          <Button type="submit" btnTitle="Save" class="bg-greenBtn" />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Button from "../../../components/Button.vue";
import { mapGetters, mapActions } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import WeekFromToSelector from "../../../components/WeekFromToSelector.vue";

export default {
  name: "MenusEdit",
  components: {
    Button,
    WeekFromToSelector
  },
  data() {
    return {
      v$: useVuelidate(),
      model: {
        name: "",
        restaurant_id: "",
        times: {},
      },
      isValidTimes: true
    };
  },

  /* --- Validation --- */
  validations() {
    return {
      model: {
        name: { required },
        restaurant_id: { required },
        times: { required }
      },
    };
  },

  async beforeMount() {
    if (this.getRestaurants.length == 0) {
      this.fetchRestaurants;
    }

    let id = parseInt(this.$route.params.id);
    await this.$store.dispatch("fetchMenuById", id);
    this.model = this.getMenuID;
  },

  computed: {
    ...mapActions(["fetchRestaurants"]),
    ...mapGetters(["getRestaurants", "getMenuID"]),
  },

  methods: {
    updateTimes(updatedTimes) {
      this.isValidTimes = updatedTimes.isValid; // Update validity state
      this.model.times = updatedTimes.times;
    },

    async onSubmit() {
      this.v$.$validate();
      if (!this.v$.$error && this.isValidTimes) {
        try {
          await this.$store.dispatch("patchMenu", this.model);
          //redirect
          this.$router.push("/menus");
        } catch (e) {
          alert(Object.values(e.response.data.messages).join(" "));
        }
      }
    },

    back() {
      this.$router.go(-1);
    },
  },
};
</script>
