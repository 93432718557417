<template>
  <div
    class="w-full h-screen bg-loginBg flex justify-center md:items-center overflow-x-hidden"
  >
    <!-- Background circles -->
    <div class="hidden md:block">
      <div
        class="w-96 h-96 rounded-full bg-round absolute bottom-10 right-0"
      ></div>
      <div
        class="w-40 h-40 rounded-full bg-round absolute bottom-10 right-0"
      ></div>
      <div
        class="w-72 h-72 rounded-full bg-round absolute bottom-15 right-0"
      ></div>
    </div>

    <div
      class="relative md:w-6/12 md:h-full md:flex md:flex-col md:justify-center 2xl:justify-start md:items-center 2xl:mt-44 2xl:pt-40"
    >
      <!-- Background circles -->
      <div class="hidden md:block">
        <div
          class="w-96 h-96 rounded-full bg-round absolute top-10 -left-32 xl:left-0"
        ></div>
        <div
          class="w-40 h-40 rounded-full bg-round absolute top-10 -left-32 xl:left-0"
        ></div>
        <div
          class="w-72 h-72 rounded-full bg-round absolute top-15 -left-32 xl:left-0"
        ></div>
      </div>

      <!-- Form INLOG container -->
      <div
        class="md:w-96 mx-auto py-4 px-8 mt-16 md:mt-0 bgInlogForm rounded-3xl"
      >
        <!-- Logo -->
        <img
          src="@/assets/images/chingaling-logo.png"
          alt=""
          class="w-32 mx-auto my-8"
        />

        <!-- Form -->
        <form @submit.prevent="submit">
          <!-- Password input -->
          <div class="relative mb-4">
            <font-awesome-icon
              icon="key"
              class="text-lg text-white absolute top-3 left-4"
            />
            <input
              class="w-full pl-12 py-2 bg-whiteTrans border-tableBorderColor border rounded outline-none text-white"
              :type="type"
              placeholder="New Password"
              v-model="password"
              :class="{
                'is-invalid': v$.password.$error,
              }"
            />
            <span
              v-if="v$.password.$error"
              class="text-sm font-normal text-invalidColor"
              >{{ v$.password.$errors[0].$message }}</span
            >
          </div>
          <!-- Password Confirm input -->
          <div class="relative mb-4">
            <font-awesome-icon
              icon="key"
              class="text-md text-white absolute top-3 left-4"
            />
            <input
              class="w-full pl-12 py-2 bg-whiteTrans border-tableBorderColor border rounded outline-none text-white"
              :type="type"
              placeholder="Confirm Password"
              v-model="password_confirm"
              :class="{
                'is-invalid': v$.password_confirm.$error,
              }"
            />
            <font-awesome-icon
              :icon="icon"
              class="text-md text-white absolute top-3 right-4"
              @click="showPassword"
            />
            <span
              v-if="v$.password_confirm.$error"
              class="text-sm font-normal text-invalidColor"
              >{{ v$.password_confirm.$errors[0].$message }}</span
            >
          </div>
          <!-- Sign in Button -->
          <Button
            type="submit"
            btnTitle="Update"
            class="bg-exportBtn w-full h-11 my-8 shadow-lg"
          ></Button>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import Button from "../components/Button.vue";
import useVuelidate from "@vuelidate/core";
import { required, sameAs } from "@vuelidate/validators";

export default {
  name: "Login",
  components: {
    Button,
  },
  data() {
    return {
      v$: useVuelidate(),
      password: "",
      password_confirm: "",
      type: "password",
      icon: "eye-slash",
    };
  },

  validations() {
    return {
      password: { required },
      password_confirm: { required, sameAs: sameAs(this.password) },
    };
  },

  methods: {
    showPassword() {
      if (this.type === "password") {
        this.type = "text";
        this.icon = "eye";
      } else {
        this.type = "password";
        this.icon = "eye-slash";
      }
    },

    async submit() {
      this.v$.$validate();
      if (!this.v$.$error) {
        const formData = new FormData();
        formData.append("password", this.password);
        formData.append("password_confirm", this.password_confirm);
        formData.append("token", this.$route.params.token);
        try {
          await this.$store.dispatch("update", formData);
          this.$router.push("/login");
        } catch (e) {
          alert(Object.values(e.response.data.messages).join(" "));
        }
      }
    },
  },
};
</script>
<style>
.size {
  width: 60rem;
  height: 60rem;
}
.bgInlogForm {
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(5px);
}
@media (max-width: 640px) {
  .bgInlogForm {
    background-color: rgba(255, 255, 255, 0);
    backdrop-filter: blur(0px);
  }
}
</style>
