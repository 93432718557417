import axios from "axios";

const state = {
  menuItems: [],
  menu: {},
};

const getters = {
  getMenuItems: (state) => state.menuItems,
  getMenuItemtById: (state) => (id) => {
    return state.menuItems.filter((item) => item.id === id);
  },
  getMenuItemsBycategory: (state) => (category) => {
    return state.menuItems.filter((item) => {
      for (let i = 0; i < item.categories.length; i++) {
        const element = item.categories[i];
        return element === category;
      }
    });
  },
  getMenu1: (state) => {
    return state.menu;
  },
};

const mutations = {
  SET_MENU_ITEMS(state, payload) {
    state.menuItems = payload;
  },

  ADD_MENU_ITEM(state, payload) {
    state.menuItems.push(payload);
  },

  REMOVE_MENU_ITEM(state, payload) {
    state.menuItems = state.menuItems.filter((item) => item.id !== payload);
  },

  UPDATE_MENU_ITEM(state, payload) {
    state.menuItems.forEach((item, i) => {
      if (item.id == payload.id) {
        state.menuItems[i] = payload;
      }
    });
  },

  setMenu(state, payload) {
    state.menu = payload;
  },
};

const actions = {
  async fetchMenu1({ commit }, restaurantId) {
    // console.log(restaurantId);

    const response = await axios.get(`/menus/${restaurantId}
    ?load=categories,categories.items,categories.items.variations,categories.items.variations.ingredients`);

    if (response.status === 200) {
      const payload = {};
      payload.data = response.data;
      commit("setMenu", payload.data.data);
    } else {
      // console.log(response.message);
    }
  },

  async fetchMenuItems({ commit }) {
    const { data } = await axios.get("/cms-menu-items");
    commit("SET_MENU_ITEMS", data.data);
  },

  async saveMenuItem({ commit }, payload) {
    const { data } = await axios.post("/cms-menu-items", payload);
    commit("ADD_MENU_ITEM", data.data);
  },

  async deleteMenuItem({ commit }, id) {
    await axios.delete(`/cms-menu-items/${id}`);
    commit("REMOVE_MENU_ITEM", id);
  },

  async patchMenuItem({ commit }, payload) {
    const { data } = await axios.patch(
      `/cms-menu-items/${payload.id}`,
      payload
    );
    commit("UPDATE_MENU_ITEM", data.data);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
