import axios from "axios";

const state = {
  users: [],
  me: null,
};

const getters = {
  getUsers: (state) => state.users,
  getUserById: (state) => (id) => {
    return state.users.filter((item) => item.id === id);
  },
  getMe: (state) => state.me,
};

const mutations = {
  SET_USERS(state, payload) {
    state.users = payload;
  },

  ADD_USER(state, payload) {
    state.users.push(payload);
  },

  REMOVE_USER(state, payload) {
    state.users = state.users.filter((user) => user.id !== payload);
  },

  UPDATE_USER(state, payload) {
    state.users.forEach((user, i) => {
      if (user.id == payload.id) {
        state.users[i] = payload;
      }
    });
  },
  SET_ME(state, data) {
    state.me = data;
  },
};

const actions = {
  async fetchUsers({ commit }) {
    const { data } = await axios.get("/users");
    commit("SET_USERS", data.data);
  },

  async saveUser({ commit }, payload) {
    const { data } = await axios.post("/users", payload);
    commit("ADD_USER", data.data);
  },

  async deleteUser({ commit }, id) {
    await axios.delete(`users/${id}`);
    commit("REMOVE_USER", id);
  },

  async patchUser({ commit }, payload) {
    let id = payload.get("id");
    // console.log(id);
    const { data } = await axios.post(`/users/${id}`, payload);
    // console.log(data);
    commit("UPDATE_USER", data.data);
  },

  async fetchMe({ commit }) {
    const { data } = await axios.get("/me");
    commit("SET_ME", data.data);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
