import axios from "axios";

const state = {
  generalSettings: [],
};

const getters = {
  getGeneralSettings: (state) => state.generalSettings,
  getGeneralSettingById: (state) => (id) => {
    return state.generalSettings.filter((item) => item.id === id);
  },
};

const mutations = {
  SET_GENERAL_SETTINGS(state, payload) {
    state.generalSettings = payload;
  },

  ADD_GENERAL_SETTING(state, payload) {
    state.generalSettings.push(payload);
  },

  REMOVE_GENERAL_SETTING(state, payload) {
    state.generalSettings = state.generalSettings.filter(
      (item) => item.id !== payload
    );
  },

  UPDATE_GENERAL_SETTING(state, payload) {
    state.generalSettings.forEach((item, i) => {
      if (item.id == payload.id) {
        state.generalSettings[i] = payload;
      }
    });
  },
};

const actions = {
  async fetchGeneralSettings({ commit }) {
    const { data } = await axios.get("/general-settings");
    commit("SET_GENERAL_SETTINGS", data.data);
  },

  async saveGeneralSetting({ commit }, payload) {
    const { data } = await axios.post("/general-settings", payload);
    commit("ADD_GENERAL_SETTING", data.data);
  },

  async deleteGeneralSetting({ commit }, id) {
    await axios.delete(`general-settings/${id}`);
    commit("REMOVE_GENERAL_SETTING", id);
  },

  async patchGeneralSetting({ commit }, payload) {
    const { data } = await axios.patch(
      `/general-settings/${payload.id}`,
      payload
    );
    commit("UPDATE_GENERAL_SETTING", data.data);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
