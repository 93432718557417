<template>
  <!-- Container -->
  <div class="w-11/12 mx-auto">
    <h2 class="big-title mb-14">
      Sales
    </h2>
    <!-- Buttons -->
    <div class="w-full">
      <Button
        type="button"
        @click.prevent="back"
        btnTitle="Back"
        icon="chevron-left"
        class="bg-goldBeige text-black"
      />
    </div>
    <!-- Form container -->
    <div class="form-container">
      <!-- Gradient Background -->
      <div class="gradient-background"></div>

      <div class="relative z-10">
        <h5 class="font-semibold text-subTitle text-base">
          Order #{{ $route.params.id }}
        </h5>
        <h3 class="form-title">
          Order
        </h3>
        <hr class="border-xs border-tableBorderColor" />
        <h5 class="text-sm font-semibold uppercase py-8 text-tableSubColor">
          Order information
        </h5>
        <!-- Order Created at -->
        <div class="xl:w-9/12 md:flex justify-between px-4 text-tableSubColor">
          <div class="mb-4">
            <label class="font-semibold text-tableTitleColor"
              >Order Created At</label
            >
            <h6 class="pt-2 font-semibold">{{ model.created_at }}</h6>
          </div>
          <div class="mb-4 font-semibold">
            <label class="font-semibold text-tableTitleColor"
              >Order Status</label
            >
            <h6 class="pt-2 uppercase">{{ model.status }}</h6>
          </div>
          <div class="mb-4 font-semibold">
            <label class="font-semibold text-tableTitleColor"
              >Order Total</label
            >
            <h6 class="pt-2">{{ model.total }}</h6>
          </div>
        </div>
        <!-- Order Itmes -->
        <h5 class="text-sm font-semibold uppercase py-8 text-tableSubColor">
          Order items
        </h5>
        <ul class="mb-8 font-semibold">
          <li
            v-for="item in model.items"
            :key="item.id"
            class="flex text-tableSubColor"
          >
            <div class="mr-4">{{ item.quantity }}</div>
            <div class="mr-4">{{ item.name ? item.name : "-" }}</div>
            <div class="mr-4">{{ item.total }} :-</div>
          </li>
        </ul>

        <!-- ===== Account ===== -->
        <h3 class="form-title mt-16">
          Account
        </h3>
        <hr class="border-xs border-tableBorderColor" />
        <h5 class="text-sm font-semibold uppercase py-8 text-tableSubColor">
          Account information
        </h5>
        <!-- Account information -->
        <div
          class="xl:w-9/12 md:flex justify-between px-4 text-tableSubColor mb-16 font-semibold"
        >
          <div class="mb-4">
            <label class="font-semibold text-tableTitleColor"
              >Customer Name</label
            >
            <h6 class="pt-2 uppercase">
              {{ model.full_name }}
            </h6>
          </div>
          <div class="mb-4">
            <label class="font-semibold text-tableTitleColor">Email</label>
            <h6 class="pt-2 uppercase">{{ model.email }}</h6>
          </div>
          <div class="mb-4">
            <label class="font-semibold text-tableTitleColor"
              >Mobile Number</label
            >
            <h6 class="pt-2">{{ model.mobile_number }}</h6>
          </div>
        </div>

        <!--  ===== Address ===== -->
        <div v-if="model.address !== null">
          <h3 class="form-title">
            Address
          </h3>
          <hr class="border-xs border-tableBorderColor" />
          <h5 class="text-sm font-semibold uppercase py-8 text-tableSubColor">
            Shipping address
          </h5>
          <!-- Order Created at -->
          <div
            class="xl:w-9/12 md:flex justify-between px-4 text-tableSubColor font-semibold"
          >
            <div class="mb-4">
              <label class="font-semibold text-tableTitleColor">Address</label>
              <h6 class="pt-2 uppercase">{{ model.address }}</h6>
            </div>
            <div class="mb-4">
              <label class="font-semibold text-tableTitleColor">City</label>
              <h6 class="pt-2 uppercase">{{ model.city }}</h6>
            </div>
            <div class="mb-4">
              <label class="font-semibold text-tableTitleColor">Postcode</label>
              <h6 class="pt-2">{{ model.postcode }}</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "../../components/Button.vue";
import axios from "axios";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "OrdersId",
  components: {
    Button,
  },
  data() {
    return {
      model: {},
    };
  },

  async beforeMount() {
    if (this.getOrders.length == 0) {
      await this.fetchOrders;
    }
    this.getCurrentOrder();
  },

  computed: {
    ...mapGetters(["getOrderById", "getOrders"]),
    ...mapActions(["fetchOrders"]),
  },

  methods: {
    async getCurrentOrder() {
      let id = parseInt(this.$route.params.id);
      /*  this.model = this.getOrderById(id)[0]; */
      const { data } = await axios.get(`/admin/orders/${id}`);

      // console.log(data);

      this.model = {
        id: data.data.id,
        status: data.data.status,
        total: data.data.total,
        created_at: data.data.created_at,
        items: data.data.items,

        email: data.data.user ? data.data.user.email : null,
        mobile_number: data.data.user ? data.data.user.mobile_number : null,
        full_name: data.data.user ? data.data.user.full_name : null,

        address: data.data.address ? data.data.address.address_1 : null,
        city: data.data.address ? data.data.address.city : null,
        postcode: data.data.address ? data.data.address.postcode : null,
      };
    },

    back() {
      this.$router.go(-1);
    },
  },
};
</script>
<style></style>
