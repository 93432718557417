import { createApp } from "vue";
import App from "./App.vue";
import "./styles/app.css";
import router from "./router";
import FontAwesomeIcon from "./utilities/Icon";
import store from "./store";
import axios from "axios";

/* require("@/store/subscriber"); */

axios.interceptors.request.use(function(config) {
  const token = store.getters.getToken;
  config.headers.Authorization = `Bearer ${token}`;

  return config;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      store.dispatch("LogOut");
      // this.$router.push("/login");
      router.push("/login");
    }
    return error;
  }
);

//axios.defaults.withCredentials = true;
//axios.defaults.baseURL = "https://chingaling-api.jdlabs.se/v1";
//axios.defaults.baseURL = "http://chingaling-api-dev.jdlabs.se/v1";
//axios.defaults.baseURL = "http://127.0.0.1:8000/v1";
//axios.defaults.baseURL = "https://chingaling-api-dev.jdlabs.se/v1";
axios.defaults.baseURL = "https://chingaling-api-v2.jdlabs.se/v1";

axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

createApp(App)
  .component("font-awesome-icon", FontAwesomeIcon)
  .use(router)
  .use(store)
  .mount("#app");
