<template>
  <div class="h-72">
    <canvas id="bar-chart"></canvas>
  </div>
</template>

<script>
import Chart from "chart.js";
import barChartData from "./bar-chart-orders-data";
import { mapGetters } from "vuex";

export default {
  name: "BarChartOrders",

  data() {
    return {
      barChartData,
      data: {
        type: "bar",
        data: {
          labels: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
          datasets: [
            {
              label: "My First Dataset",
              data: [],
              backgroundColor: "rgba(254, 141, 37, 0.7)",
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          tooltips: {
            displayColors: false,
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            titleAlign: "center",
            bodyFontSize: 14,
            caretPadding: 10,
            padding: 10,

            callbacks: {
              label: function(item) {
                let value = item.yLabel;
                value = value.toLocaleString();
                let label = "Orders: " + value;
                return label;
              },
            },
          },
          legend: {
            display: false,
          },
          scales: {
            yAxes: [
              {
                gridLines: {
                  display: false,
                  drawBorder: false,
                },
                ticks: {
                  beginAtZero: true,
                  padding: 10,
                },
              },
            ],
            xAxes: [
              {
                gridLines: {
                  display: false,
                },
              },
            ],
          },
        },
      },
    };
  },
  mounted() {
    let data = this.getDashboardData;
    // console.log(data);

    let array = [];

    Object.entries(data.ordered_by_month).forEach((test, i) => {
      array[i] = 0;
      array[test[0]] = test[1];
    });

    // console.log(array);

    this.data.data.datasets[0].data = array;

    const ctx = document.getElementById("bar-chart");
    new Chart(ctx, this.data);
  },
  computed: {
    ...mapGetters(["getDashboardData"]),
  },
};
</script>
