<template>
  <div class="w-11/12 mx-auto" v-if="this.ingredients != null">
    <h2 class="big-title mb-12">
      Menu
    </h2>
    <div class="form-container 2xl:w-9/12">
      <!-- Gradient Background -->
      <div class="gradient-background"></div>

      <h3 class="form-title relative z-10">
        Edit menu item
      </h3>

      <!-- Form -->
      <form
        @submit.prevent="onSubmit"
        class="text-tableSubColor font-bold relative z-10"
      >
        <div>
          <!-- Profil image -->
          <div class="md:flex">
            <div class="w-full mb-8 md:mb-4 mr-8">
              <div class="w-72 mx-auto flex items-center relative">
                <img class="rounded-lg" :src="img" alt="Select Food image" />
                <div class="file-input mt-4">
                  <input
                    type="file"
                    id="file"
                    class="file"
                    @change="filesChange"
                  />
                  <label
                    for="file"
                    class="bg-white text-subTitle border border-tableBorderColor px-3 py-2 rounded-full shadow-md text-sm font-bold cursor-pointer absolute -bottom-2.5 -right-2.5"
                    ><font-awesome-icon icon="plus"
                  /></label>
                </div>
              </div>
            </div>

            <div class="md:w-full">
              <!-- Select Menu Item Variation input -->
              <div class="w-full mb-4">
                <label
                  >Select Menu Item Variation
                  <select
                    name="menuItem.menuItemVariation_is_base"
                    v-model="this.is_base"
                    @change="resetConfigurableOrBundle"
                    class="input "
                  >
                    <option value="1">Base</option>
                    <option value="0">Configurable</option>
                    <option value="2">Bundle</option>
                  </select>
                </label>
              </div>
              <!-- Select Categories -->
              <div class="w-full mb-4">
                <label
                  >Select Categories
                  <Multiselect
                    :classes="{
                      container: 'multiselectContainer',
                      tag: 'bg-black text-white multiselectTag',
                      tagsSearch: 'multiselectTagsSearch',
                    }"
                    v-model="this.menuItem[0].categories"
                    mode="tags"
                    :closeOnSelect="false"
                    :searchable="true"
                    :options="this.categories"
                  />
                </label>
              </div>
            </div>
          </div>

          <div class="md:flex">
            <div class="md:w-full md:mr-8">
              <!-- Name -->
              <div class="w-full mb-4">
                <label
                  >Name
                  <input
                    type="text"
                    name="name"
                    autocomplete="off"
                    v-model="menuItem[0].name"
                    class="input"
                  />
                </label>
              </div>
              <!-- Price -->
              <div class="w-full mb-4" v-show="this.is_base != 0">
                <label
                  >Price
                  <input
                    type="number"
                    name="menuItemVariation_price"
                    autocomplete="off"
                    v-model="this.price"
                    class="input"
                  />
                </label>
              </div>

              <!-- Order -->
              <div class="w-full mb-4">
                <label
                  >Order
                  <input
                    type="number"
                    name="order"
                    autocomplete="off"
                    v-model="menuItem[0].order"
                    class="input"
                  />
                </label>
              </div>
            </div>

            <div class="md:w-full">
              <!-- Description -->
              <div class="w-full mb-3">
                <label
                  >Description
                  <textarea
                    rows="5"
                    cols="30"
                    name="description"
                    autocomplete="off"
                    v-model="menuItem[0].description"
                    class="input"
                  ></textarea>
                </label>
              </div>
              <div class="flex">
                <!-- Toggle Switch -->
                <div class="flex flex-col md:ml-1 md:mr-8">
                  <label for="is_active">Published</label>
                  <label class="switch mb-4">
                    <input
                      type="checkbox"
                      v-model="menuItem[0].is_active"
                      :false-value="parseInt(0)"
                      :true-value="parseInt(1)"
                    />
                    <span class="slider round"></span>
                  </label>
                </div>
                <!-- Toggle Switch -->
                <div class="flex flex-col md:ml-1 md:mr-8">
                  <label for="is_new">Is New</label>
                  <label class="switch mb-4">
                    <input
                      type="checkbox"
                      v-model="menuItem[0].is_new"
                      :false-value="parseInt(0)"
                      :true-value="parseInt(1)"
                    />
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <!-- ===== Configurable Container ===== -->
          <!-- Variations multiple -->
          <div class="w-full mb-4" v-show="this.is_base == 0">
            <label
              >Select Variations
              <Multiselect
                :classes="{
                  container: 'multiselectContainer',
                  tag: 'bg-loginBg text-goldBeige multiselectTag',
                  tagsSearch: 'multiselectTagsSearch',
                }"
                mode="tags"
                :closeOnSelect="false"
                :searchable="true"
                :options="this.varations"
                v-model="this.selectedVariations"
                @select="updateVariations($event, varation)"
                @deselect="deleteVariation($event, varation)"
              />
            </label>
          </div>
          <!-- Ingredient conatiner -->
          <div class="mt-16" v-show="this.is_base == 0">
            <h3
              class="py-4 text-xl text-subTitle border-b border-tableBorderColor"
            >
              Ingredients
            </h3>
            <p class="text-sm py-2">
              Start by choosing a price then select the ingredients which will
              be included into the menu and then choose if there will be any
              extra optinal indgredients included.
            </p>
            <div
              class="my-8"
              v-for="(variation, i) in this.selectedVariations"
              :key="i"
            >
              <h1>{{ variation }}</h1>

              <h5 class="text-md text-goldBeige uppercase bg-loginBg p-2">
                {{ this.getRightVarations(variation).name }}
              </h5>

              <!-- Price -->
              <div class="w-full flex justify-between items-center pt-8">
                <div class="w-80 mb-4">
                  <label
                    >Price
                    <input
                      type="text"
                      name="menuItemVariation_prices"
                      autocomplete="off"
                      :value="showPrice(variation)"
                      class="input"
                      @change="(e) => updatePrice(e, variation)"
                    />
                  </label>
                </div>
              </div>
              <!-- Ingredient List -->
              <div>
                <label
                  >Ingredient list
                  <Multiselect
                    :classes="{
                      container: 'multiselectContainer',
                      tag: 'bg-goldBeige text-black multiselectTag',
                      tagsSearch: 'multiselectTagsSearch',
                    }"
                    mode="tags"
                    :closeOnSelect="false"
                    :searchable="true"
                    :options="this.ingredients"
                    v-model="this.selectedIngredients[variation]"
                  />
                </label>
              </div>
              <!-- Option List -->
              <div class="py-4">
                <label
                  >Optional list
                  <Multiselect
                    :classes="{
                      container: 'multiselectContainer',
                      tag: 'bg-goldBeige text-black multiselectTag',
                      tagsSearch: 'multiselectTagsSearch',
                    }"
                    mode="tags"
                    :closeOnSelect="false"
                    :searchable="true"
                    :options="this.ingredients"
                    v-model="menuItemVariation_optionals[variation]"
                  />
                </label>
              </div>
            </div>
          </div>

          <!-- ===== Bundle Container ===== -->
          <!-- Categories bundle multiple -->
          <div class="w-full my-4" v-show="this.is_base == 2">
            <label
              >From which categories will the items in the bundle be?
              <Multiselect
                :classes="{
                  container: 'multiselectContainer',
                  tag: 'bg-loginBg text-goldBeige multiselectTag',
                  tagsSearch: 'multiselectTagsSearch',
                }"
                mode="tags"
                :closeOnSelect="false"
                :searchable="true"
                :options="this.categories"
                v-model="this.selectedBundleCategories"
              />
            </label>
          </div>
          <!-- Bundle -->
          <div v-if="this.bundleItems != null">
            <div
              v-for="value in this.bundleItems"
              :key="value.id"
              class="md:flex"
            >
              <div class="w-full mb-4 mr-8">
                <label
                  >Which items will be available for choosing?
                  <Multiselect
                    :classes="{
                      container: 'multiselectContainer',
                      tag: 'bg-loginBg text-goldBeige multiselectTag',
                      tagsSearch: 'multiselectTagsSearch',
                    }"
                    mode="tags"
                    :closeOnSelect="false"
                    :searchable="true"
                    v-model="bundleItemsForSelect[value.category_id]"
                    :options="this.getItemsByCategory(value.category_id)"
                  />
                </label>
              </div>
              <div class="w-full mb-4">
                <label
                  >How many items allowed for choosing?
                  <input
                    type="number"
                    name="menuItemVariation_price"
                    autocomplete="off"
                    placeholder="Enter a number.."
                    class="input"
                    v-model="value.max_items_per_category"
                  />
                </label>
              </div>
            </div>
          </div>
        </div>

        <!-- Buttons -->
        <div class="w-full flex justify-end mt-8">
          <Button
            type="button"
            @click.prevent="back"
            btnTitle="Cancle"
            class="mr-4 bg-goldBeige text-black"
          />
          <Button type="submit" btnTitle="Save" class="bg-greenBtn" />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Button from "../../../components/Button.vue";
import Multiselect from "@vueform/multiselect";
import axios from "axios";

export default {
  name: "MenuItemsEdit",
  components: { Multiselect, Button },
  data() {
    return {
      is_base: null,
      price: null,
      menuItemVarations: null,
      img: null,
      menuItemImg: null,
      menuItemVarationsIngredients: [],
      menuItemVariation: {
        is_base: 0,
        prices: [],
        multiple: [],
      },
      menus: null,
      categories: null,
      varations: null,
      ingredients: null,
      selectedVariations: [],
      selectedIngredients: [],
      selectedBundleCategories: [],
      bundleItems: [],
      bundleItemsForSelect: [],
      max_items_per_category: [],
      menuItemVariation_optionals: [],
      prices: [],
      menuItem: {
        name: "",
        order: "",
        description: "",
        categories: [],
        is_active: 1,
        is_new: 1,
        menuItemVariation_prices: {},
        menuItemVariation_price: "",
        menuItemVariation_is_base: null,
        menuItemVariation_multiple: [],
        selectedVariations: [],
        configurable_menu: false,
        prices: {},
        image_path: null,
        bundle_items: {
          categories: [],
        },
        menu_item_image: null,
      },
    };
  },

  async created() {
    await this.fetchMenuItems.then(() => {
      let id = parseInt(this.$route.params.id);
      let menuItem = this.getMenuItemtById(id);

      console.log("this is menuItem", menuItem);

      this.img = menuItem[0].image_path;

      this.menuItem = menuItem;
    });

    await this.fetchMenuItemsVariations.then(() => {
      let menuItemVarations = this.getMenuItemsVariationById(
        this.menuItem[0].id
      );
      this.menuItemVarations = menuItemVarations;
      this.is_base = menuItemVarations[0].is_base;
      this.price = menuItemVarations[0].price;

      let array = [];
      menuItemVarations.forEach((element) => {
        array.push(element.variation_id);

        if (menuItemVarations[0].is_base == 2) {
          element.bundle_items.categories.forEach((categorie) => {
            this.selectedBundleCategories.push(categorie.category_id);
            this.bundleItems.push(categorie);

            categorie.items.forEach((element) => {
              this.bundleItemsForSelect[categorie.category_id] = [];

              this.bundleItemsForSelect[categorie.category_id].push(
                element.item_id
              );
            });
          });
        }
      });
      this.selectedVariations = array;
    });

    await this.fetchMenuItemVariationIngredients.then(() => {
      let array = [];
      let optional = [];

      this.menuItemVarations.forEach(async (element) => {
        let id = element.variation_id;

        let ingriedients = this.getMenuItemVariationIngredientsById(element.id);

        let ingriedientsArray = [];
        let optionalArray = [];
        ingriedients.forEach((ingriedient) => {
          // console.log(ingriedient);

          if (ingriedient.is_optional) {
            // console.log("found");
            optionalArray.push(ingriedient.ingredient_id);
          }

          ingriedientsArray.push(ingriedient.ingredient_id);
        });

        array[id] = ingriedientsArray;
        optional[id] = optionalArray;

        this.menuItemVarationsIngredients.push({
          varation: element.id,
          ingriedients,
        });
      });

      this.selectedIngredients = array;
      this.menuItemVariation_optionals = optional;
    });

    await this.fetchMenus.then(() => {
      this.menus = this.getMenus;
    });

    await this.fetchCategories.then(() => {
      // this.categories = this.getCategories;
      let array = [];
      this.getCategories.forEach((element) => {
        array.push({
          value: element.id,
          label: element.name,
        });
      });
      this.categories = array;
    });

    await this.fetchVariations.then(() => {
      // this.varations = this.getVariations;

      let array = [];
      this.getVariations.forEach((element) => {
        array.push({
          value: element.id,
          label: `${element.name} - ${element.type} `,
        });
      });
      this.varations = array;
    });

    await this.fetchIngredients.then(() => {
      // this.ingredients = this.getIngredients;

      let array = [];
      this.getIngredients.forEach((element) => {
        array.push({
          value: element.id,
          label: `${element.name} `,
        });
      });
      this.ingredients = array;
    });
  },

  mounted() {},

  methods: {
    getRight(id) {
      let name = "";
      let price = "";

      this.menuItemVarations.forEach((element) => {
        if (element.variation_id == id) {
          name = element.name;
          price = element.price;
        }
      });

      return { name, price };
    },

    getRightVarations(id) {
      let name = "";

      this.varations.forEach((element) => {
        if (element.value == id) {
          name = element.label;
        }
      });

      return { name };
    },

    updatePrice(e, variation) {
      // console.log(e);
      // console.log(variation);

      this.menuItemVarations.forEach((item) => {
        if (item.variation_id == variation) {
          item.price = e.target.value;
        }
      });
    },

    showPrice(variation) {
      let price = "";
      this.menuItemVarations.forEach((item) => {
        console.log(item.variation_id);
        console.log(variation);
        if (item.variation_id == variation) {
          price = item.price;
        }
      });
      return price;
    },

    async updateVariations(variation) {
      let empty = null;

      await this.menuItemVarations.forEach((item) => {
        if (item.variation_id !== variation) {
          empty = true;
        }
      });

      if (empty) {
        let name = "";
        this.varations.forEach((element) => {
          // console.log(element);
          if (element.value == variation) {
            name = element.label;
          }
        });

        this.menuItemVarations.push({
          id: null,
          name: name.split("-").reverse()[1],
          type: name.split("-")[1],
          price: "",
          is_base: 0,
          menu_item_id: this.$route.params.id,
          variation_id: variation,
        });
      }
    },

    deleteVariation(e) {
      // console.log(e);

      this.menuItemVarations.forEach((item, i) => {
        if (e == item.variation_id) {
          this.menuItemVarations.splice(i, 1);
        }
      });

      // this.selectedIngredients.forEach((item, i) => {
      //   console.log(i);

      //   if (i == e) {
      //     this.selectedIngredients.splice(i, 1);
      //   }
      // });
    },

    async onSubmit() {
      // console.log(
      //   this.menuItem,
      //   this.menuItemVarations,
      //   this.menuItemVarationsIngredients
      // );

      let pricesIngredients = {};

      this.selectedIngredients.forEach((ingriedient, i) => {
        let obj = {};
        obj[i] = {};

        this.ingredients.forEach((e) => {
          obj[i][e.value] = {};

          console.log(this.selectedIngredients);

          if (
            this.selectedIngredients[i].includes(e.value) &&
            this.menuItemVariation_optionals[i].includes(e.value)
          ) {
            obj[i][e.value] = { value: 0, selected: true, optional: true };
          }

          if (
            this.selectedIngredients[i].includes(e.value) &&
            !this.menuItemVariation_optionals[i].includes(e.value)
          ) {
            obj[i][e.value] = { selected: true };
          }

          pricesIngredients = { ...pricesIngredients, ...obj };
        });
      });

      let priceArray = [];

      this.menuItemVarations.forEach((e, i) => {
        // console.log(e);
        priceArray[e.variation_id] = this.menuItemVarations[i].price;
      });

      var formdata = new FormData();

      if (this.is_base == 1) {
        let empty = {};
        let emptyArray = [];

        formdata.append("price", this.price);
        formdata.append("multiple", emptyArray);
        formdata.append("prices_ingredients", JSON.stringify(empty));
      }
      if (this.is_base == 0) {
        formdata.append("prices", priceArray.toString());
        formdata.append(
          "prices_ingredients",
          JSON.stringify(pricesIngredients)
        );
        formdata.append("multiple", this.selectedVariations);
      }
      if (this.is_base == 2) {
        let obj = { categories: [] };

        this.bundleItems.forEach((item) => {
          // console.log(this.bundleItemsForSelect[item.category_id]);

          obj.categories.push({
            category_id: item.category_id,
            items: this.bundleItemsForSelect[item.category_id],
            max_items_per_category: item.max_items_per_category,
          });
        });

        formdata.append("bundle_items", JSON.stringify(obj));
        formdata.append("bundle_categories", this.menuItem[0].categories);
        formdata.append("is_bundle", true);
        formdata.append("menuItemVariation_is_base", this.is_base);
        formdata.append("menuItemVariation_price", this.price);
        formdata.append("menuItemVariation_prices", null);

        // console.log(obj);
      }

      if (this.menuItemImg != null) {
        formdata.append("menu_item_image", this.menuItemImg);
      }

      formdata.append("id", this.$route.params.id);

      formdata.append("categories", this.menuItem[0].categories);
      formdata.append("name", this.menuItem[0].name);
      formdata.append("order", this.menuItem[0].order);
      formdata.append("description", this.menuItem[0].description);
      formdata.append("is_active", this.menuItem[0].is_active ? "1" : "0");
      formdata.append("is_new", this.menuItem[0].is_new ? "1" : "0");
      // formdata.append("bundle_categories", "null");
      formdata.append("is_base", this.is_base);
      formdata.append("_method", "patch");
      // formdata.append("multiple", this.selectedVariations);
      // formdata.append("bundle_items", "null");
      // formdata.append("prices_ingredients", JSON.stringify(pricesIngredients));

      // for (var pair of formdata.entries()) {
      //   console.log(pair[0] + ", " + pair[1]);
      // }

      for (var pair of formdata.entries()) {
        console.log(pair[0] + ", " + pair[1]);
      }

      try {
        await axios.post(
          "/cms-menu-items/" + `${this.$route.params.id}`,
          formdata
        );

        if (this.getUser.role == "kitchen_staff") {
          this.$router.push("/");
        } else {
          this.$router.push("/menu-items");
        }
      } catch (err) {
        // console.log(err);
      }
    },

    getItemsByCategory(value) {
      const items = this.getMenuItemsBycategory(value);

      return items.map((item) => {
        return { label: item.name, value: item.id };
      });
    },

    filesChange(e) {
      const file = e.target.files[0];

      this.img = URL.createObjectURL(file);

      if (e.target.files.length == 1) {
        this.menuItemImg = file;
      } else {
        delete this.menuItemImg;
      }
    },

    back() {
      this.$router.go(-1);
    },
  },

  watch: {
    is_base() {
      if (this.is_base == 1) {
        this.menuItemVarations.length = 0;
        this.selectedIngredients.length = 0;
        this.menuItemVariation_optionals.length = 0;
        this.selectedVariations.length = 0;
      }
    },
  },

  computed: {
    ...mapActions([
      "fetchMenus",
      "fetchCategories",
      "fetchMenuItems",
      "fetchMenuItemsVariations",
      "fetchMenuItemVariationIngredients",
      "fetchVariations",
      "fetchIngredients",
    ]),
    ...mapGetters([
      "getMenus",
      "getCategories",
      "getMenuItemtById",
      "getMenuItemsVariationById",
      "getMenuItemVariationIngredientsById",
      "getMenuItemVariationIngredients",
      "getVariations",
      "getIngredients",
      "getMenu1",
      "getMenuItemsBycategory",
      "getUser",
    ]),
  },
};
</script>
