import axios from "axios";

const state = {
  deliveryAreas: [],
};

const getters = {
  getDeliveryAreas: (state) => state.deliveryAreas,
  getDeliveryAreaById: (state) => (id) => {
    return state.deliveryAreas.filter((item) => item.id === id);
  },
};

const mutations = {
  SET_DELIVERY_AREAS(state, payload) {
    state.deliveryAreas = payload;
  },

  ADD_DELIVERY_AREA(state, payload) {
    state.deliveryAreas.push(payload);
  },

  REMOVE_DELIVERY_AREA(state, payload) {
    state.deliveryAreas = state.deliveryAreas.filter(
      (area) => area.id !== payload
    );
  },

  UPDATE_DELIVERY_AREA(state, payload) {
    state.deliveryAreas.forEach((area, i) => {
      if (area.id == payload.id) {
        state.deliveryAreas[i] = payload;
      }
    });
  },
};

const actions = {
  async fetchDeliveryAreas({ commit }) {
    const { data } = await axios.get("/delivery-areas");
    commit("SET_DELIVERY_AREAS", data.data);
  },

  async saveDeliveryArea({ commit }, payload) {
    const { data } = await axios.post("/delivery-areas", payload);
    commit("ADD_DELIVERY_AREA", data.data);
  },

  async deleteDeliveryArea({ commit }, id) {
    await axios.delete(`/delivery-areas/${id}`);
    commit("REMOVE_DELIVERY_AREA", id);
  },

  async patchDeliveryArea({ commit }, payload) {
    const { data } = await axios.patch(
      `/delivery-areas/${payload.id}`,
      payload
    );
    commit("UPDATE_DELIVERY_AREA", data.data);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
