import axios from "axios";

const state = {
  pushNotifications: [],
};

const getters = {
  getPushNotifications: (state) => state.pushNotifications,
  getPushNotificationById: (state) => (id) => {
    return state.pushNotifications.filter((item) => item.id === id);
  },
};

const mutations = {
  SET_PUSH_NOTIFICATIONS(state, payload) {
    state.pushNotifications = payload;
  },

  ADD_PUSH_NOTIFICATION(state, payload) {
    state.pushNotifications.push(payload);
  },

  REMOVE_PUSH_NOTIFICATION(state, payload) {
    state.pushNotifications = state.pushNotifications.filter(
      (item) => item.id !== payload
    );
  },

  UPDATE_PUSH_NOTIFICATION(state, payload) {
    state.pushNotifications.forEach((push, i) => {
      if (push.id == payload.id) {
        state.pushNotifications[i] = payload;
      }
    });
  },
};

const actions = {
  async fetchPushNotifications({ commit }) {
    const { data } = await axios.get("/push-notifications");
    commit("SET_PUSH_NOTIFICATIONS", data.data);
  },

  async savePushNotification({ commit }, payload) {
    const { data } = await axios.post("/push-notifications", payload);
    commit("ADD_PUSH_NOTIFICATION", data.data);
  },

  async deletePushNotification({ commit }, id) {
    await axios.delete(`push-notifications/${id}`);
    commit("REMOVE_PUSH_NOTIFICATION", id);
  },

  async patchPushNotification({ commit }, payload) {
    const { data } = await axios.patch(
      `/push-notifications/${payload.id}`,
      payload
    );
    commit("UPDATE_PUSH_NOTIFICATION", data.data);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
