<template>
  <!-- Container -->
  <div class="w-11/12 mx-auto">
    <h2 class="big-title mb-14">
      Promotions
    </h2>
    <!-- Form container -->
    <div class="form-wrapper 2xl:w-9/12 mx-auto">
      <h3 class="form-title ">
        Create coupon
      </h3>

      <!-- Form -->
      <form @submit.prevent="onSubmit" class="text-tableSubColor font-bold">
        <div class="md:flex">
          <!-- Code -->
          <div class="w-full mb-4 md:mr-8">
            <label
              >Code
              <input
                type="text"
                name="code"
                autocomplete="off"
                v-model="model.code"
                class="input"
                :class="{
                  'is-invalid': v$.model.code.$error,
                }"
              />
            </label>
            <span
              v-if="v$.model.code.$error"
              class="text-sm font-normal text-invalidColor"
              >{{ v$.model.code.$errors[0].$message }}</span
            >
          </div>
          <!-- Discount -->
          <div class="w-full mb-4">
            <label
              >Discount
              <input
                type="number"
                name="discount"
                autocomplete="off"
                v-model="model.discount"
                class="input"
                :class="{
                  'is-invalid': v$.model.discount.$error,
                }"
              />
            </label>
            <span
              v-if="v$.model.discount.$error"
              class="text-sm font-normal text-invalidColor"
              >{{ v$.model.discount.$errors[0].$message }}</span
            >
          </div>
        </div>

        <div class="md:flex">
          <!-- Usage Limit -->
          <div class="w-full mb-4 md:mr-8">
            <label
              >Usage Limit
              <input
                type="number"
                name="usage_limit"
                v-model="model.usage_limit"
                class="input"
                :class="{
                  'is-invalid': v$.model.usage_limit.$error,
                }"
              />
            </label>
            <span
              v-if="v$.model.usage_limit.$error"
              class="text-sm font-normal text-invalidColor"
              >{{ v$.model.usage_limit.$errors[0].$message }}</span
            >
          </div>
          <!-- Percent Switch -->
          <div class="md:w-full md:flex md:ml-4">
            <div class="flex flex-col md:ml-1 md:mr-8">
              <label for="discount_is_percent">Discount is percent</label>
              <label class="switch mb-4 md:mb-0 md:mt-2">
                <input
                  type="checkbox"
                  v-model="model.discount_is_percent"
                  :false-value="parseInt(0)"
                  :true-value="parseInt(1)"
                />
                <span class="slider round"></span>
              </label>
            </div>
            <!-- Amount Switch -->
            <div class="flex flex-col">
              <label for="discount_is_amount">Discount is amount</label>
              <label class="switch mb-4 md:mb-0 md:mt-2">
                <input
                  type="checkbox"
                  v-model="model.discount_is_amount"
                  :false-value="parseInt(0)"
                  :true-value="parseInt(1)"
                />
                <span class="slider round"></span>
              </label>
            </div>
          </div>
        </div>

        <div class="md:flex">
          <!-- Usage per Customer -->
          <div class="w-full mb-4 md:mr-8">
            <label
              >Usage per Customer
              <input
                type="number"
                name="usage_per_customer"
                v-model="model.usage_per_customer"
                class="input"
                :class="{
                  'is-invalid': v$.model.usage_per_customer.$error,
                }"
              />
            </label>
            <span
              v-if="v$.model.usage_per_customer.$error"
              class="text-sm font-normal text-invalidColor"
              >{{ v$.model.usage_per_customer.$errors[0].$message }}</span
            >
          </div>
          <!-- Expires At -->
          <div class="w-full mb-4">
            <label
              >Expires At
              <input
                type="date"
                name="expires_at"
                v-model="model.expires_at"
                class="input"
                :class="{
                  'is-invalid': v$.model.expires_at.$error,
                }"
              />
            </label>
            <span
              v-if="v$.model.expires_at.$error"
              class="text-sm font-normal text-invalidColor"
              >{{ v$.model.expires_at.$errors[0].$message }}</span
            >
          </div>
        </div>

        <div class="md:flex mb-4">
          <!-- Multiselect Client -->
          <div class="w-full md:mr-8">
            <label for="optional_ingredient">Select clients</label>
            <Multiselect
              :classes="{
                container: 'multiselectContainer',
                tag: 'bg-black text-white multiselectTag',
                tagsSearch: 'multiselectTagsSearch',
              }"
              v-model="clientsArray"
              mode="tags"
              :closeOnSelect="false"
              :searchable="true"
              :options="this.getClients"
            />
          </div>
          <div
            class="w-full flex items-end justify-center flex-col mt-4 md:mt-0"
          >
            <label for="published">Published</label>
            <label class="switch md:mt-2">
              <input
                type="checkbox"
                v-model="model.is_active"
                :false-value="parseInt(0)"
                :true-value="parseInt(1)"
              />
              <span class="slider round"></span>
            </label>
          </div>
        </div>

        <!-- Buttons -->
        <div class="w-full flex justify-end pt-4">
          <Button
            type="button"
            @click.prevent="back"
            btnTitle="Cancel"
            class="mr-4 bg-goldBeige text-black"
          />
          <Button type="submit" btnTitle="Save" class="bg-greenBtn" />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Button from "../../../components/Button.vue";
import Multiselect from "@vueform/multiselect";
import { mapGetters, mapActions } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  name: "CouponsCreate",
  components: {
    Button,
    Multiselect,
  },
  data() {
    return {
      v$: useVuelidate(),
      clientsArray: [],
      model: {
        code: "",
        discount: "",
        discount_is_percent: 0,
        discount_is_amount: 0,
        usage_limit: "",
        usage_per_customer: "",
        expires_at: "",
        is_active: 0,
        clients: null,
      },
    };
  },

  /* --- Validation --- */
  validations() {
    return {
      model: {
        code: { required },
        discount: { required },
        usage_limit: { required },
        usage_per_customer: { required },
        expires_at: { required },
      },
    };
  },

  async beforeMount() {
    if (this.getUsers.length == 0) {
      this.fetchUsers;
    }
  },

  computed: {
    ...mapActions(["fetchUsers"]),
    ...mapGetters(["getUsers"]),

    getClients() {
      let role = this.getUsers.filter((user) => {
        if (user.role.toLowerCase() === "client") {
          return user;
        }
      });
      return role.map((client) => {
        return client.email;
      });
    },
  },

  methods: {
    async onSubmit() {
      this.v$.$validate();
      if (!this.v$.$error) {
        const convertArray = this.clientsArray.join();
        this.model.clients = convertArray;
        try {
          await this.$store.dispatch("saveCoupon", this.model);
          await this.$store.dispatch("fetchCoupons", this.model);
          //redirect
          this.$router.push("/coupons");
        } catch (e) {
          alert(Object.values(e.response.data.messages).join(" "));
        }
      }
    },

    back() {
      this.$router.go(-1);
    },
  },
};
</script>
