// import axios from "axios";

const state = {
  tables: [
    { id: 1, table_name: "Ching", table_number: 1 },
    { id: 2, table_name: "Ling", table_number: 2 },
  ],
};

const getters = {
  getTables: (state) => state.tables,
  getTableById: (state) => (id) => {
    return state.tables.filter((table) => table.id === id);
  },
};

const mutations = {
  SET_TABLES(state, payload) {
    state.tables = payload;
  },

  ADD_TABLE(state, payload) {
    state.tables.push(payload);
  },

  REMOVE_TABLE(state, payload) {
    state.tables = state.tables.filter((table) => table.id !== payload);
  },

  UPDATE_TABLE(state, payload) {
    state.tables.forEach((table, i) => {
      if (table.id == payload.id) {
        state.tables[i] = payload;
      }
    });
  },
};

const actions = {
  /*   async fetchTables({ commit }) {
    const { data } = await axios.get("/categories");
    commit("SET_TABLES", data.data);
  },

  async saveTable({ commit }, payload) {
    const { data } = await axios.post("/categories", payload);
    commit("ADD_TABLE", data.data);
  },

  async deleteTable({ commit }, id) {
    await axios.delete(`/categories/${id}`);
    commit("REMOVE_TABLE", id);
  },

  async patchTable({ commit }, payload) {
    const { data } = await axios.patch(`/categories/${payload.id}`, payload);
    commit("UPDATE_TABLE", data.data);
  }, */
};

export default {
  state,
  getters,
  mutations,
  actions,
};
