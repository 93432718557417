<template>
  <!-- Container -->
  <div class="w-11/12 mx-auto">
    <h2 class="big-title mb-14">
      Restaurant
    </h2>
    <!-- Form container -->
    <div class="form-wrapper 2xl:w-9/12 mx-auto">
      <h3 class="form-title ">
        Create business hour
      </h3>

      <!-- Form -->
      <form @submit.prevent="onSubmit" class="text-tableSubColor font-bold">
        <!-- Select Day -->
        <div class="w-full mb-4">
          <label
            >Select Day
            <select
              name="day"
              v-model="model.day"
              class="input"
              :class="{
                'is-invalid': v$.model.day.$error,
              }"
            >
              <option v-for="(item, i) in getDays" :key="i" :value="i">{{
                item
              }}</option>
            </select>
          </label>
          <span
            v-if="v$.model.day.$error"
            class="text-sm font-normal text-invalidColor"
            >{{ v$.model.day.$errors[0].$message }}</span
          >
        </div>

        <div class="md:flex">
          <!-- Open Time -->
          <div class="w-full mb-4 md:mr-8">
            <label>
              Open Time
              <input
                type="time"
                name="open_time"
                autocomplete="off"
                v-model="model.open_time"
                class="input"
                :class="{
                  'is-invalid': v$.model.open_time.$error,
                }"
              />
            </label>
            <span
              v-if="v$.model.open_time.$error"
              class="text-sm font-normal text-invalidColor"
              >{{ v$.model.open_time.$errors[0].$message }}</span
            >
          </div>
          <!-- Close Time -->
          <div class="w-full mb-4">
            <label
              >Close Time
              <input
                type="time"
                name="close_time"
                autocomplete="off"
                v-model="model.close_time"
                class="input"
                :class="{
                  'is-invalid': v$.model.close_time.$error,
                }"
              />
            </label>
            <span
              v-if="v$.model.close_time.$error"
              class="text-sm font-normal text-invalidColor"
              >{{ v$.model.close_time.$errors[0].$message }}</span
            >
          </div>
        </div>

        <div class="md:flex">
          <!-- Valid from -->
          <div class="w-full mb-4 md:mr-8">
            <label
              >Valid from
              <input
                type="date"
                name="valid_from"
                autocomplete="off"
                v-model="model.valid_from"
                class="input"
              />
            </label>
          </div>
          <!-- Valid to -->
          <div class="w-full mb-4">
            <label
              >Valid to
              <input
                type="date"
                name="valid_through"
                autocomplete="off"
                v-model="model.valid_through"
                class="input"
              />
            </label>
          </div>
        </div>

        <div class="md:flex">
          <!-- Default -->
          <div class="md:w-full md:flex">
            <div class="flex flex-col md:ml-1 md:mr-8">
              <label>Default</label>
              <label class="switch mb-4 md:mb-0 md:mt-2">
                <input
                  type="checkbox"
                  v-model="model.is_default"
                  :false-value="parseInt(0)"
                  :true-value="parseInt(1)"
                />
                <span class="slider round"></span>
              </label>
            </div>
            <!-- Is Store Closed? -->
            <div class="flex flex-col">
              <label>Is Store Closed?</label>
              <label class="switch mb-4 md:mb-0 md:mt-2">
                <input
                  type="checkbox"
                  v-model="model.is_closed"
                  :false-value="parseInt(0)"
                  :true-value="parseInt(1)"
                />
                <span class="slider round"></span>
              </label>
            </div>
          </div>
        </div>

        <!-- Buttons -->
        <div class="w-full flex justify-end pt-4">
          <Button
            type="button"
            @click.prevent="back"
            btnTitle="Cancel"
            class="mr-4 bg-goldBeige text-black"
          />
          <Button type="submit" btnTitle="Save" class="bg-greenBtn" />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Button from "../../../components/Button.vue";
import { mapGetters } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  name: "BusinessHoursCreate",
  components: {
    Button,
  },
  data() {
    return {
      v$: useVuelidate(),
      model: {
        day: null,
        open_time: "",
        close_time: "",
        is_closed: 0,
        is_default: 0,
      },
    };
  },

  /* --- Validation --- */
  validations() {
    return {
      model: {
        day: { required },
        open_time: { required },
        close_time: { required },
      },
    };
  },

  computed: {
    ...mapGetters(["getDays"]),
  },

  methods: {
    async onSubmit() {
      this.v$.$validate();
      if (!this.v$.$error) {
        try {
          await this.$store.dispatch("saveBusinessHour", this.model);
          await this.$store.dispatch("fetchBusinessHours", this.model);

          //redirect
          this.$router.push("/business-hours");
        } catch (e) {
          alert(Object.values(e.response.data.messages).join(" "));
        }
      }
    },

    back() {
      this.$router.go(-1);
    },
  },
};
</script>

<style>
.is-invalid {
  border-color: rgb(253, 165, 49);
}
</style>
