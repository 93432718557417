// import store from "../../store/index.js";
// import dashboard from "../../store/modules/dashboard.js";

// console.log(dashboard.state);
// console.log(store.state.dashboard);

const lineChartData = {
  type: "line",
  data: {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Value",
        data: [
          0,
          782,
          1000,
          22500,
          5000,
          10000,
          33521,
          20000,
          1293,
          3029,
          5938,
          4563,
        ],
        backgroundColor: "rgba(18, 152, 144, 0.2)",
        borderColor: "rgba(18, 152, 144, 0.5)",
        borderWidth: 3,
      },
    ],
  },
  options: {
    maintainAspectRatio: false,
    responsive: true,
    lineTension: 1,
    tooltips: {
      displayColors: false,
      backgroundColor: "rgba(18, 152, 144, 0.8)",
      titleAlign: "center",
      bodyFontSize: 14,
      caretPadding: 10,
      padding: 10,

      callbacks: {
        label: function(item) {
          let value = item.yLabel;
          value = value.toLocaleString();
          let label = value + " Sek";
          return label;
        },
      },
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            beginAtZero: true,
            padding: 15,
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            padding: 25,
          },
        },
      ],
    },
    legend: {
      display: false,
    },
  },
};

export default lineChartData;
