import axios from "axios";

const state = {
  deliveryAreaCoordinates: [],
};

const getters = {
  getDeliveryAreaCoordinates: (state) => state.deliveryAreaCoordinates,
  getDeliveryAreaCoordinatesById: (state) => (id) => {
    return state.deliveryAreaCoordinates.filter(
      (item) => item.delivery_area_id === id
    );
  },
};

const mutations = {
  SET_DELIVERY_AREA_COORDINATES(state, payload) {
    state.deliveryAreaCoordinates = payload;
  },

  ADD_DELIVERY_AREA_COORDINATE(state, payload) {
    state.deliveryAreaCoordinates.push(payload);
  },

  REMOVE_DELIVERY_AREA_COORDINATE(state, payload) {
    state.deliveryAreaCoordinates = state.deliveryAreaCoordinates.filter(
      (Coordinate) => Coordinate.id !== payload
    );
  },

  UPDATE_DELIVERY_AREA_COORDINATE(state, payload) {
    state.deliveryAreaCoordinates.forEach((coordinate, i) => {
      if (coordinate.id == payload.id) {
        state.deliveryAreaCoordinates[i] = payload;
      }
    });
  },
};

const actions = {
  async fetchDeliveryAreaCoordinates({ commit }) {
    const { data } = await axios.get("/delivery-area-coordinates");
    commit("SET_DELIVERY_AREA_COORDINATES", data.data);
  },

  async saveDeliveryAreaCoordinate({ commit }, payload) {
    const { data } = await axios.post("/delivery-area-coordinates", payload);
    commit("ADD_DELIVERY_AREA_COORDINATE", data.data);
  },

  async deleteDeliveryAreaCoordinate({ commit }, id) {
    await axios.delete(`/delivery-area-coordinates/${id}`);
    commit("REMOVE_DELIVERY_AREA_COORDINATE", id);
  },

  async patchDeliveryAreaCoordinate({ commit }, payload) {
    const { data } = await axios.patch(
      `/delivery-area-coordinates/${payload.id}`,
      payload
    );
    commit("UPDATE_DELIVERY_AREA_COORDINATE", data.data);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
