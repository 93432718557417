<template>
  <!-- Container -->
  <div class="w-11/12 mx-auto">
    <h2 class="big-title mb-14">
      Menu
    </h2>
    <!-- Form container -->
    <div class="form-wrapper 2xl:w-9/12 mx-auto">
      <h3 class="form-title">
        Create variation
      </h3>

      <!-- Form -->
      <form @submit.prevent="onSubmit" class="text-tableSubColor font-bold">
        <div class="md:flex">
          <!-- Name -->
          <div class="w-full mb-4 md:mr-8">
            <label
              >Name
              <input
                type="text"
                name="name"
                autocomplete="off"
                v-model="model.name"
                class="input"
                :class="{
                  'is-invalid': v$.model.name.$error,
                }"
              />
            </label>
            <span
              v-if="v$.model.name.$error"
              class="text-sm font-normal text-invalidColor"
              >{{ v$.model.name.$errors[0].$message }}</span
            >
          </div>
          <!-- Type -->
          <div class="w-full mb-4">
            <label
              >Type
              <input
                type="text"
                name="type"
                autocomplete="off"
                v-model="model.type"
                class="input"
                :class="{
                  'is-invalid': v$.model.type.$error,
                }"
              />
            </label>
            <span
              v-if="v$.model.type.$error"
              class="text-sm font-normal text-invalidColor"
              >{{ v$.model.type.$errors[0].$message }}</span
            >
          </div>
        </div>

        <!-- Description -->
        <div class="w-full mb-4">
          <label
            >Description
            <textarea
              rows="4"
              cols="30"
              name="description"
              autocomplete="off"
              v-model="model.description"
              class="input"
            ></textarea>
          </label>
        </div>

        <!-- Buttons -->
        <div class="w-full flex justify-end pt-4">
          <Button
            type="button"
            @click.prevent="back"
            btnTitle="Cancel"
            class="mr-4 bg-goldBeige text-black"
          />
          <Button type="submit" btnTitle="Save" class="bg-greenBtn" />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Button from "../../../components/Button.vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  name: "VariationsCreate",
  components: {
    Button,
  },
  data() {
    return {
      v$: useVuelidate(),
      model: {
        name: "",
        type: "",
        description: "",
      },
    };
  },

  /* --- Validation --- */
  validations() {
    return {
      model: {
        name: { required },
        type: { required },
      },
    };
  },

  methods: {
    async onSubmit() {
      this.v$.$validate();
      if (!this.v$.$error) {
        try {
          await this.$store.dispatch("saveVariation", this.model);
          //redirect
          this.$router.push("/variations");
        } catch (e) {
          alert(Object.values(e.response.data.messages).join(" "));
        }
      }
    },

    back() {
      this.$router.go(-1);
    },
  },
};
</script>
