import { createStore } from "vuex";
import users from "./modules/users";
import categories from "../store/modules/categories";
import ingredients from "../store/modules/ingredients";
import menus from "../store/modules/menus";
import variations from "./modules/variations";
import menuItems from "./modules/menuItems";
import coupons from "./modules/coupons";
import pushNotifications from "./modules/pushNotifications";
import points from "./modules/points";
import generalSettings from "./modules/generalSettings";
import restaurants from "./modules/restaurants";
import businessHours from "./modules/businessHours";
import deliveryAreas from "./modules/deliveryAreas";
import auth from "./modules/auth";
import createPersistedState from "vuex-persistedstate";
import deliveryAreaCoordinates from "./modules/deliveryAreaCoordinates";
import orders from "./modules/orders";
import menuItemVarations from "./modules/menuItemVarations";
import menuItemVarationIngredients from "./modules/menuItemVarationIngredients";
import tablePlacement from "./modules/tablePlacement";
import dashboard from "./modules/dashboard";

export default createStore({
  modules: {
    categories,
    ingredients,
    menus,
    variations,
    menuItems,
    coupons,
    pushNotifications,
    points,
    generalSettings,
    users,
    restaurants,
    businessHours,
    deliveryAreas,
    auth,
    deliveryAreaCoordinates,
    orders,
    menuItemVarations,
    menuItemVarationIngredients,
    tablePlacement,
    dashboard,
  },
  plugins: [
    createPersistedState({
      paths: ["auth", "profile"],
    }),
  ],
});
