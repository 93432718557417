<template>
  <!-- Container -->
  <div class="w-11/12 mx-auto" v-if="this.getRestaurants.length > 0">
    <h2 class="big-title">
      Restaurant
    </h2>
    <div class="w-full flex justify-end">
      <router-link :to="{ name: 'RestaurantsCreate' }">
        <Button btnTitle="New" icon="plus" class="bg-greenBtn"
      /></router-link>
    </div>
    <!-- Container table -->
    <div class="form-container">
      <!-- Gradient Background -->
      <div class="gradient-background"></div>
      <!-- Title and search box -->
      <div
        class="sm:flex sm:justify-between sm:items-center mb-4 sm:mr-8 relative z-10"
      >
        <h3 class="form-title ">Restaurants</h3>

        <form class="flex">
          <img
            src="../../../assets/images/search.svg"
            alt=""
            class="w-4 mb-1 transform translate-x-4"
          />
          <input
            class="search"
            type="search"
            name="search"
            placeholder="Search"
            autocomplete="off"
            v-model="search"
          />
        </form>
      </div>
      <!-- Table -->
      <div
        class=" overflow-x-auto md:overflow-hidden text-sm xl:text-base relative z-10"
      >
        <table id="my-table" class="w-full">
          <tr class="text-left text-tableTitleColor">
            <th @click="sort('name')" class="pb-4 cursor-pointer">Name</th>
            <th @click="sort('phone')" class="pb-4 cursor-pointer">Phone</th>
            <th @click="sort('street')" class="pb-4 cursor-pointer">Street</th>
            <th @click="sort('city')" class="pb-4 cursor-pointer">City</th>
            <th @click="sort('postcode')" class="pb-4 cursor-pointer">
              Postcode
            </th>
            <th @click="sort('is_active')" class="pb-4 cursor-pointer">
              Active
            </th>
            <th @click="sort('created_at')" class="pb-4 cursor-pointer">
              Created at
            </th>
          </tr>
          <tr
            v-for="(restaurant, i) in filteredList"
            :key="restaurant.id"
            v-show="setPaginate(i)"
            class="border-b border-tableBorderColor text-tableSubColor"
          >
            <td class="py-4">{{ restaurant.name }}</td>
            <td>{{ restaurant.phone }}</td>
            <td>{{ restaurant.street }}</td>
            <td>{{ restaurant.city }}</td>
            <td>{{ restaurant.postcode }}</td>
            <td>{{ restaurant.is_active }}</td>
            <td>{{ restaurant.created_at }}</td>
            <td>
              <router-link
                :to="{ name: 'RestaurantsEdit', params: { id: restaurant.id } }"
              >
                <font-awesome-icon
                  icon="user-edit"
                  title="Edit"
                  class="text-sm xl:text-base md:ml-2 xl:ml-0 cursor-pointer"
              /></router-link>
              <font-awesome-icon
                icon="trash"
                title="Delete"
                @click="togglePopUp(restaurant.id)"
                class="text-sm xl:text-base md:ml-3 xl:ml-4 cursor-pointer"
              />
            </td>
          </tr>
        </table>
      </div>
      <!-- Pagination -->
      <div class="flex items-center justify-between pt-8 text-tableSubColor">
        <div>
          <p class="hidden md:block text-sm xl:text-base">
             Showing
            <!-- {{ current * paginate }} items of -->
            {{ itemsPerPage }} items of
            {{ filteredList.length }}
          </p>
        </div>
        <nav class="flex">
          <button class="pr-4 " @click="prev">
            <font-awesome-icon
              icon="chevron-left"
              class="cursor-pointer text-xs xl:text-sm"
            />
          </button>
          <div v-for="pageIndex in totalPage" :key="pageIndex">
            <button
              class="text-sm xl:text-base w-6 h-6 xl:w-8 xl:h-8 m-1 text-center rounded-full hover:bg-sideDashboard"
              v-if="
                Math.abs(pageIndex - this.current) < 3 ||
                  pageIndex == this.totalPage ||
                  pageIndex == 1
              "
              @click="updateCurrent(pageIndex)"
              :class="{
                'bg-goldBeige shadow-xl text-white border-goldBeige hover:bg-goldBeige':
                  pageIndex == current,
                last:
                  pageIndex == totalPage &&
                  Math.abs(pageIndex - this.current) > 3,
                first: pageIndex == 1 && Math.abs(pageIndex - this.current) > 3,
              }"
            >
              {{ pageIndex }}
            </button>
          </div>
          <button class="pl-4" @click="next">
            <font-awesome-icon
              icon="chevron-right"
              class="cursor-pointer text-xs md:text-sm"
            />
          </button>
        </nav>
      </div>
    </div>
    <!-- Pop-up  -->
    <transition name="slide-fade">
      <PopUp v-show="showPopUp" @remove="remove" @cancel="cancel" />
    </transition>
  </div>
</template>

<script>
import Button from "../../../components/Button.vue";
import PopUp from "../../../components/PopUp.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Restaurants",
  components: {
    Button,
    PopUp,
  },
  data() {
    return {
      search: "",
      current: 1,
      paginate: 5,
      showPopUp: false,
      selectedElement: null,
      currentSort: "name",
      currentSortDir: "asc",
    };
  },

  async beforeMount() {
    if (this.getRestaurants.length == 0) {
      this.fetchRestaurants;
    }
  },

  computed: {
    ...mapActions(["fetchRestaurants"]),
    ...mapGetters(["getRestaurants"]),

    filteredList() {
      const restaurants = this.getRestaurants.slice().reverse();
      return restaurants
        .filter((restaurant) => {
          return restaurant.name
            .toLowerCase()
            .includes(this.search.toLowerCase());
        })
        .sort((a, b) => {
          let modifier = 1;
          if (this.currentSortDir === "desc") modifier = -1;
          if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
          if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
          return 0;
        });
    },

    totalPage() {
      return Math.ceil(this.filteredList.length / this.paginate);
    },
    itemsPerPage() {
      let items = this.current * this.paginate
      return items <= this.filteredList.length ? items : this.filteredList.length
    }, 
  },

  methods: {
    sort(columnTitle) {
      //if columnTitle == current sort, reverse
      if (columnTitle === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = columnTitle;
    },

    /* ------- Pop-up Remove User ------- */
    togglePopUp(id) {
      this.selectedElement = id;
      this.showPopUp = true;
    },

    remove() {
      this.showPopUp = false;
      this.$store.dispatch("deleteRestaurant", this.selectedElement);
    },

    cancel() {
      this.showPopUp = false;
    },

    /* ------- Pagination ------- */
    setPaginate(i) {
      if (this.current == 1) {
        return i < this.paginate;
      } else {
        return (
          i >= this.paginate * (this.current - 1) &&
          i < this.current * this.paginate
        );
      }
    },

    updateCurrent(i) {
      this.current = i;
    },

    next() {
      if (this.current !== this.totalPage) {
        this.current++;
      }
    },
    prev() {
      if (this.current !== 1) {
        this.current--;
      }
    },
  },
};
</script>
