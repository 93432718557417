<template>
  <!-- Container -->
  <div class="w-11/12 mx-auto">
    <h2 class="big-title mb-12">
      Profile
    </h2>
    <!-- Form container -->
    <div class="form-wrapper form-wrapper 2xl:w-9/12 mx-auto">
      <h3 class="form-title">
        Edit user
      </h3>

      <!-- Form -->
      <form @submit.prevent="onSubmit" class="text-tableSubColor font-bold">
        <div class="flex flex-col items-center md:flex-row mb-4 md:ml-4">
          <!-- Profil image -->
          <div class="md:w-80 md:h-full relative">
            <img
              class="w-56 h-56 rounded-full relative"
              :src="img"
              alt="Avatar"
            />
            <div class="file-input mt-4">
              <input type="file" id="file" class="file" @change="filesChange" />
              <label
                for="file"
                class="bg-white text-subTitle border border-tableBorderColor px-3 py-2 rounded-full shadow-md text-sm font-bold cursor-pointer absolute bottom-4 right-6 md:right-28"
                ><font-awesome-icon icon="plus"
              /></label>
            </div>
          </div>
          <div class="mb-4 md:mb-0 flex flex-col items-center md:items-start">
            <h3 class="text-5xl mb-4 text-goldBeige">Welcome</h3>
            <p class="md:pl-2 text-xl">
              {{ this.model.first_name }} {{ this.model.last_name }}
            </p>
            <p class="md:pl-2 text-md">{{ this.model.email }}</p>
          </div>
        </div>

        <div class="w-full">
          <div class="md:flex">
            <!-- First name -->
            <div class="w-full mb-4">
              <label
                >First name
                <input
                  type="text"
                  name="first_name"
                  autocomplete="off"
                  v-model="model.first_name"
                  class="input"
                  :class="{
                    'is-invalid': v$.model.first_name.$error,
                  }"
                />
                <span
                  v-if="v$.model.first_name.$error"
                  class="text-sm font-normal text-invalidColor"
                  >{{ v$.model.first_name.$errors[0].$message }}</span
                >
              </label>
            </div>
            <!-- Last name -->
            <div class="w-full mb-4 md:ml-8">
              <label
                >Last name
                <input
                  type="text"
                  name="last_name"
                  autocomplete="off"
                  v-model="model.last_name"
                  class="input"
                  :class="{
                    'is-invalid': v$.model.last_name.$error,
                  }"
                />
                <span
                  v-if="v$.model.last_name.$error"
                  class="text-sm font-normal text-invalidColor"
                  >{{ v$.model.last_name.$errors[0].$message }}</span
                >
              </label>
            </div>
          </div>

          <div class="md:flex">
            <!-- Email -->
            <div class="w-full mb-4">
              <label
                >Email
                <input
                  type="text"
                  name="email"
                  autocomplete="off"
                  v-model="model.email"
                  class="input"
                  :class="{
                    'is-invalid': v$.model.email.$error,
                  }"
                />
                <span
                  v-if="v$.model.email.$error"
                  class="text-sm font-normal text-invalidColor"
                  >{{ v$.model.email.$errors[0].$message }}</span
                >
              </label>
            </div>
            <!-- Username -->
            <div class="w-full mb-4 md:ml-8">
              <label
                >Username
                <input
                  type="text"
                  name="username"
                  autocomplete="off"
                  v-model="model.username"
                  class="input"
                  :class="{
                    'is-invalid': v$.model.username.$error,
                  }"
                />
              </label>
              <span
                v-if="v$.model.username.$error"
                class="text-sm font-normal text-invalidColor"
                >{{ v$.model.username.$errors[0].$message }}</span
              >
            </div>
          </div>

          <!-- Phone number -->
          <div class="w-full md:w-6/12 mb-4 md:pr-4">
            <label
              >Mobil number
              <input
                type="text"
                name="mobile_number"
                autocomplete="off"
                v-model="model.mobile_number"
                class="input"
                :class="{
                  'is-invalid': v$.model.mobile_number.$error,
                }"
              />
            </label>
            <span
              v-if="v$.model.mobile_number.$error"
              class="text-sm font-normal text-invalidColor"
              >{{ v$.model.mobile_number.$errors[0].$message }}</span
            >
          </div>

          <!-- Buttons -->
          <div class="w-full flex justify-end pt-4">
            <Button
              type="button"
              @click.prevent="back"
              btnTitle="Cancel"
              class="mr-4 bg-goldBeige text-black"
            />
            <Button type="submit" btnTitle="Save" class="bg-greenBtn" />
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Button from "../components/Button.vue";
import { mapActions, mapGetters } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";

export default {
  name: "Profile",
  components: {
    Button,
  },
  data() {
    return {
      v$: useVuelidate(),
      img: null,
      model: {
        first_name: "",
        last_name: "",
        email: "",
        username: "",
        mobile_number: "",
        avatar: null,
      },
    };
  },

  /* --- Validation --- */
  validations() {
    return {
      model: {
        first_name: { required },
        last_name: { required },
        email: { required, email },
        username: { required },
        mobile_number: { required },
      },
    };
  },

  async beforeMount() {
    await this.fetchMe;

    this.currentMe();

    this.fetchRestaurants;
  },

  computed: {
    ...mapGetters(["getMe"]),
    ...mapActions(["fetchMe", "fetchRestaurants"]),
  },

  methods: {
    /* Get the inlogged user */

    currentMe() {
      console.log(this.getMe);
      let me = this.getMe;

      console.log(me.email);

      this.model = {
        id: me.id,
        restaurant_id: me.restaurant_id,
        first_name: me.first_name,
        last_name: me.last_name,
        email: me.email,
        username: me.username,
        current_avatar: me.avatar,
        mobile_number: me.mobile_number,
        role: me.role,
      };

      this.img = me.avatar;
    },
    /*     getMe() {
      let users = this.getUsers;
      let user = users.filter((user) => {
        if (user.full_name === this.getUser.full_name) {
          return user;
        }
      });
      let currentUser = user[0];
      this.model = currentUser;
    }, */

    /* filesChange(files) {
      this.inputs.file = files;
      if (this.inputs.file.length == 1) {
        this.model.avatar = this.inputs.file[0];
      } else {
        delete this.model.avatar;
      }
    }, */

    filesChange(e) {
      const file = e.target.files[0];

      // console.log(e.target.files);

      this.img = URL.createObjectURL(file);

      if (e.target.files.length == 1) {
        this.model.avatar = file;
      } else {
        delete this.model.avatar;
      }
    },
    imageLoaded(event) {
      this.model.avatar = event.target.result;
    },

    async onSubmit() {
      this.v$.$validate();
      if (!this.v$.$error) {
        let formdata = new FormData();
        for (var key in this.model) {
          if (key == "restaurant_id") {
            if (
              this.model.role == "administrator" ||
              this.model.role == "client"
            ) {
              formdata.append(key, 0);
            } else {
              formdata.append(key, this.model[key]);
            }
          } else {
            formdata.append(key, this.model[key]);
            formdata.append("_method", "patch");
          }
        }

        try {
          await this.$store.dispatch("patchUser", formdata);
          // await this.$store.dispatch("fetchUsers", this.model);
          await this.$store.dispatch("fetchMe", this.fetchMe);
          this.$store.dispatch("getMe");
          //redirect
          this.$router.push("/");
        } catch (e) {
          alert(Object.values(e.response.data.messages).join(" "));
        }
      }

      // console.log(this.model);

      // this.v$.$validate();
      // if (!this.v$.$error) {
      //   try {
      //     await this.$store.dispatch("patchUser", this.model);
      //     await this.$store.dispatch("fetchUsers", this.model);
      //     //redirect
      //     this.$router.push("/");
      //   } catch (e) {
      //     alert(Object.values(e.response.data.messages).join(" "));
      //   }
      // }
    },

    back() {
      this.$router.go(-1);
    },
  },
};
</script>

<style>
.file {
  opacity: 0;
  width: 0.1px;
  height: 0.1px;
  position: absolute;
}
</style>
