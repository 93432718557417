<template>
  <div
    class="w-full xl:w-96 h-32 mx-auto mb-8 px-4 rounded-lg shadow-xl flex items-center justify-between bg-white"
  >
    <font-awesome-icon :icon="icon" class="text-lightGrey text-xl ml-2" />

    <div class="w-full">
      <h3 class="text-center text-xl font-bold text-lightGrey">
        {{ title }}
      </h3>
      <p class="text-center font-bold text-blueBlack text-2xl">
        <span class="text-lg">{{ valuta }}</span>
        {{ value }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Card",
  props: {
    title: String,
    valuta: String,
    value: String,
    icon: String,
  },
  methods: {},
};
</script>
