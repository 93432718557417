<template>
  <div>
    <div v-for="(time, day) in populatedTimes" :key="day" class="grid grid-cols-4 gap-2 relative">
      <div class="my-6">
        <label>
          <input type="checkbox" v-model="time.checked" @change="updateTimes"> {{ days[day] }}
        </label>
      </div>
      <div class="w-full mb-5 relative">
        <label>Valid From</label>
        <input type="time" v-model="time.startTime" @input="updateTimes" @blur="validateTime(time)"
          :disabled="!time.checked" class="border border-gray-300 rounded px-2 py-1 w-full" :class="{
      'is-invalid': time.checked && !time.startTime
    }">
        <span v-if="time.checked && !time.startTime"
          class="absolute top-full left-0 text-sm font-normal text-invalidColor">Value is required</span>
      </div>
      <div class="w-full mb-5 relative">
        <label>Valid To</label>
        <input type="time" v-model="time.endTime" @input="updateTimes" @blur="validateTime(time)"
          :disabled="!time.checked" class="border border-gray-300 rounded px-2 py-1 w-full" :class="{
      'is-invalid': time.checked && !time.endTime
    }">
        <span v-if="time.checked && !time.endTime"
          class="absolute top-full left-0 text-sm font-normal text-invalidColor">Value is required</span>
      </div>
      <template v-if="day === '1'">
        <div class="flex items-center justify-center">
          <Button @click.prevent="copyTimes" btnTitle="Copy" class="bg-greenBtn" icon="copy" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Button from "../components/Button.vue";


export default {
  props: {
    times: {
      type: Object,
      default: () => ({})
    }
  },

  components: {
    Button
  },

  data() {
    return {
      days: {
        1: 'Monday',
        2: 'Tuesday',
        3: 'Wednesday',
        4: 'Thursday',
        5: 'Friday',
        6: 'Saturday',
        7: 'Sunday'
      },
      isValid: true
    };
  },
  computed: {
    populatedTimes() {
      const defaultTime = { checked: false, startTime: '00:00', endTime: '23:59' };
      const populated = {};
      for (let day = 1; day <= 7; day++) {
        populated[day] = { ...defaultTime };
      }
      Object.assign(populated, this.times);
      return populated;
    }
  },
  methods: {
    updateTimes() {
      this.$emit('time-updated', { isValid: this.isValid, times: this.populatedTimes });
    },
    validateTime(time) {
      this.isValid = time.checked ? !!time.startTime && !!time.endTime : true;
      this.$emit('time-updated', { isValid: this.isValid, times: this.populatedTimes });
    },
    copyTimes() {
      const mondayTime = this.populatedTimes[1];
      for (let day = 2; day <= 7; day++) {
        const currentDay = this.populatedTimes[day];
        currentDay.checked = mondayTime.checked;
        currentDay.startTime = mondayTime.startTime;
        currentDay.endTime = mondayTime.endTime;
      }
      this.updateTimes();
    }
  }
};
</script>
