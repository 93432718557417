import axios from "axios";

const state = {
  restaurants: [],
};

const getters = {
  getRestaurants: (state) => state.restaurants,
  getRestaurantById: (state) => (id) => {
    return state.restaurants.filter((item) => item.id === id);
  },
};

const mutations = {
  SET_RESTAURANTS(state, payload) {
    state.restaurants = payload;
  },

  ADD_RESTAURANT(state, payload) {
    state.restaurants.push(payload);
  },

  REMOVE_RESTAURANT(state, payload) {
    state.restaurants = state.restaurants.filter(
      (restaurant) => restaurant.id !== payload
    );
  },

  UPDATE_RESTAURANT(state, payload) {
    state.restaurants.forEach((restaurant, i) => {
      if (restaurant.id == payload.id) {
        state.restaurants[i] = payload;
      }
    });
  },
};

const actions = {
  async fetchRestaurants({ commit }) {
    const { data } = await axios.get("/cms_restaurants");
    commit("SET_RESTAURANTS", data.data);
  },

  async saveRestaurant({ commit }, payload) {
    const { data } = await axios.post("/restaurants", payload);
    commit("ADD_RESTAURANT", data.data);
  },

  async deleteRestaurant({ commit }, id) {
    await axios.delete(`restaurants/${id}`);
    commit("REMOVE_RESTAURANT", id);
  },

  async patchRestaurant({ commit }, payload) {
    const { data } = await axios.patch(`/restaurants/${payload.id}`, payload);
    commit("UPDATE_RESTAURANT", data.data);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
