import axios from "axios";

const state = {
  menuItemVariations: [],
  menuItemVariation: null,
};
const getters = {
  getMenuItemsVariations: (state) => {
    return state.menuItemVariations;
  },

  getMenuItemsVariationById: (state) => (id) => {
    return state.menuItemVariations.filter((item) => item.menu_item_id == id);
  },
};
const actions = {
  async fetchMenuItemsVariations({ commit }) {
    await axios.get("/menu-item-variations/").then((res) => {
      // console.log("RESPONSE RECEIVED: ", res);
      commit("setMenuItemVariations", res.data.data);
    });
    // .catch((err) => console.log(err));
  },

  async fetchMenuItemVaration({ commit }) {
    await axios.get(`/menu-item-variations/`).then((res) => {
      console.log("RESPONSE RECEIVED: ", res);
      commit("setMenuItemVariation", res.data.data);
    });
    // .catch((err) => console.log(err));
  },
};
const mutations = {
  setMenuItemVariations: (state, payload) => {
    state.menuItemVariations = payload;
  },
  setMenuItemVariation: (state, payload) => {
    state.menuItemVariation = payload;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
