<template>
  <!-- Container -->
  <div class="w-11/12 mx-auto">
    <h2 class="big-title mb-14">
      Settings
    </h2>
    <!-- Form container -->
    <div class="form-wrapper 2xl:w-9/12 mx-auto">
      <h3 class="form-title ">
        Edit general setting
      </h3>

      <!-- Form -->
      <form @submit.prevent="onSubmit" class="text-tableSubColor font-bold">
        <div class="md:flex">
          <!-- Select Restaurant -->
          <div class="w-full mb-4 md:mr-8">
            <label
              >Select Restaurant
              <select
                name="restaurant"
                v-model="model.restaurant_id"
                class="input"
                :class="{
                  'is-invalid': v$.model.restaurant_id.$error,
                }"
              >
                <option value="1">Pizza&Co</option>
              </select>
            </label>
            <span
              v-if="v$.model.restaurant_id.$error"
              class="text-sm font-normal text-invalidColor"
              >{{ v$.model.restaurant_id.$errors[0].$message }}</span
            >
          </div>
          <!-- Key -->
          <div class="w-full mb-4">
            <label
              >Key
              <input
                type="text"
                name="key"
                autocomplete="off"
                v-model="model.key"
                class="input"
                :class="{
                  'is-invalid': v$.model.key.$error,
                }"
              />
            </label>
            <span
              v-if="v$.model.key.$error"
              class="text-sm font-normal text-invalidColor"
              >{{ v$.model.key.$errors[0].$message }}</span
            >
          </div>
        </div>

        <div class="md:flex flex">
          <!-- Heading -->
          <div class="w-full mb-4 md:mr-8">
            <label
              >Heading
              <input
                type="text"
                name="heading"
                autocomplete="off"
                v-model="model.heading"
                class="input"
                :class="{
                  'is-invalid': v$.model.heading.$error,
                }"
              />
            </label>
            <span
              v-if="v$.model.heading.$error"
              class="text-sm font-normal text-invalidColor"
              >{{ v$.model.heading.$errors[0].$message }}</span
            >
          </div>
          <!-- Value -->
          <div class="w-full mb-4">
            <label
              >Value
              <textarea
                rows="3"
                cols="30"
                name="value"
                v-model="model.value"
                class="input"
                :class="{
                  'is-invalid': v$.model.value.$error,
                }"
              ></textarea>
            </label>
            <span
              v-if="v$.model.value.$error"
              class="text-sm font-normal text-invalidColor"
              >{{ v$.model.value.$errors[0].$message }}</span
            >
          </div>
        </div>

        <!-- Buttons -->
        <div class="w-full flex justify-end pt-4">
          <Button
            type="button"
            @click.prevent="back"
            btnTitle="Cancel"
            class="mr-4 bg-goldBeige text-black"
          />
          <Button type="submit" btnTitle="Save" class="bg-greenBtn" />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Button from "../../../components/Button.vue";
import { mapActions, mapGetters } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  name: "GeneralSettingsEdit",
  components: {
    Button,
  },
  data() {
    return {
      v$: useVuelidate(),
      model: {
        id: "",
        restaurant_id: "",
        key: "",
        heading: null,
        value: "",
      },
    };
  },

  /* --- Validation --- */
  validations() {
    return {
      model: {
        restaurant_id: { required },
        key: { required },
        value: { required },
        heading: { required },
      },
    };
  },

  async beforeMount() {
    if (this.getGeneralSettings.length == 0) {
      await this.fetchGeneralSettings;
    }
    this.getCurrentGeneralSetting();
  },

  computed: {
    ...mapGetters(["getGeneralSettingById", "getGeneralSettings"]),
    ...mapActions(["fetchGeneralSettings"]),
  },

  methods: {
    getCurrentGeneralSetting() {
      let id = parseInt(this.$route.params.id);
      this.model = this.getGeneralSettingById(id)[0];
    },

    async onSubmit() {
      this.v$.$validate();
      if (!this.v$.$error) {
        try {
          await this.$store.dispatch("patchGeneralSettings", this.model);
          //redirect
          this.$router.push("/general-settings");
        } catch (e) {
          alert(Object.values(e.response.data.messages).join(" "));
        }
      }
    },

    back() {
      this.$router.go(-1);
    },
  },
};
</script>
