import { createRouter, createWebHistory } from "vue-router";
import store from "../store";

import Login from "../views/Login.vue";
import ForgotPassword from "../views/ForgotPassword.vue";
import ResetPassword from "../views/ResetPassword.vue";

import Dashboard from "../views/Dashboard.vue";

import DailyWok from "../views/DailyWok.vue";

import Profile from "../views/Profile.vue";

import Users from "../views/users/Users.vue";
import UserCreate from "../views/users/UserCreate.vue";
import UserEdit from "../views/users/UserEdit.vue";

import Restaurants from "../views/restaurant/restaurants/Restaurants.vue";
import RestaurantsEdit from "../views/restaurant/restaurants/RestaurantsEdit.vue";
import RestaurantsCreate from "../views/restaurant/restaurants/RestaurantsCreate.vue";

import BusinessHours from "../views/restaurant/businessHours/BusinessHours.vue";
import BusinessHoursEdit from "../views/restaurant/businessHours/BusinessHoursEdit.vue";
import BusinessHoursCreate from "../views/restaurant/businessHours/BusinessHoursCreate.vue";

import DeliveryAreas from "../views/restaurant/deliveryAreas/DeliveryAreas.vue";
import DeliveryAreasEdit from "../views/restaurant/deliveryAreas/DeliveryAreasEdit.vue";
import DeliveryAreasCreate from "../views/restaurant/deliveryAreas/DeliveryAreasCreate.vue";

import TablePlacement from "../views/restaurant/tablePlacement/TablePlacement.vue";
import TablePlacementEdit from "../views/restaurant/tablePlacement/TablePlacementEdit.vue";
import TablePlacementCreate from "../views/restaurant/tablePlacement/TablePlacementCreate.vue";

import Menus from "../views/menu/menus/Menus.vue";
import MenusEdit from "../views/menu/menus/MenusEdit.vue";
import MenusCreate from "../views/menu/menus/MenusCreate.vue";

import Categories from "../views/menu/categories/Categories.vue";
import CategoriesCreate from "../views/menu/categories/CategoriesCreate.vue";
import CategoriesEdit from "../views/menu/categories/CategoriesEdit.vue";

import Ingredients from "../views/menu/ingredients/Ingredients.vue";
import IngredientsCreate from "../views/menu/ingredients/IngredientsCreate.vue";
import IngredientsEdit from "../views/menu/ingredients/IngredientsEdit.vue";

import Variations from "../views/menu/variations/Variations.vue";
import VariationsCreate from "../views/menu/variations/VariationsCreate.vue";
import VariationsEdit from "../views/menu/variations/VariationsEdit.vue";

import MenuItems from "../views/menu/menu_items/MenuItems.vue";
import MenuItemsCreate from "../views/menu/menu_items/MenuItemsCreate.vue";
import MenuItemsEdit from "../views/menu/menu_items/MenuItemsEdit.vue";

import CurrentOrders from "../views/sales/CurrentOrders.vue";
import OrdersAdmin from "../views/sales/OrdersAdmin.vue";
import OrdersId from "../views/sales/OrdersId";

import Coupons from "../views/promotions/coupons/Coupons.vue";
import CouponsCreate from "../views/promotions/coupons/CouponsCreate.vue";
import CouponsEdit from "../views/promotions/coupons/CouponsEdit.vue";

import PushNotifications from "../views/promotions/push_notifications/PushNotifications.vue";
import PushNotificationsCreate from "../views/promotions/push_notifications/PushNotificationsCreate.vue";
import PushNotificationsEdit from "../views/promotions/push_notifications/PushNotificationsEdit.vue";

import Points from "../views/settings/points/Points.vue";
import PointsCreate from "../views/settings/points/PointsCreate.vue";
import PointsEdit from "../views/settings/points/PointsEdit.vue";

import GeneralSettings from "../views/settings/general_settings/GeneralSettings.vue";
import GeneralSettingsCreate from "../views/settings/general_settings/GeneralSettingsCreate.vue";
import GeneralSettingsEdit from "../views/settings/general_settings/GeneralSettingsEdit.vue";

import PageNotFound from "../views/PageNotFound.vue";

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/reset",
    name: "ForgotPassword",
    component: ForgotPassword,
  },
  {
    path: "/reset/:token",
    name: "ResetPassword",
    component: ResetPassword,
  },
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
    meta: { requiresAuth: true },
  },
  {
    path: "/dailyWok",
    name: "DailyWok",
    component: DailyWok,
    // meta: { requiresAuth: true },
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/users",
    name: "Users",
    component: Users,
    meta: { requiresAuth: true },
  },
  {
    path: "/users/edit/:id",
    name: "UserEdit",
    component: UserEdit,
    meta: { requiresAuth: true },
  },
  {
    path: "/users/create",
    name: "UserCreate",
    component: UserCreate,
    meta: { requiresAuth: true },
  },
  {
    path: "/restaurants",
    name: "Restaurants",
    component: Restaurants,
    meta: { requiresAuth: true },
  },
  {
    path: "/restaurants/edit/:id",
    name: "RestaurantsEdit",
    component: RestaurantsEdit,
    meta: { requiresAuth: true },
  },
  {
    path: "/restaurants/create",
    name: "RestaurantsCreate",
    component: RestaurantsCreate,
    meta: { requiresAuth: true },
  },
  {
    path: "/business-hours",
    name: "BusinessHours",
    component: BusinessHours,
    meta: { requiresAuth: true },
  },
  {
    path: "/business-hours/edit/:id",
    name: "BusinessHoursEdit",
    component: BusinessHoursEdit,
    meta: { requiresAuth: true },
  },
  {
    path: "/business-hours/create",
    name: "BusinessHoursCreate",
    component: BusinessHoursCreate,
    meta: { requiresAuth: true },
  },
  {
    path: "/delivery-areas",
    name: "DeliveryAreas",
    component: DeliveryAreas,
    meta: { requiresAuth: true },
  },
  {
    path: "/delivery-areas/edit/:id",
    name: "DeliveryAreasEdit",
    component: DeliveryAreasEdit,
    meta: { requiresAuth: true },
  },
  {
    path: "/delivery-areas/create",
    name: "DeliveryAreasCreate",
    component: DeliveryAreasCreate,
    meta: { requiresAuth: true },
  },
  {
    path: "/table-placement",
    name: "TablePlacement",
    component: TablePlacement,
    meta: { requiresAuth: true },
  },
  {
    path: "/table-placement/edit/:id",
    name: "TablePlacementEdit",
    component: TablePlacementEdit,
    meta: { requiresAuth: true },
  },
  {
    path: "/table-placement/create",
    name: "TablePlacementCreate",
    component: TablePlacementCreate,
    meta: { requiresAuth: true },
  },
  {
    path: "/menus",
    name: "Menus",
    component: Menus,
    meta: { requiresAuth: true },
  },
  {
    path: "/menus/edit/:id",
    name: "MenusEdit",
    component: MenusEdit,
    meta: { requiresAuth: true },
  },
  {
    path: "/menus/create",
    name: "MenusCreate",
    component: MenusCreate,
    meta: { requiresAuth: true },
  },
  {
    path: "/categories",
    name: "Categories",
    component: Categories,
    meta: { requiresAuth: true },
  },
  {
    path: "/categories/edit/:id",
    name: "CategoriesEdit",
    component: CategoriesEdit,
    meta: { requiresAuth: true },
  },
  {
    path: "/categories/create",
    name: "CategoriesCreate",
    component: CategoriesCreate,
    meta: { requiresAuth: true },
  },
  {
    path: "/ingredients",
    name: "Ingredients",
    component: Ingredients,
    meta: { requiresAuth: true },
  },
  {
    path: "/ingredients/edit/:id",
    name: "IngredientsEdit",
    component: IngredientsEdit,
    meta: { requiresAuth: true },
  },
  {
    path: "/ingredients/create",
    name: "IngredientsCreate",
    component: IngredientsCreate,
    meta: { requiresAuth: true },
  },
  {
    path: "/variations",
    name: "Variations",
    component: Variations,
    meta: { requiresAuth: true },
  },
  {
    path: "/variations/edit/:id",
    name: "VariationsEdit",
    component: VariationsEdit,
    meta: { requiresAuth: true },
  },
  {
    path: "/variations/create",
    name: "VariationsCreate",
    component: VariationsCreate,
    meta: { requiresAuth: true },
  },
  {
    path: "/menu-items",
    name: "MenuItems",
    component: MenuItems,
    meta: { requiresAuth: true },
  },
  {
    path: "/menu-items/edit/:id",
    name: "MenuItemsEdit",
    component: MenuItemsEdit,
    meta: { requiresAuth: true },
  },
  {
    path: "/menu-items/create",
    name: "MenuItemsCreate",
    component: MenuItemsCreate,
    meta: { requiresAuth: true },
  },
  //-------------------------------------------------------------------
  //kitchenstaff & driver
  {
    path: "/orders",
    name: "CurrentOrders",
    component: CurrentOrders,
    meta: { requiresAuth: true },
  },
  //admin & kitchenstaff
  {
    path: "/orders/all-completed",
    name: "OrdersAdmin",
    component: OrdersAdmin,
    meta: { requiresAuth: true },
  },
  //admin & kitchenstaff
  {
    path: "/orders/:id",
    name: "OrdersId",
    component: OrdersId,
    meta: { requiresAuth: true },
  },
  //-------------------------------------------------------------------
  {
    path: "/coupons",
    name: "Coupons",
    component: Coupons,
    meta: { requiresAuth: true },
  },
  {
    path: "/coupons/create",
    name: "CouponsCreate",
    component: CouponsCreate,
    meta: { requiresAuth: true },
  },
  {
    path: "/coupons/edit/:id",
    name: "CouponsEdit",
    component: CouponsEdit,
    meta: { requiresAuth: true },
  },
  {
    path: "/push-notifications",
    name: "PushNotifications",
    component: PushNotifications,
    meta: { requiresAuth: true },
  },
  {
    path: "/push-notifications/create",
    name: "PushNotificationsCreate",
    component: PushNotificationsCreate,
    meta: { requiresAuth: true },
  },
  {
    path: "/push-notifications/edit/:id",
    name: "PushNotificationsEdit",
    component: PushNotificationsEdit,
    meta: { requiresAuth: true },
  },
  {
    path: "/points",
    name: "Points",
    component: Points,
    meta: { requiresAuth: true },
  },
  {
    path: "/points/create",
    name: "PointsCreate",
    component: PointsCreate,
    meta: { requiresAuth: true },
  },
  {
    path: "/points/edit/:id",
    name: "PointsEdit",
    component: PointsEdit,
    meta: { requiresAuth: true },
  },
  {
    path: "/general-settings",
    name: "GeneralSettings",
    component: GeneralSettings,
    meta: { requiresAuth: true },
  },
  {
    path: "/general-settings/create",
    name: "GeneralSettingsCreate",
    component: GeneralSettingsCreate,
    meta: { requiresAuth: true },
  },
  {
    path: "/general-settings/edit/:id",
    name: "GeneralSettingsEdit",
    component: GeneralSettingsEdit,
    meta: { requiresAuth: true },
  },
  {
    path: "/:pathMatch(.*)*",
    component: PageNotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach((to) => {
  if (to.meta.requiresAuth && !store.getters.isAuthenticated) {
    return { path: "/login" };
  }
});

export default router;
