import axios from "axios";

const state = {
  menuItemVariationIngredients: [],
};
const getters = {
  getMenuItemVariationIngredients: (state) => {
    return state.menuItemVariationIngredients;
  },

  getMenuItemVariationIngredientsById: (state) => (id) => {
    return state.menuItemVariationIngredients.filter((item) => {
      if (item.menu_variation_id == id) {
        return item;
      }
    });
  },
};
const actions = {
  async fetchMenuItemVariationIngredients({ commit }) {
    await axios.get("/menu-item-variation-ingredients").then((res) => {
      // console.log("RESPONSE RECEIVED: ", res);
      commit("setMenuItemVariationIngredients", res.data.data);
    });
    // .catch((err) => console.log(err));
  },

  /* async postMenuItemVariationIngredient({commit}, payload){
    await axios.post('/menu-item-varation-ingredients', payload){

    }

  } */
};
const mutations = {
  setMenuItemVariationIngredients: (state, payload) => {
    state.menuItemVariationIngredients = payload;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
