import axios from "axios";

const state = {
  menus: [],
  menu: null,
};

const getters = {
  getMenus: (state) => state.menus,
  getMenuById: (state) => (id) => {
    return state.menus.filter((menu) => menu.id === id);
  },
  getMenuID: (state) => state.menu,
};

const mutations = {
  SET_MENUS(state, payload) {
    state.menus = payload;
  },

  ADD_MENU(state, payload) {
    state.menus.push(payload);
  },

  REMOVE_MENU(state, payload) {
    state.menus = state.menus.filter((menu) => menu.id !== payload);
  },

  MENU_BY_ID(state, payload) {
    state.menu = payload;
  },

  UPDATE_MENUS(state, payload) {
    state.menus.forEach((menu, i) => {
      if (menu.id == payload.id) {
        state.menus[i] = payload;
      }
    });
  },
};

const actions = {
  async fetchMenus({ commit }) {
    const { data } = await axios.get("/menus");
    commit("SET_MENUS", data.data);
  },

  async fetchMenuById({ commit }, id) {
    const { data } = await axios.get(`/menus/times/${id}`);
    commit("MENU_BY_ID", data.data);
  },

  async saveMenu({ commit }, payload) {
    const { data } = await axios.post("/menus", payload);
    commit("ADD_MENU", data.data);
  },

  async deleteMenu({ commit }, id) {
    await axios.delete(`menus/${id}`);
    commit("REMOVE_MENU", id);
  },

  async patchMenu({ commit }, payload) {
    const { data } = await axios.patch(`/menus/${payload.id}`, payload);
    commit("UPDATE_MENUS", data.data);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
