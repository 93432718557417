<template>
  <!-- Container -->
  <div class="w-11/12 mx-auto">
    <h2 class="big-title mb-12">
      Menu
    </h2>
    <!-- Form container -->
    <div class="form-container 2xl:w-9/12">
      <!-- Gradient Background -->
      <div class="gradient-background"></div>

      <h3 class="form-title relative z-10">
        Create menu item
      </h3>

      <!-- Form -->
      <form
        @submit.prevent="onSubmit"
        class="text-tableSubColor font-bold relative z-10"
      >
        <div>
          <!-- Profil image -->
          <div class="md:flex">
            <div class="w-full mb-8 md:mb-4 mr-8">
              <div class="w-72 mx-auto flex items-center relative">
                <img class="rounded-lg" :src="img" alt="Select Food image" />
                <div class="file-input mt-4">
                  <input
                    type="file"
                    id="file"
                    class="file"
                    @change="filesChange"
                  />
                  <label
                    for="file"
                    class="bg-white text-subTitle border border-tableBorderColor px-3 py-2 rounded-full shadow-md text-sm font-bold cursor-pointer absolute -bottom-2.5 -right-2.5"
                    ><font-awesome-icon icon="plus"
                  /></label>
                </div>
              </div>
            </div>

            <div class="md:w-full">
              <!-- Select Menu Item Variation input -->
              <div class="w-full mb-4">
                <label
                  >Select Menu Item Variation
                  <select
                    name="menuItem.menuItemVariation_is_base"
                    v-model="menuItem.menuItemVariation_is_base"
                    @change="resetConfigurableOrBundle"
                    class="input "
                  >
                    <option value="1">Base</option>
                    <option value="0">Configurable</option>
                    <option value="2">Bundle</option>
                  </select>
                </label>
              </div>
              <!-- Select Categories -->
              <div class="w-full mb-4">
                <label
                  >Select Categories
                  <Multiselect
                    :classes="{
                      container: 'multiselectContainer',
                      tag: 'bg-black text-white multiselectTag',
                      tagsSearch: 'multiselectTagsSearch',
                    }"
                    v-model="this.menuItem.categories"
                    mode="tags"
                    :closeOnSelect="false"
                    :searchable="true"
                    :options="this.categories"
                  />
                </label>
              </div>
            </div>
          </div>

          <div class="md:flex">
            <div class="md:w-full md:mr-8">
              <!-- Name -->
              <div class="w-full mb-4">
                <label
                  >Name
                  <input
                    type="text"
                    name="name"
                    autocomplete="off"
                    v-model="menuItem.name"
                    class="input"
                  />
                </label>
              </div>
              <!-- Price -->
              <div
                class="w-full mb-4"
                v-show="menuItem.menuItemVariation_is_base != 0"
              >
                <label
                  >Price
                  <input
                    type="number"
                    name="menuItemVariation_price"
                    autocomplete="off"
                    v-model="menuItem.menuItemVariation_price"
                    class="input"
                  />
                </label>
              </div>

              <!-- Order -->
              <div class="w-full mb-4">
                <label
                  >Order
                  <input
                    type="number"
                    name="order"
                    autocomplete="off"
                    v-model="menuItem.order"
                    class="input"
                  />
                </label>
              </div>
            </div>

            <div class="md:w-full">
              <!-- Description -->
              <div class="w-full mb-3">
                <label
                  >Description
                  <textarea
                    rows="5"
                    cols="30"
                    name="description"
                    autocomplete="off"
                    v-model="menuItem.description"
                    class="input"
                  ></textarea>
                </label>
              </div>
              <div class="flex">
                <!-- Toggle Switch -->
                <div class="flex flex-col md:ml-1 md:mr-8">
                  <label for="is_active">Published</label>
                  <label class="switch mb-4">
                    <input
                      type="checkbox"
                      v-model="menuItem.is_active"
                      :false-value="parseInt(0)"
                      :true-value="parseInt(1)"
                    />
                    <span class="slider round"></span>
                  </label>
                </div>
                <!-- Toggle Switch -->
                <div class="flex flex-col md:ml-1 md:mr-8">
                  <label for="is_new">Is New</label>
                  <label class="switch mb-4">
                    <input
                      type="checkbox"
                      v-model="menuItem.is_new"
                      :false-value="parseInt(0)"
                      :true-value="parseInt(1)"
                    />
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <!-- ===== Configurable Container ===== -->
          <!-- Variations multiple -->
          <div
            class="w-full mb-4"
            v-show="menuItem.menuItemVariation_is_base == 0"
          >
            <label
              >Select Variations
              <Multiselect
                :classes="{
                  container: 'multiselectContainer',
                  tag: 'bg-loginBg text-goldBeige multiselectTag',
                  tagsSearch: 'multiselectTagsSearch',
                }"
                @select="filterVariations"
                @deselect="filterVariations"
                v-model="this.menuItem.menuItemVariation_multiple"
                mode="tags"
                :closeOnSelect="false"
                :searchable="true"
                :options="this.variations"
              />
            </label>
          </div>
          <!-- Ingredient conatiner -->
          <div
            class="mt-16"
            v-show="
              menuItem.menuItemVariation_is_base == 0 &&
                menuItem.menuItemVariation_multiple.length > 0
            "
          >
            <h3
              class="py-4 text-xl text-subTitle border-b border-tableBorderColor"
            >
              Ingredients
            </h3>
            <p class="text-sm py-2">
              Start by choosing a price then select the ingredients which will
              be included into the menu and then choose if there will be any
              extra optinal indgredients included.
            </p>

            <div
              class="my-8"
              v-for="(variation, i) in menuItem.selectedVariations"
              :key="i"
            >
              <h5 class="text-md text-goldBeige uppercase bg-loginBg p-2">
                {{ variation.name }} - {{ variation.type }}
              </h5>

              <!-- Price -->
              <div class="w-full flex justify-between items-center pt-8">
                <div class="w-80 mb-4">
                  <label
                    >Price
                    <input
                      type="text"
                      name="menuItemVariation_prices"
                      autocomplete="off"
                      v-model="menuItem.menuItemVariation_prices[variation.id]"
                      class="input"
                    />
                  </label>
                </div>
              </div>
              <!-- Ingredient List -->
              <div>
                <label
                  >Ingredient list
                  <Multiselect
                    :classes="{
                      container: 'multiselectContainer',
                      tag: 'bg-goldBeige text-black multiselectTag',
                      tagsSearch: 'multiselectTagsSearch',
                    }"
                    v-model="menuItemVariation_ingredients[variation.id]"
                    mode="tags"
                    :closeOnSelect="false"
                    :searchable="true"
                    :options="this.ingredients"
                  />
                </label>
              </div>
              <!-- Option List -->
              <div class="py-4">
                <label
                  >Optional list
                  <Multiselect
                    :classes="{
                      container: 'multiselectContainer',
                      tag: 'bg-goldBeige text-black multiselectTag',
                      tagsSearch: 'multiselectTagsSearch',
                    }"
                    v-model="menuItemVariation_optionals[variation.id]"
                    mode="tags"
                    :closeOnSelect="false"
                    :searchable="true"
                    :options="this.ingredients"
                  />
                </label>
              </div>
            </div>
          </div>
          <!-- ===== Bundle Container ===== -->
          <!-- Categories bundle multiple -->
          <div
            class="w-full my-4"
            v-show="menuItem.menuItemVariation_is_base == 2"
          >
            <label
              >From which categories will the items in the bundle be?
              <Multiselect
                :classes="{
                  container: 'multiselectContainer',
                  tag: 'bg-loginBg text-goldBeige multiselectTag',
                  tagsSearch: 'multiselectTagsSearch',
                }"
                v-model="menuItem.bundle_items.categories"
                mode="tags"
                :closeOnSelect="false"
                :searchable="true"
                :options="this.categories"
              />
            </label>
          </div>
          <!-- Bundle -->
          <div
            v-for="value in menuItem.bundle_items.categories"
            v-show="menuItem.menuItemVariation_is_base == 2"
            :key="value.id"
            class="md:flex"
          >
            <div class="w-full mb-4 mr-8">
              <label
                >Which items will be available for choosing?
                <Multiselect
                  :classes="{
                    container: 'multiselectContainer',
                    tag: 'bg-loginBg text-goldBeige multiselectTag',
                    tagsSearch: 'multiselectTagsSearch',
                  }"
                  v-model="bundleItemsForSelect[value]"
                  mode="tags"
                  :closeOnSelect="false"
                  :searchable="true"
                  :options="this.getItemsByCategory(value)"
                />
              </label>
            </div>
            <div class="w-full mb-4">
              <label
                >How many items allowed for choosing?
                <input
                  type="number"
                  name="menuItemVariation_price"
                  autocomplete="off"
                  placeholder="Enter a number.."
                  v-model="max_items_per_category[value]"
                  class="input"
                />
              </label>
            </div>
          </div>
        </div>

        <!-- Buttons -->
        <div class="w-full flex justify-end mt-8">
          <Button
            type="button"
            @click.prevent="back"
            btnTitle="Cancel"
            class="mr-4 bg-goldBeige text-black"
          />
          <Button type="submit" btnTitle="Save" class="bg-greenBtn" />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Button from "../../../components/Button.vue";
import { mapGetters, mapActions } from "vuex";
import Multiselect from "@vueform/multiselect";

export default {
  name: "MenuItmesCreate",
  components: {
    Button,
    Multiselect,
  },
  data() {
    return {
      inputs: {
        file: [],
      },
      img: null,
      menuItem: {
        name: "",
        order: "",
        description: "",
        is_active: 1,
        is_new: 0,
        menuItemVariation_prices: {},
        menuItemVariation_price: "",
        menuItemVariation_is_base: 1,
        menuItemVariation_multiple: [],
        selectedVariations: [],
        configurable_menu: false,
        prices: {},
        bundle_items: {
          categories: [],
        },
        menu_item_image: null,
      },
      menuItemVariation_optionals: [],
      menuItemVariation_ingredients: [],

      bundleItemsForSelect: [],
      max_items_per_category: [],
      current: null,
    };
  },

  async beforeMount() {
    if (this.getCategories.length == 0) {
      await this.fetchCategories;
    }
    if (this.getIngredients.length == 0) {
      await this.fetchIngredients;
    }
    if (this.getVariations.length == 0) {
      await this.fetchVariations;
    }
    if (this.getMenuItems.length == 0) {
      await this.fetchMenuItems;
    }
  },

  computed: {
    ...mapGetters([
      "getCategories",
      "getVariations",
      "getIngredients",
      "getMenuItemsBycategory",
      "getMenuItems",
    ]),
    ...mapActions([
      "fetchCategories",
      "fetchVariations",
      "fetchIngredients",
      "fetchMenuItems",
    ]),

    categories() {
      let array = [];
      this.getCategories.forEach((element) => {
        array.push({
          value: element.id,
          label: element.name,
        });
      });
      return array;
    },

    variations() {
      let array = [];
      this.getVariations.forEach((element) => {
        array.push({
          value: element.id,
          label: `${element.name} - ${element.type} `,
        });
      });
      return array;
    },

    ingredients() {
      let array = [];
      this.getIngredients.forEach((element) => {
        array.push({
          value: element.id,
          label: element.name,
        });
      });
      return array;
    },
  },

  methods: {
    async onSubmit() {
      try {
        let formData = new FormData();
        for (var key in this.menuItem) {
          if (key == "is_active") {
            if (this.menuItem[key] === 1) {
              formData.append(key, 1);
            } else {
              formData.append(key, 0);
            }
          } else if (key == "is_new") {
            if (this.menuItem[key] === 1) {
              formData.append(key, 1);
            } else {
              formData.append(key, 0);
            }
            /* ==== If the submit is a configurable ==== */
          } else if (key == "menuItemVariation_prices") {
            formData.append(key, JSON.stringify(this.menuItem[key]));
          } else if (key == "prices") {
            let model = {};
            let data = {};

            let ingrident = this.menuItemVariation_ingredients;
            ingrident.forEach((element, i) => {
              data[i] = "";
              let obj = {};

              element.forEach((item) => {
                obj[item] = { selected: true };

                data[i] = obj;
              });
            });

            let optional = this.menuItemVariation_optionals;
            optional.forEach((element, i) => {
              element.forEach((item) => {
                data[i][item] = { selected: true, optional: true };
              });
            });
            model = data;

            formData.append(key, JSON.stringify(model));
          } else if (key == "selectedVariations") {
            formData.append(key, JSON.stringify(this.menuItem[key]));
          } else {
            formData.append(key, this.menuItem[key]);
          }
        }
        /* ==== If the submit is a bundle ==== */
        if (this.menuItem.menuItemVariation_is_base == 2) {
          let model = {
            categories: [
              /*  { category_id: null, items: [], max_items_per_category: null }, */
            ],
          };
          // console.log(model);
          this.menuItem.bundle_items.categories.forEach((element) => {
            model.categories.push({
              category_id: element,
              items: [],
              max_items_per_category: null,
            });
          });

          this.bundleItemsForSelect.forEach((element, i) => {
            if (element != undefined) {
              let id = i;
              let items = [];
              items.push(Object.values(element));

              model.categories.forEach((category) => {
                let categoryID = category.category_id;

                items.forEach((element) => {
                  if (categoryID == id) {
                    category.items = element;
                  }
                });
              });
            }
          });

          this.max_items_per_category.forEach((element, i) => {
            if (element != undefined) {
              let max = element;
              let id = i;

              model.categories.forEach((category) => {
                if (category.category_id == id) {
                  category.max_items_per_category = max;
                }
              });
            }
          });

          formData.append(
            "bundle_categories",
            JSON.stringify(this.menuItem.bundle_items.categories)
          );
          // console.log(model);
          formData.append("bundle_items", JSON.stringify(model));
        }

        for (var pair of formData.entries()) {
          console.log(pair[0] + ", " + pair[1]);
        }

        await this.$store.dispatch("saveMenuItem", formData);
        await this.$store.dispatch("fetchMenuItems");
        this.$router.push("/menu-items");
      } catch (e) {
        Object.values(e.response.data.messages).join(" ");
      }
    },

    filterVariations() {
      this.menuItem.selectedVariations = [];
      this.getVariations.forEach((element) => {
        if (this.menuItem.menuItemVariation_multiple.includes(element.id)) {
          this.menuItem.selectedVariations.push(element);
        }
      });
    },

    getItemsByCategory(value) {
      const items = this.getMenuItemsBycategory(value);
      return items.map((item) => {
        return { label: item.name, value: item.id };
      });
    },

    resetConfigurableOrBundle() {
      if (this.menuItem.menuItemVariation_is_base == 0) {
        this.menuItem.configurable_menu = true;
        this.menuItem.bundle_items.categories.length = 0;
        this.bundleItemsForSelect = [];
        this.max_items_per_category = [];
      }

      if (this.menuItem.menuItemVariation_is_base == 2) {
        this.menuItem.configurable_menu = false;
        this.menuItem.prices = {};
        this.menuItem.selectedVariations = [];
        this.menuItem.menuItemVariation_multiple = [];
        this.menuItem.menuItemVariation_prices = {};
        this.menuItemVariation_optionals = [];
        this.menuItemVariation_ingredients = [];
      }
    },

    back() {
      this.$router.go(-1);
    },

    filesChange(e) {
      const file = e.target.files[0];

      this.img = URL.createObjectURL(file);
      this.inputs.file = e;
      if (e.target.files.length == 1) {
        this.menuItem.menu_item_image = file;
      } else {
        delete this.menuItem.menu_item_image;
      }
    },
    //
  },

  //   methods: {
  //   fileSelected(event) {
  //     const file = event.target.files.item(0);
  //     const reader = new FileReader();
  //     reader.addEventListener("load", this.imageLoaded);
  //     reader.readAsDataURL(file);
  //   },
  //   imageLoaded(event) {
  //     this.menuItem.image_path = event.target.result;
  //   },
  // },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
