<template>
  <div class="h-96 lg:h-64 xl:h-96">
    <canvas id="line-chart-week"></canvas>
  </div>
</template>

<script>
import Chart from "chart.js";
import lineChartWeek from "./line-chart-data";

export default {
  name: "LineChartWeek",

  data() {
    return {
      lineChartWeek,
    };
  },
  mounted() {
    const ctx = document.getElementById("line-chart-week");
    new Chart(ctx, this.lineChartWeek);
  },
};
</script>
