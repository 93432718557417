import axios from "axios";
/* import store from "@/store"; */

const state = {
  ingredients: [],
};

const getters = {
  getIngredients: (state) => state.ingredients,
  getIngredientById: (state) => (id) => {
    return state.ingredients.filter((item) => item.id === id);
  },
};

const mutations = {
  SET_INGREDIENTS(state, ingredients) {
    state.ingredients = ingredients;
  },

  ADD_INGREDIENT(state, payload) {
    state.ingredients.push(payload);
  },

  REMOVE_INGREDIENT(state, id) {
    state.ingredients = state.ingredients.filter(
      (ingredient) => ingredient.id !== id
    );
  },

  UPDATE_INGREDIENT(state, payload) {
    state.ingredients.forEach((ingredient, i) => {
      if (ingredient.id == payload.id) {
        state.ingredients[i] = payload;
      }
    });
  },
};

const actions = {
  async fetchIngredients({ commit }) {
    const { data } = await axios.get("/ingredients");
    commit("SET_INGREDIENTS", data.data);
  },

  async saveIngredient({ commit }, payload) {
    const { data } = await axios.post("/ingredients", payload);
    commit("ADD_INGREDIENT", data.data);
  },

  async deleteIngredient({ commit }, id) {
    await axios.delete(`/ingredients/${id}`);
    commit("REMOVE_INGREDIENT", id);
  },

  async patchIngredient({ commit }, payload) {
    const { data } = await axios.patch(`/ingredients/${payload.id}`, payload);
    commit("UPDATE_INGREDIENT", data.data);
  },
};

/* const token = store.getters.getToken; */
/*  {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    } */

export default {
  state,
  getters,
  mutations,
  actions,
};
