<template>
  <div
    v-if="data != null"
    class="min-h-screen w-full xl:flex xl:flex-row-reverse"
  >
    <!-- Side dashboard -->
    <div class=" xl:bg-white xl:shadow-lg">
      <!-- Header on small screens -->
      <h2
        class="block xl:hidden text-4xl font-semibold py-4 px-4 text-tableBorderColor"
      >
        Dashboard
      </h2>
      <!-- Cards -->
      <div class="px-8 pt-8">
        <div class="md:flex xl:flex-col">
          <Card
            class="bg-cardColor md:mr-8 xl:mr-0"
            title="Total Value"
            :value="data.total_orders_value"
            valuta=""
            icon="chart-line"
          />
          <Card
            class="bg-cardColor "
            title="Total Resturants"
            :value="data.total_restaurants"
            icon="utensils"
          />
        </div>
        <div class="md:flex xl:flex-col">
          <Card
            class="bg-cardColor md:mr-8 xl:mr-0"
            title="Total Orders"
            :value="data.total_orders"
            icon="copy"
          />
          <Card
            class="bg-cardColor"
            title="Total Users"
            :value="data.total_users"
            icon="users"
          />
        </div>
      </div>
      <!-- Bar Chart -->
      <div
        class="w-11/12 mx-auto bg-white p-4 shadow-xl rounded-lg md:mb-8 xl:mb-0"
      >
        <div class="mb-2">
          <p class="text-xs text-lightGrey">Perfomance</p>
          <h3 class="text-2xl font-semibold text-subTitle">Orders</h3>
        </div>
        <div>
          <BarChartOrders class="xl:h-52" />
        </div>
      </div>
    </div>
    <!-- Main dashboard -->
    <div class="w-full">
      <!-- XL screen header -->
      <div class="flex justify-between xl:pb-16 w-11/12 mx-auto">
        <h1 class="big-title hidden xl:block">
          Dashboard
        </h1>
        <div class="hidden xl:block xl:py-8 ">
          <p class="text-sm text-tableBorderColor">{{ day }}</p>
          <p class="text-sm text-tableBorderColor">{{ fullDate }}</p>
        </div>
      </div>
      <!-- Line Chart -->
      <div class="w-11/12 mx-auto bg-white px-8 pt-8 shadow-lg rounded-lg">
        <div class="sm:flex sm:justify-between sm:items-center ">
          <div>
            <p class="text-xs text-lightGrey">Overview</p>
            <h3 class="text-2xl font-semibold text-subTitle">
              Orders value
            </h3>
          </div>
          <div class="flex">
            <button
              v-for="(date, i) in calender"
              :key="i"
              @click="current = i"
              :class="{ 'bg-blueBlack text-white': i == current }"
              class="text-base lg:text-sm xl:text-base w-16 my-auto py-1 text-center mr-2 lg:mr-0 xl:mr-2 rounded transition duration-300 ease-in-out"
            >
              {{ date }}
            </button>
          </div>
        </div>
        <div v-if="current === 2" class=" py-8">
          <LineChart />
        </div>
        <div v-if="current === 0" class=" py-8">
          <LineChartDay />
        </div>
        <div v-if="current === 1" class=" py-8">
          <LineChartWeek />
        </div>
        <div v-if="current === 3" class=" py-8">
          <LineChartYear />
        </div>
      </div>

      <!-- Top Users Table -->
      <div class="w-11/12 mx-auto my-12" v-if="showUsers">
        <div class="sm:flex sm:justify-between sm:items-center mb-8">
          <div class="mb-4">
            <p class="text-xs text-lightGrey">{{ month }} {{ year }}</p>
            <h3 class="text-2xl font-semibold text-subTitle">
              Top Users
            </h3>
          </div>
          <form class="flex">
            <img
              src="../assets/images/search.svg"
              alt=""
              class="w-4 mb-1 transform translate-x-4"
            />
            <input
              class="w-60 pl-6 pb-1 border-b bg-whiteTrans  text-tableSubColor border-tableSubColor focus:outline-none text-sm"
              type="search"
              name="search"
              placeholder="Search"
              v-model="search"
            />
          </form>
        </div>
        <div class="w-full overflow-x-auto">
          <table class="w-full">
            <tr class="text-left text-tableTitleColor">
              <th>Name</th>
              <th>Email</th>
              <th>Points</th>
              <!-- <th>Total amount</th> -->
            </tr>
            <tr
              v-for="user in filteredList.slice(0, 5)"
              :key="user"
              class="border-b border-tableBorderColor text-tableSubColor"
            >
              <td class="py-4">{{ user.first_name }}{{ user.last_name }}</td>
              <td>{{ user.email }}</td>
              <td>{{ user.co_points }}</td>
              <!-- <td>{{ user.co_points_in_sek }}:-</td> -->
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LineChart from "../components/LineChartMonth/LineChartMonth.vue";
import LineChartDay from "../components/LineChartDay/LineChartDay.vue";
import LineChartWeek from "../components/LineChartWeek/LineChartWeek.vue";
import LineChartYear from "../components/LineChartYear/LineChartYear.vue";
import BarChartOrders from "../components/BarChartOrders/BarChartOrders.vue";
import Card from "@/components/Card.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Dashboard",
  components: {
    LineChart,
    LineChartDay,
    BarChartOrders,
    Card,
    LineChartWeek,
    LineChartYear,
  },
  data() {
    return {
      search: "",
      current: 2,
      calender: ["Day", "Week", "Month", "Year"],
      showUsers: true,
      month: "",
      months: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      date: "",
      year: "",
      day: "",
      days: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
    };
  },

  async beforeMount() {
    if (this.getUser.role == "kitchen_staff") {
      this.showUsers = false;
    } else {
      if (this.getUsers.length == 0) {
        await this.fetchUsers;
      }
    }

    if (this.data == null) {
      this.$store.dispatch("fetchDashboardData");
    }
  },

  computed: {
    ...mapActions(["fetchUsers"]),
    ...mapGetters(["getUsers", "getUser"]),

    filteredList() {
      return this.getUsers
        .filter((user) => {
          return user.first_name
            .toLowerCase()
            .includes(this.search.toLowerCase());
        })
        .sort((a, b) => b.co_points - a.co_points);
    },

    fullDate() {
      return this.month + " " + this.date + " " + this.year;
    },

    data: function() {
      let data = this.$store.getters["getDashboardData"];

      return data;
    },
  },

  methods: {
    getMonth() {
      return new Date().getMonth();
    },
    getFullDate() {
      return new Date().getDate();
    },
    getYear() {
      return new Date().getFullYear();
    },
    getDay() {
      return new Date().getDay();
    },
  },

  mounted() {
    this.month = this.months[this.getMonth()].toUpperCase();
    this.date = this.getFullDate();
    this.year = this.getYear();
    this.day = this.days[this.getDay()].toUpperCase();
  },
};
</script>
