<template>
  <div
    class="w-full h-screen bg-loginBg flex justify-center md:items-center overflow-x-hidden"
  >
    <!-- Background circles -->
    <div class="hidden md:block">
      <div
        class="w-96 h-96 rounded-full bg-round absolute bottom-10 right-0"
      ></div>
      <div
        class="w-40 h-40 rounded-full bg-round absolute bottom-10 right-0"
      ></div>
      <div
        class="w-72 h-72 rounded-full bg-round absolute bottom-15 right-0"
      ></div>
    </div>

    <div
      class="relative md:w-6/12 md:h-full md:flex md:flex-col md:justify-center 2xl:justify-start md:items-center 2xl:mt-44 2xl:pt-40"
    >
      <!-- Background circles -->
      <div class="hidden md:block">
        <div
          class="w-96 h-96 rounded-full bg-round absolute top-10 -left-32 xl:left-0"
        ></div>
        <div
          class="w-40 h-40 rounded-full bg-round absolute top-10 -left-32 xl:left-0"
        ></div>
        <div
          class="w-72 h-72 rounded-full bg-round absolute top-15 -left-32 xl:left-0"
        ></div>
      </div>

      <!-- Form INLOG container -->
      <div
        class="md:w-96 mx-auto py-4 px-8 mt-16 md:mt-0 bgInlogForm rounded-3xl"
      >
        <!-- Logo -->
        <img
          src="@/assets/images/chingaling-logo.png"
          alt=""
          class="w-32 mx-auto my-8"
        />
        <transition name="shake">
          <p
            v-if="showError"
            class="p-2 mb-4 bg-orangeCard border border-invalidColor text-invalidColor"
          >
            Wrong username or password!
          </p>
        </transition>

        <!-- Form -->
        <form @submit.prevent="submit">
          <!-- Email/Username input -->
          <div class="relative mb-4">
            <font-awesome-icon
              icon="envelope"
              class="text-lg text-white absolute top-3 left-4"
            />
            <input
              class="w-full pl-12 py-2 bg-whiteTrans border-tableBorderColor border rounded outline-none text-white"
              type="text"
              placeholder="Email/Username"
              v-model="form.login"
              :class="{
                'is-invalid': v$.form.login.$error,
              }"
            />
            <span
              v-if="v$.form.login.$error"
              class="text-sm font-normal text-invalidColor"
              >{{ v$.form.login.$errors[0].$message }}</span
            >
          </div>
          <!-- Password input -->
          <div class="relative mb-4">
            <font-awesome-icon
              icon="key"
              class="text-md text-white absolute top-3 left-4"
            />
            <input
              class="w-full pl-12 py-2 bg-whiteTrans border-tableBorderColor border rounded outline-none text-white"
              :type="type"
              placeholder="Password"
              v-model="form.password"
              :class="{
                'is-invalid': v$.form.password.$error,
              }"
            />
            <font-awesome-icon
              :icon="icon"
              class="text-md text-white absolute top-3 right-4"
              @click="showPassword"
            />
            <span
              v-if="v$.form.password.$error"
              class="text-sm font-normal text-invalidColor"
              >{{ v$.form.password.$errors[0].$message }}</span
            >
          </div>
          <!-- Checkbox -->
          <div class="flex items-center">
            <input class="w-4" type="checkbox" id="scales" name="scales" />
            <label class="text-white text-sm ml-2" for="scales"
              >Remember me</label
            >
          </div>
          <!-- Sign in Button -->
          <Button
            type="submit"
            btnTitle="Sign in"
            class="bg-exportBtn w-full h-11 my-8 shadow-lg"
          ></Button>
        </form>
      </div>

      <!-- Forgot password -->
      <p
        class="w-96 mx-auto text-menuText text-sm mt-6 pl-8 xl:pl-2 cursor-pointer"
      >
        <router-link :to="{ name: 'ForgotPassword' }">
          Forgot password?</router-link
        >
      </p>
    </div>
  </div>
</template>
<script>
import Button from "../components/Button.vue";
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";

export default {
  name: "Login",
  components: {
    Button,
  },
  data() {
    return {
      v$: useVuelidate(),
      form: {
        login: "",
        password: "",
      },
      type: "password",
      icon: "eye-slash",
      showError: false,
    };
  },

  validations() {
    return {
      form: {
        login: { required, email },
        password: { required },
      },
    };
  },

  methods: {
    showPassword() {
      if (this.type === "password") {
        this.type = "text";
        this.icon = "eye";
      } else {
        this.type = "password";
        this.icon = "eye-slash";
      }
    },

    async submit() {
      this.v$.$validate();
      if (!this.v$.$error) {
        try {
          await this.$store.dispatch("LogIn", {
            login: this.form.login,
            password: this.form.password,
          });
          this.$router.push("/");
          this.showError = false;
        } catch (error) {
          this.showError = true;
          setTimeout(() => {
            this.showError = false;
          }, 4000);
        }
      }
    },
  },
};
</script>
<style>
.size {
  width: 60rem;
  height: 60rem;
}
.bgInlogForm {
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(5px);
}
@media (max-width: 640px) {
  .bgInlogForm {
    background-color: rgba(255, 255, 255, 0);
    backdrop-filter: blur(0px);
  }
}

.shake-enter-active {
  animation: shake-in 0.5s;
}
.shake-leave-active {
  transition: all 0.8s ease-out;
}
.shake-leave-to {
  opacity: 0;
}
@keyframes shake-in {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
</style>
