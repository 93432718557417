<template>
  <div class="relative">
    <Navigation v-if="isAuthenticated" />
    <div
      v-if="isAuthenticated"
      class="w-full h-80 background absolute z-0 top-0 left-0"
    ></div>
    <div class="bg-bodyBg" :class="[isAuthenticated ? 'xl:ml-52' : '']">
      <router-view class="relative z-20"></router-view>
    </div>
  </div>
</template>

<script>
import Navigation from "./components/Navigation.vue";
import { mapGetters } from "vuex";

export default {
  name: "App",
  components: {
    Navigation,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
  },
};
</script>
<style>
/* -------- Popup delete box -------- */
.slide-fade-enter-active {
  transition: all 0.2s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.5s ease-out;
}

.slide-fade-enter-from {
  transform: scale(2, 2);
  opacity: 0;
}
.slide-fade-leave-to {
  opacity: 0;
}

/* Top background color */
.background {
  background: #485563; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #29323c,
    #485563
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #29323c,
    #485563
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

/* -------- Browser image -------- */
.file {
  opacity: 0;
  width: 0.1px;
  height: 0.1px;
  position: absolute;
}

/* -------- Slide checkbox -------- */
.switch {
  position: relative;
  display: inline-block;
  width: 53px;
  height: 26px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #677d9e;
}

input:focus + .slider {
  box-shadow: 0 0 1px #677d9e;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
