const barChartData = {
  type: "bar",
  data: {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "My First Dataset",
        data: [65, 59, 153, 81, 203, 78, 40, 250, 300, 220, 186, 126],
        backgroundColor: "rgba(254, 141, 37, 0.7)",
      },
    ],
  },
  options: {
    maintainAspectRatio: false,
    tooltips: {
      displayColors: false,
      backgroundColor: "rgba(0, 0, 0, 0.8)",
      titleAlign: "center",
      bodyFontSize: 14,
      caretPadding: 10,
      padding: 10,

      callbacks: {
        label: function(item) {
          let value = item.yLabel;
          value = value.toLocaleString();
          let label = "Orders: " + value;
          return label;
        },
      },
    },
    legend: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            beginAtZero: true,
            padding: 10,
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
          },
        },
      ],
    },
  },
};

export default barChartData;
