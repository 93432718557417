<template>
  <div class="h-96 lg:h-64 xl:h-96">
    <canvas id="line-chart-year"></canvas>
  </div>
</template>

<script>
import Chart from "chart.js";
import lineChartYear from "./line-chart-data";

export default {
  name: "LineChartYear",

  data() {
    return {
      lineChartYear,
    };
  },
  mounted() {
    const ctx = document.getElementById("line-chart-year");
    new Chart(ctx, this.lineChartYear);
  },
};
</script>
