import axios from "axios";
const state = {
  orders: [],
  kitchenStaffOrders: [],
  driversOrders: [],
};

const getters = {
  getOrders: (state) => state.orders,
  getKitchenStaffOrders: (state) => state.kitchenStaffOrders,
  getDriversOrders: (state) => state.driversOrders,
  getOrderById: (state) => (id) => {
    return state.orders.filter((item) => item.id === id);
  },
};

const mutations = {
  SET_ORDERS(state, payload) {
    state.orders = payload;
  },

  SET_KITCHEN_STAFF_ORDERS(state, payload) {
    state.kitchenStaffOrders = payload;
  },

  SET_DRIVERS_ORDERS(state, payload) {
    state.driversOrders = payload;
  },
};

const actions = {
  async fetchOrders({ commit }) {
    const { data } = await axios.get("/admin/orders");
    commit("SET_ORDERS", data.data);
  },

  async fetchKitchenStaffOrders({ commit }) {
    const { data } = await axios.get("/admin/orders-kitchen_staff");
    commit("SET_KITCHEN_STAFF_ORDERS", data.data);
  },

  async fetchDriversOrders({ commit }) {
    const { data } = await axios.get("/admin/orders-driver");
    commit("SET_DRIVERS_ORDERS", data.data);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
