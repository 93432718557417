<template>
  <div class="h-96 lg:h-64 xl:h-96">
    <canvas id="line-chart"></canvas>
  </div>
</template>

<script>
import Chart from "chart.js";
import lineChartData from "./line-chart-data";
import { mapGetters } from "vuex";

export default {
  name: "LineChart",

  data() {
    return {
      lineChartData,
      data: [],
      test: {
        type: "line",
        data: {
          labels: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
          datasets: [
            {
              label: "Value",
              data: [],
              backgroundColor: "rgba(18, 152, 144, 0.2)",
              borderColor: "rgba(18, 152, 144, 0.5)",
              borderWidth: 3,
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          responsive: true,
          lineTension: 1,
          tooltips: {
            displayColors: false,
            backgroundColor: "rgba(18, 152, 144, 0.8)",
            titleAlign: "center",
            bodyFontSize: 14,
            caretPadding: 10,
            padding: 10,

            callbacks: {
              label: function(item) {
                let value = item.yLabel;
                value = value.toLocaleString();
                let label = value + " Sek";
                return label;
              },
            },
          },
          scales: {
            yAxes: [
              {
                gridLines: {
                  display: false,
                  drawBorder: false,
                },
                ticks: {
                  beginAtZero: true,
                  padding: 15,
                },
              },
            ],
            xAxes: [
              {
                gridLines: {
                  display: false,
                },
                ticks: {
                  padding: 25,
                },
              },
            ],
          },
          legend: {
            display: false,
          },
        },
      },
    };
  },
  mounted() {
    let data = this.getDashboardData;

    let array = [];

    Object.entries(data.total_value_ordered_by_month).forEach((test, i) => {
      array[i] = 0;
      array[test[0]] = test[1];
    });

    this.test.data.datasets[0].data = array;

    const ctx = document.getElementById("line-chart");
    new Chart(ctx, this.test);
  },
  computed: {
    ...mapGetters(["getDashboardData"]),
  },
};
</script>
