import axios from "axios";

const state = {
  coupons: [],
};

const getters = {
  getCoupons: (state) => state.coupons,
  getCouponById: (state) => (id) => {
    return state.coupons.filter((item) => item.id === id);
  },
};

const mutations = {
  SET_COUPONS(state, payload) {
    state.coupons = payload;
  },

  ADD_COUPON(state, payload) {
    state.coupons.push(payload);
  },

  REMOVE_COUPON(state, payload) {
    state.coupons = state.coupons.filter((coupon) => coupon.id !== payload);
  },

  UPDATE_COUPON(state, payload) {
    state.coupons.forEach((coupon, i) => {
      if (coupon.id == payload.id) {
        state.coupons[i] = payload;
      }
    });
  },
};

const actions = {
  async fetchCoupons({ commit }) {
    const { data } = await axios.get("/coupons");
    commit("SET_COUPONS", data.data);
  },

  async saveCoupon({ commit }, payload) {
    const { data } = await axios.post("/coupons", payload);
    commit("ADD_COUPON", data.data);
  },

  async deleteCoupon({ commit }, id) {
    await axios.delete(`coupons/${id}`);
    commit("REMOVE_COUPON", id);
  },

  async patchCoupon({ commit }, payload) {
    const { data } = await axios.patch(`/coupons/${payload.id}`, payload);
    commit("UPDATE_COUPON", data.data);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
