<template>
  <!-- Container -->
  <div class="w-11/12 mx-auto">
    <h2 class="big-title mb-14">
      Settings
    </h2>
    <!-- Form container -->
    <div class="form-wrapper 2xl:w-9/12 mx-auto">
      <h3 class="form-title ">
        Edit point
      </h3>

      <!-- Form -->
      <form @submit.prevent="onSubmit" class="text-tableSubColor font-bold">
        <div class="md:flex">
          <!-- Select Type -->
          <div class="w-full mb-4 md:mr-8">
            <label
              >Select Type
              <select
                name="action"
                v-model="model.action"
                class="input"
                :class="{
                  'is-invalid': v$.model.action.$error,
                }"
              >
                <option value="register">Register</option>
                <option value="buy">Buy</option>
                <option value="coupon_share">Coupon share</option>
              </select>
            </label>
            <span
              v-if="v$.model.action.$error"
              class="text-sm font-normal text-invalidColor"
              >{{ v$.model.action.$errors[0].$message }}</span
            >
          </div>
          <!-- Points -->
          <div class="w-full mb-4">
            <label
              >Points
              <input
                type="number"
                name="points"
                autocomplete="off"
                v-model="model.points"
                class="input"
                :class="{
                  'is-invalid': v$.model.points.$error,
                }"
              />
            </label>
            <span
              v-if="v$.model.points.$error"
              class="text-sm font-normal text-invalidColor"
              >{{ v$.model.points.$errors[0].$message }}</span
            >
          </div>
        </div>

        <!-- Published -->
        <div class="flex flex-col">
          <label for="published">Published</label>
          <label class="switch mb-4 md:mb-0 md:mt-2">
            <input
              type="checkbox"
              v-model="model.published"
              :false-value="parseInt(0)"
              :true-value="parseInt(1)"
            />
            <span class="slider round"></span>
          </label>
        </div>

        <!-- Buttons -->
        <div class="w-full flex justify-end pt-4">
          <Button
            type="button"
            @click.prevent="back"
            btnTitle="Cancel"
            class="mr-4 bg-goldBeige text-black"
          />
          <Button type="submit" btnTitle="Save" class="bg-greenBtn" />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Button from "../../../components/Button.vue";
import { mapGetters, mapActions } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  name: "PointsEdit",
  components: {
    Button,
  },
  data() {
    return {
      v$: useVuelidate(),
      model: {
        action: "",
        points: "",
        published: 0,
      },
    };
  },

  /* --- Validation --- */
  validations() {
    return {
      model: {
        action: { required },
        points: { required },
      },
    };
  },

  async beforeMount() {
    if (this.getPoints.length == 0) {
      await this.fetchPoints;
    }
    this.getCurrentPoint();
  },

  computed: {
    ...mapGetters(["getPointById", "getPoints"]),
    ...mapActions(["fetchPoints"]),
  },

  methods: {
    getCurrentPoint() {
      let id = parseInt(this.$route.params.id);
      this.model = this.getPointById(id)[0];
    },

    async onSubmit() {
      this.v$.$validate();
      if (!this.v$.$error) {
        try {
          await this.$store.dispatch("patchPoint", this.model);
          await this.$store.dispatch("fetchPoints", this.model);
          //redirect
          this.$router.push("/points");
        } catch (e) {
          alert(Object.values(e.response.data.messages).join(" "));
        }
      }
    },

    back() {
      this.$router.go(-1);
    },
  },
};
</script>
