<template>
  <div class="w-full h-screen flex justify-center bg-blue">
    <div class="w-9/12 text-white p-4 text-center mt-44">
      <h4 class="text-3xl font-semibold pb-6">Ooops!</h4>
      <p class="text-lg pb-4">
        Page not found. Don't worry though, we have plenty of other pages to
        explore
      </p>
      <router-link :to="{ name: 'Dashboard' }">
        <p class="text-lg font-semibold text-goldBeige">Back to dashboard</p>
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  name: "PageNotFound",
};
</script>
