import axios from "axios";

const state = {
  categories: [],
};

const getters = {
  getCategories: (state) => state.categories,
  getCategoryById: (state) => (id) => {
    return state.categories.filter((category) => category.id === id);
  },
};

const mutations = {
  SET_CATEGORIES(state, payload) {
    state.categories = payload;
  },

  ADD_CATEGORY(state, payload) {
    state.categories.push(payload);
  },

  REMOVE_CATEGORY(state, payload) {
    state.categories = state.categories.filter(
      (category) => category.id !== payload
    );
  },

  UPDATE_CATEGORY(state, payload) {
    state.categories.forEach((category, i) => {
      if (category.id == payload.id) {
        state.categories[i] = payload;
      }
    });
  },
};

const actions = {
  async fetchCategories({ commit }) {
    const { data } = await axios.get("/categories");
    commit("SET_CATEGORIES", data.data);
  },

  async saveCategory({ commit }, payload) {
    const { data } = await axios.post("/categories", payload);
    commit("ADD_CATEGORY", data.data);
  },

  async deleteCategory({ commit }, id) {
    await axios.delete(`/categories/${id}`);
    commit("REMOVE_CATEGORY", id);
  },

  async patchCategory({ commit }, payload) {
    const { data } = await axios.patch(`/categories/${payload.id}`, payload);
    commit("UPDATE_CATEGORY", data.data);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
